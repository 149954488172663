
<template>
     <v-navigation-drawer expand-on-hover rail app>
       

        <v-list>
      <v-list-item prepend-icon="mdi-view-dashboard" :to="{name:'WarehouseDashboard'}" class="list-items" value="dashboard">
        Manager Dashboard
      </v-list-item>
      <v-list-item prepend-icon="mdi-chart-bar" :to="{name:'Home'}" class="list-items" value="home">
        Summary
      </v-list-item>
      <v-list-item prepend-icon="mdi-store" class="list-items" value="" title="" :to="{ name: 'AllAssets' }">
        All Assets
      </v-list-item>
      <v-list-item prepend-icon="mdi-barcode-scan" class="list-items" value="Load Assets" title=""
        :to="{ name: 'LoadAssets' }">Load
        Assets</v-list-item>
      <v-list-item prepend-icon="mdi-access-point-network" class="list-items" value="inventory" title=""
        :to="{ name: 'InventoryList' }">
        Installation Inventory
      </v-list-item>
      <v-list-item prepend-icon="mdi-laptop" class="list-items" value="staff_equipment" title=""
        :to="{ name: 'StaffEquipmentList' }">
        Staff Equipment
      </v-list-item>
      <v-list-item prepend-icon="mdi-radio-tower" class="list-items" value="highsite_iventory" title=""
        :to="{ name: 'HighsiteList' }">
        Highsite Inventory
      </v-list-item>
      <v-list-item prepend-icon="mdi-tools" class="list-items" value="tools" title="" :to="{ name: 'OtherAssets' }">
        Other Assets
      </v-list-item>
      <v-list-item prepend-icon='mdi-cart-outline ' class="list-items" value="" title="" :to="{ name: 'OrderInvoices' }">
        Orders/Invoices
      </v-list-item>
      <v-list-item prepend-icon="mdi-router-network" class="list-items" value="Authorized Product List" title=""
        :to="{ name: 'Products' }">
        Authorized Products
      </v-list-item>
      <v-list-item prepend-icon="mdi-handshake" class="list-items" value="Authorized Supplier List" title=""
        :to="{ name: 'Suppliers' }">
        Authorized Suppliers
      </v-list-item>

      <v-list-item prepend-icon="mdi-clipboard-arrow-left" class="list-items" value="Supplier Returns" title=""
        :to="{ name: 'Returns' }">
        Returns
      </v-list-item>
      <v-list-item prepend-icon="mdi-account-remove" class="list-items" value="Unaccepted Assets" title=""
        :to="{ name: 'UnacceptedAssets' }">
        Assignments
      </v-list-item>
      <v-list-item prepend-icon="mdi-account-arrow-left" class="list-items" value="My Assets" title=""
        :to="{ name: 'MyAssets' }">
        My Assets
      </v-list-item>

    </v-list>

    </v-navigation-drawer>
    <v-main>
        <router-view v-slot="{ Component}">
            <component :is="Component" />
        </router-view>
    </v-main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: "WarehouseIndex",
    data: () => ({}),
    methods: {},
    onMounted() {
        console.log("Dashboard mounted");
    },
});
</script>
