
<template>
    <v-container v-if="isLoading">
        <v-row>
            <v-col>
                <v-card>

                    <v-card-title>Loading...</v-card-title>
                    <v-card-text>
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else class="mt-0 pt-0">
        <v-card>
            <v-card-title>Installation Details</v-card-title>
            <v-container>
                <v-row>
                    <v-col xs="12" sm="12" md="6">
                        <div v-for="(value, key) in installation" :key="key">
                            <v-row v-if="key != 'people'">
                                <v-col cols="4">{{ formatFieldName(key) }}</v-col>
                                <v-col cols="8">{{ value }}</v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                        <v-row>
                            <v-col xs="12" sm="12" md="12" v-for="(person, index) in installation.people" :key="index">
                                <v-card>
                                    <v-card-title>{{ person.name }}</v-card-title>
                                    <v-card-text>
                                        {{ person.cellphone }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
            </v-container>
            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-row-reverse">
                <v-btn color="primary" target="_blank" :href="`https://vertex.ap3k.co/lio/${installation.id}`">
                    Open LIO
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-container>

    <v-container class="mt-0 pt-0">
        <v-card>
            <v-card-title>Call Form</v-card-title>
            <v-container>
                <v-row>
                    <v-col xs="12" sm="12" md="6">
                        <div v-for="(value, key) in installation" :key="key">
                            <v-row v-if="key != 'people'">
                                <v-col cols="4">{{ formatFieldName(key) }}</v-col>
                                <v-col cols="8">{{ value }}</v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col xs="12" sm="12" md="6">
                        <v-row>
                            <v-col xs="12" sm="12" md="12" v-for="(person, index) in installation.people" :key="index">
                                <v-card>
                                    <v-card-title>{{ person.name }}</v-card-title>
                                    <v-card-text>
                                        {{ person.cellphone }}
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-row-reverse">
                <v-btn color="primary" target="_blank" :href="`https://vertex.ap3k.co/lio/${installation.id}`">
                    Open LIO
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-container>
</template>
  
<script lang="ts">
import { defineComponent } from 'vue';

interface Installation {
    id: number;
    name: string;
    area: string;
    address: string;
    status: string;
    required_payment: string;
    lio_type: string;
    people: {
        name: string;
        cellphone: string;
    }[];
}

export default defineComponent({
    props: {
        installation: {
            type: Object as () => Installation,
            required: true,
        },
    },
    data() {
        return {
            isLoading: true, // Set isLoading to true initially
        };
    },
    methods: {
        formatFieldName(fieldName: string): string {
            // Convert underscore-separated string to title case
            return fieldName
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
    },
    watch: {
        // Watch for changes in the installation prop
        installation: {
            immediate: true, // Run the watcher immediately
            handler(newVal) {
                if (newVal && Object.keys(newVal).length > 0) {
                    this.isLoading = false; // Set isLoading to false when installation data is available
                }
            },
        },
    },
});
</script>

<style>
/* Your styles here */
</style>
