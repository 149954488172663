<template>
    <div>
        <div>Hello World CustomerSupportLanding</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "CustomerSupportLanding",
    data: () => ({}),
    methods: {},
    onMounted() {
        console.log("CustomerSupportLanding mounted");
    },
});

</script>
