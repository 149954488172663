<template>
    <div>
        <div>Hello World ScheduleLanding</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ScheduleLanding",
    data: () => ({}),
    methods: {},
    onMounted() {
        console.log("ScheduleLanding mounted");
    },
});

</script>
