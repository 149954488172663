import TicketView from './views/TicketView.vue'
import TicketContent from '@/components/TicketContent.vue'
import Dashboard from './views/Dashboard.vue'
import ManagerDashboard from './views/ManagerDashboard.vue'
import CallList from './views/CallList.vue'
import QA from './views/QA.vue'
import LiveDashboard from './views/LiveDashboard.vue'
import Landing from './views/Landing.vue'
 
let routes = [
    {
        path: '',
        component: Landing,
        name: 'CustomerSupport',
    },
    {
        path: 'dashboard',
        component: Dashboard,
        name: 'CustomerServiceDashboard',
    },
    {
      path: 'tickets',
      component: TicketView,
      name: 'TicketView',
    },
    {
      path: 'ticket-content',
      name: 'TicketContent ',
      component: TicketContent
    },
    {
        path: 'manager-dashboard',
        component: ManagerDashboard,
        name: 'ManagerDashboard',
    },
    {
        path: 'call-list',
        component: CallList,
        name: "CallList"
    },
    {
        path: 'qa',
        component: QA,
        name: "QA"
    }
   
  ]
export default routes


