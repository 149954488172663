/* import { defineStore } from 'pinia'

interface MyStoreState {
  warehouse: string
}

export const useStore = defineStore('myStore', {
  state: (): MyStoreState => ({
    warehouse: '',
  }),
  getters: {
    // Define your computed properties (getters) here
    // For example:
    upperCaseWarehouse(state): string {
      return state.warehouse.toUpperCase()
    },
  },
  actions: {
    // Define your actions here
    // For example:
    setWarehouse(warehouse: string): void {
      this.warehouse = warehouse
    },
  },
})
 */
import { reactive } from 'vue'

export const store = reactive({
    warehouse:''
    
})

import axios from 'axios';

const axiosWarehouseInstance = axios.create({
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  withCredentials: true,
  
});
if (process.env.NODE_ENV === 'development'){
  axiosWarehouseInstance.defaults.baseURL = "http://127.0.0.1:8000/api/assets"
  axiosWarehouseInstance.defaults.timeout = 20000000
}
if (process.env.NODE_ENV === 'production'){
  axiosWarehouseInstance.defaults.baseURL = "https://api.ap3k.co/assets"
  axiosWarehouseInstance.defaults.timeout = 28000
}
console.log("this is the axios warehouse instance", axiosWarehouseInstance.defaults)
export default axiosWarehouseInstance;
