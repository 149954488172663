<template>
    <v-container>
     <v-row no-gutters class="mt-8">
        <v-col>
            <div class="float-right">
                <input v-model="search" type="search" id="default-search"
                    class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search" required>
            </div>
        </v-col>
    </v-row>
    <v-row no-gutters class="mt-8">
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto text-center">

       
            <v-data-table :headers="headers" :items="listType" :search="search">
               
                <template v-slot:[`item.date_created`]="{ value }">
                    {{ formatDate(value.date_created) }}
                </template>
                <template  v-slot:[`item.received`]="{ value }">

                    {{ value.received }}
                </template>
                <template v-slot:[`item.actions`]="{ value }">
                    <v-btn class="edit-button" small @click="openEditDialog(value.id,true)">Edit</v-btn>
                </template>
                
            </v-data-table>
        
    </v-col>
    <v-dialog v-model="editDialog">
    <Edit :edit-dialog="editDialog" :view-type="viewType" :updateDialogOption="updateDialogOption"
        :selected-product-id="selectedProductId" />
    </v-dialog>
</v-row>
</v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Edit from "../components/Edit.vue"
export default defineComponent({
    components: { Edit},
    props: {
        headers: {
            type: [] as any[],
            required: true,
        },
        listType: {
            type: [] as any[],
            required: true
        },
        viewType: {
            type: String as PropType<string>
        }

    },
    data() {
        return {
            selectedProductId: 0,
            editDialog: false,
            viewType: this.viewType,
            search: ""
        };
    },
    methods: {
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        isValidDate(dateString: string) {
            return !isNaN(Date.parse(dateString));
        },
        openEditDialog(id: number, edit: boolean) {
            this.selectedProductId = id
            this.editDialog = true
        },
        updateDialogOption(newValue: boolean) {
            this.editDialog = newValue;
        }


    },
   

})
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.edit-button{
    color: #385196;
    border: 1px  #385196 solid
}
</style>