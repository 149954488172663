<template>
    <v-container>
        <h2>Authorized Products</h2>
            <ListsToolbar :add-dialog="addDialog" :view-type="'Products'" :device-types="deviceTypes"
                :add-endpoint="addEndpoint" :edit-endpoint="editEndpoint" :asset-class="assetClass" />
            <Lists :device-types="deviceTypes" :headers="headers" :list-type="productList"
                :view-type="'Products'" />
            <LoadingOverlay v-if="loading" />
    </v-container>
</template>

<script lang="ts" >
import axiosWarehouseInstance from '@/stores/warehouse_store';
import { defineComponent } from 'vue';
import ListsToolbar from "../components/ListsToolbar.vue";
import Lists from "../components/Lists.vue";
import Add from "../components/Add.vue"
import Edit from "../components/Edit.vue"
interface Product {
    id: number,
    name: string,
    model: string,
    model_name: string,
    type: string,
    device_type: string,
    date_created: string,
    is_custom: boolean,
    track_by_identifier: boolean,
    tracking_active: boolean,
    product_code: null,
    description: null,
    supplier_id: null,

}



export default defineComponent({
    components: {
        ListsToolbar,
        Lists,
        Add,
        Edit
    },
    data: () => ({
        productList: [] as Product[],
        name: "",
        model: "",
        model_name: "",
        device_type: "",
        track_by_identifier: false,
        tracking_active: false,
        dialog: false,
        addDialog: false,
        deviceTypes: [],
        assetClass: [],

        search: "",
        headers: [
            {
                align: 'start',
                key: 'name',
                sortable: false,
                title: 'Name',
            },
            { key: 'model', title: 'Model' },
            { key: 'model_name', title: 'Model Name' },
            { key: 'device_type', title: 'Device Type' },
            { key: 'track_by_identifier', title: 'Track By Identifier' },
            { key: 'date_created', title: 'Date Created' },
            { key: 'actions', title: 'Actions' }

        ],
        addEndpoint: "add-products",
        editEndpoint: "edit-products",
        loading:false

    }),

    methods: {
        async product() {
            
            let vm = this
            vm.loading = true
            console.log("should be loadin")
            let response = await axiosWarehouseInstance
                .get("products")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.productList = response.data.products

                    }
                })
                .catch((error: string) => {
                    if (error) {

                    }
                })
                vm.loading = false

        },
        async getDeviceType() {
            let vm = this;
            let response = await axiosWarehouseInstance
                .get("device-types")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.deviceTypes = response.data.device_types;
                        vm.assetClass = response.data.asset_class

                    }
                })
                .catch((error: string) => {
                    if (error) {

                    }
                })
               

        },
    },
    created() {
        
        this.getDeviceType();
        this.product();
        this.loading = false

    }
})

</script>
<!-- <style scoped>
.uncapped-card {
    border-radius: 25px;
    background: #0693e3;
    color: white;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: Lightgreen;
}

</style> -->
