<template>
    <v-container>
        <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                    <asset-lists :headers="headers" :list-name="'Other Assets'" :list-items="otherAssetList"/>    
                </v-col>
        </v-row>
    </v-container>
    </template>
    
    <script lang="ts">
    import { defineComponent } from 'vue';
    import axiosWarehouseInstance from '@/stores/warehouse_store';
    import Vue from 'vue'
    import AssetLists from '../components/AssetLists.vue'
    
    interface OtherAssets{
        id:string
        product__name:string
        supplier__name:string
        manufacturer:string
        purchase_price:number
        stock:number
        lost:number
        deployed:number
        assigned:number
    };
    
    export default defineComponent({
        name:'OtherAssets',
        components:{
            AssetLists
        },
        data: () =>({
        otherAssetList: [] as OtherAssets[],
        loading:true,
        headers: { "Name": "product__name", 
            "Stock": "stock", 
            "Lost": "lost",
            "Deployed":"deployed",
            "Assigned":"assigned"
           },
    }),
    
     methods:{
            async getOtherAssetsList(): Promise<void>{
                let vm = this
                
                try{
                    const response = await
                    axiosWarehouseInstance.get('other-assets/list');
                    vm.otherAssetList = response.data.other_assets
                }
    
                catch(error){
                    console.error(error);
                    //throw error
                }
    
                finally{
                    this.loading=false
                }
                
            },
    },
    created(){
        this.getOtherAssetsList()
    }
    
    })
    
    </script>
    
    <style></style>