<template>
        <v-card density="compact" outlined min-width="600" justify="center">
            <v-card-title>
                <v-col class="text-center">
                    <span class="text-h5">{{ viewType }}</span>
                </v-col>

            </v-card-title>
            <v-card-text v-if="viewType == 'Products'">
                <v-text-field variant="outlined" label="Name" v-model="name">
                </v-text-field>

                <v-text-field variant="outlined" label="Model" v-model="model">
                </v-text-field>
                <v-text-field variant="outlined" label="Model Name" v-model="modelName">
                </v-text-field>
                <v-select variant="outlined" v-model="deviceType" :items="deviceTypes" label="Device Type">
                </v-select>

                <v-checkbox
                    label="Activate Tracking - Product needs to be tracked by an identifier (eg. mac address or serial number)"
                    type="checkbox" class="text-tmwGreen" v-model="identifierTracking">
                </v-checkbox>
                <v-checkbox label="Product is detectable on network" type="checkbox" class="text-tmwGreen"
                    v-model="activeTracking">
                </v-checkbox>
            </v-card-text>
            <v-card-text v-if="viewType == 'Suppliers'">
                <v-text-field variant="outlined" label="Name" v-model="name">
                </v-text-field>
                <v-text-field variant="outlined" label="Contact" v-model="contact">
                </v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-col>
                    <v-btn class="bg-tmwRed" @click="closeDialog()">Cancel</v-btn>
                </v-col>
                <v-divider></v-divider>
                <v-col>
                    <v-btn v-if="viewType == 'Products'" class="bg-NavigationDrawer"
                        @click="editSubmitFormProduct(selectedProductId)">Save</v-btn>
                    <v-btn v-if="viewType == 'Suppliers'" class="bg-NavigationDrawer"
                        @click="editSubmitFormSupplier(selectedProductId)">Save</v-btn>
                </v-col>

            </v-card-actions>
        </v-card>
    
    
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import axios from 'axios'

export default defineComponent({
    props: {
        editDialog: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
        candidateName: {
            type: String as PropType<string>,
        },
        candidateModel: {
            type: String as PropType<string>,
        },
        candidateModelName: {
            type: String as PropType<string>,
        },
        candidateDeviceType: {
            type: String as PropType<string>,
        },
        candidateContact: {
            type: String as PropType<string>,
        },
        trackByIdentifier: {
            type: Boolean as PropType<boolean>,
        },
        isTrackingActive: {
            type: Boolean as PropType<boolean>,
        },
        viewType: {
            type: String as PropType<string>,
        },
        deviceTypes: {
            type: Array
        },
        updateDialogOption: {
            type: Function,
            required: true
        },
        selectedProductId: {
            type: Number as PropType<number>,
            default: 0

        }
    },
    data() {
        return {
            editDialog: this.editDialog,
            name: this.candidateName,
            model: this.candidateModel,
            modelName: this.candidateModelName,
            deviceType: this.candidateDeviceType,
            contact: this.candidateContact,
            identifierTracking: this.trackByIdentifier,
            activeTracking: this.isTrackingActive,
            selectedProductId: this.selectedProductId,
        };
    },
    methods: {
        async editSubmitFormSupplier(id: number) {

            const updatedSupplier = {
                id: id,
                name: this.name,
                contact_number: this.contact,

            };

            let vm = this;
            
            let response = await axios
                .post("edit-supplier/", updatedSupplier)
                .then(function (response) {
                })
                .catch((error: string) => {
                    if (error) {
                    }
                });
        },
        async editSubmitFormProduct(id: number) {
            const updatedProduct = {
                id: id,
                name: this.name,
                model: this.model,
                model_name: this.modelName,
                device_type: this.deviceType,
                track_by_identifier: this.identifierTracking,
                tracking_active: this.activeTracking,

            };

            let vm = this;
            let response = await axios
                .post("edit-product", updatedProduct)
                .then(function (response) {
                })
                .catch((error: string) => {
                    if (error) {

                    }
                })
        },
        formartDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },

        closeDialog() {
            this.toggleDialogOption()
        },
        toggleDialogOption() {
            this.updateDialogOption(!this.editDialog);
        },

    }
})
</script>
<style>
.center-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>