<template>
    <v-col v-if="!dialogWaybill && !audit" cols="12">
        <h1 class="text-center">Transfers</h1>

        <v-row no-gutters class="justify-end">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                <v-btn flat class="float-right" @click="dialog = true"><span id="add-info">Request<span class="text-center"
                            id="add-button"><a>+</a></span></span></v-btn>
            </v-col>
            <div class="float-right mt-3">
                <input v-model="search" type="search" id="default-search"
                    class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search" required>
            </div>
        </v-row>
        <v-row no-gutters>

            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                <v-data-table :headers="headers" :items="requests" :search="search">
                    <template v-slot:item.manage="{ item }">
                        <v-row no-gutters justify="space-between">


                            <v-btn @click="viewTransfer(item.props.title.id)" class="bg-navigationDrawer"
                                density="compact">View</v-btn>
                            <v-btn @click="manageTransfer(item.props.title.id)" class="bg-navigationDrawer"
                                density="compact">Manage</v-btn>
                            <v-btn v-if="item.props.title.status == 'APPROVED'" @click="createWaybill(item.props.title.id)"
                                class="bg-navigationDrawer" density="compact">Create
                                Waybill</v-btn>

                            <v-btn v-if="item.props.title.status == 'TRANSIT'" @click="auditDelivery(item.props.title.id)"
                                class="bg-navigationDrawer" density="compact">Audit Delivery</v-btn>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" enctype="multipart/form-data" lazy-validation valid="valid">
                    <v-autocomplete v-model="product" :items="formOptions.products" variant="outlined"
                        label="Select Product"></v-autocomplete>
                    <v-autocomplete variant="outlined" :items="formOptions.warehouses" v-model="warehouse"
                        label="Select Destination Warehouse">
                    </v-autocomplete>
                    <v-text-field variant="outlined" v-model="quantity" label="Number of products">

                    </v-text-field>
                    <v-btn class="me-4 float-right bg-navigationDrawer" @click="addTransferProduct">
                        add
                    </v-btn>
                    <v-btn class="me-4 float-right bg-navigationDrawer" @click="submitRequest()">
                        submit
                    </v-btn>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="viewRequest" max-width="700px">
            <v-card>
                <v-card-title>Products Requested</v-card-title>
                <v-card-item>
                    <v-data-table :items-per-page="3" :headers="[
                        {
                            align: 'start',
                            key: 'product',
                            sortable: false,
                            title: 'Product',
                        },
                        {
                            align: 'start',
                            key: 'quantity',
                            sortable: false,
                            title: 'Quantity',
                        },

                    ]" :items="transferProducts">

                    </v-data-table>
                    {{ product }}
                </v-card-item>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogManage" max-width="700px">
            <v-card>
                <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" enctype="multipart/form-data" lazy-validation valid="valid">


                    <v-select variant="outlined" v-model="selectedStatus" :items="['Approved', 'Rejected', 'Delivered']"
                        label="Change status"></v-select>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-btn class="bg-navigationDrawer" @click="changeStatus()">Submit</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogWaybill" max-width="700px">
            <v-card>
                <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" enctype="multipart/form-data" lazy-validation valid="valid">



                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-btn class="bg-navigationDrawer" @click="changeStatus()">Submit</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>

        </v-dialog>
    </v-col>
    <v-col v-if="dialogWaybill && !sign && !audit" cols="12">
        <h1 class="text-center">Create Waybill</h1>
        <v-row no-gutters class="mt-9">


            <v-col v-if="openQRScanner" cols="12" xl="3" md="3" xxl="3" sm="12">
                <div>
                    <video ref="video" id="video"></video>
                </div>
            </v-col>
            <v-col v-if="!openQRScanner" cols="12" xl="5" md="5" xxl="5" sm="12">
                <h2>Scan out assets</h2>

                <v-autocomplete v-model="scanOutProduct" variant="outlined" :items="scanOutProducts"
                    label="Select Product To Scan out"></v-autocomplete>
                <v-text-field variant="outlined" placeholder="Scan in identifiers" v-model="identifier"
                    @change="checkBarScannerValue()"></v-text-field>
                <v-btn @click="startQRReader()" class="bg-navigationDrawer">Use QR Scanner</v-btn>
            </v-col>
            <v-col v-if="scannedIdentifiers.length > 0" cols="12" xl="5" md="5" xxl="5" sm="12" class="mx-auto">
                <h2>Assets to be Transferred</h2>
                <v-data-table :items-per-page="3" :headers="[
                    {
                        align: 'start',
                        key: 'identifier',
                        sortable: false,
                        title: 'Identifier',
                    }, {
                        align: 'start',
                        key: 'product',
                        sortable: false,
                        title: 'Product',
                    },



                ]" :items="scannedIdentifiers">

                </v-data-table>
                <p></p>
                <div class="float-right">
                    <v-btn class="bg-navigationDrawer mt-7" @click="getSignature()">Complete Waybill</v-btn>
                </div>

            </v-col>
        </v-row>


    </v-col>

    <v-col v-if="sign" cols="12" class="">
        <h1 class="text-center">Confirm Details</h1>

        <v-row no-gutters justify="center">
            <v-col cols="12" xl="12" md="7" xxl="4" sm="12">
                <v-data-table :items-per-page="3" :headers="[{
                    align: 'start',
                    key: 'identifier',
                    sortable: false,
                    title: 'Identifier',
                },
                {
                    align: 'start',
                    key: 'product',
                    sortable: false,
                    title: 'Product',
                },]" :items="scannedIdentifiers">

                </v-data-table>



            </v-col>

        </v-row>
        <v-row no-gutters justify="center">
            <v-col cols="12" xl="12" md="7" xxl="4" sm="12">
                <VueSignaturePad class="bg-grey mt-7" height="200px" width="700px" ref="signaturePad" />
                <div class="float-right">
                    <v-btn class="mt-3 bg-navigationDrawer" @click="downloadPdf()">Save</v-btn>
                </div>

            </v-col>
        </v-row>






    </v-col>

    <v-col v-if="audit" cols="12" class="">
        <v-row no-gutters class="mt-9">

            <v-col v-if="openQRScanner" cols="12" xl="3" md="3" xxl="3" sm="12">
                <div>
                    <video ref="video" id="video"></video>
                </div>
            </v-col>
            <v-col v-if="!openQRScanner" v-cols="12" xl="5" md="5" xxl="5" sm="12">
                <h2>Scan in assets</h2>
                <v-text-field variant="outlined" placeholder="Scan in identifiers" v-model="identifier"
                    @change="checkBarScannerValue()"></v-text-field>
                <v-btn @click="startQRReader()" class="bg-navigationDrawer">Use QR Scanner</v-btn>

            </v-col>
            <v-col v-if="scannedIdentifiers.length > 0" cols="12" xl="5" md="5" xxl="5" sm="12" class="mx-auto">
                <h2>Assets to be Transferred</h2>
                <v-data-table :items-per-page="3" :headers="[
                    {
                        align: 'start',
                        key: 'identifier',
                        sortable: false,
                        title: 'Identifier',
                    },



                ]" :items="scannedIdentifiers">

                </v-data-table>
                <div class="float-right">
                    <v-btn class="bg-navigationDrawer mt-7" @click="submitAudit()">Audit Waybill</v-btn>
                </div>

            </v-col>
        </v-row>
    </v-col>
    <div class="text-center">
        <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="7000"
            transition="dialog-bottom-transition">
            <span>{{ snackbarResponse }}</span>
            <template v-slot:actions>
                <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
    <v-row>
        <LoadingOverlay v-if="loading" />
    </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import axiosWarehouseInstance from '@/stores/warehouse_store';
import { ref, onMounted } from 'vue'
import jsPDF from 'jspdf'
import VueSignaturePad from "../components/VueSignaturePad.vue"
import { faL } from '@fortawesome/free-solid-svg-icons'
import tmwImage from "../../../assets/img/tmwf_mini_icon.png"; // Replace "example.jpg" with your image file name and extension
import { MultiFormatReader, BarcodeFormat, QRCodeReader } from '@zxing/library';
import { BrowserMultiFormatReader } from '@zxing/library';
import scannerSound from "/scanner.mp3"
interface FormOptions {
    products: Array<String>,
    invoices: Array<String>,
    warehouses: Array<String>
} 
interface Request {
    id: number;
    status: 'PENDING_APPROVAL' | 'APPROVED' | 'IN_TRANSIT' | 'DELIVERED' | 'REJECTED';
    user_requested_id: number;
    date_requested: string;
    date_approved: string | null;
    user_approved_id: number | null;
    date_transit: string | null;
    user_transit_id: number | null;
    date_delivered: string | null;
    user_delivered_id: number | null;
    date_rejected: string | null;
    user_rejected_id: number | null;
    name: string;
    username: string;
    waybill: boolean;
    products: TransferProducts[];
    [key: string]: any

}
interface TransferProducts {
    name: string,
    quantity: number,
    [key: string]: any
}
export default defineComponent({
    components: {

        VueSignaturePad
    },
    data() {
        return {
            headers: [
                {
                    align: 'start',
                    key: 'id',
                    sortable: false,
                    title: 'Transfer',
                },

                { key: 'username', title: 'Requested By' },
                { key: 'date_requested', title: 'Date Requested' },
                { key: 'status', title: 'Status' },
                { key: 'manage' },
            ] as any,
            search: "",
            requests: [] as Request[],
            product: '',
            formOptions: {} as FormOptions,
            dialog: false,
            dialogManage: false,
            dialogWaybill: false,
            manifestFile: "",
            quantity: "",
            selectedStatus: '',
            transferId: 0,
            identifier: "",
            existingIdentifiers: [] as string[],
            candidateIdentifiers: [] as string[],
            snackbarResponse: "",
            snackbarType: "error",
            openSnackBar: false,
            scannedIdentifiers: [] as any[],
            warehouse: "",
            sign: false,
            tmwImage,
            loading: false,
            audit: false,
            deliveredIdentifiers: [] as string[],
            openQRScanner: false,
            transferProducts: [] as TransferProducts[],
            viewRequest: false,
            scanOutProducts: [] as any[],
            scanOutProduct: ""

        }
    },
    setup() {
        //scanner soun
        //const scannerSound = new Audio('./scannerSound.mp3')
        const scanner = new Audio(scannerSound)
        // console.log("this is the scanner sound", scannerSound)
        const reader = ref(new BrowserMultiFormatReader());
        const devices: MediaDeviceInfo[] = [];
        let selectedDevice: MediaDeviceInfo | null = null;
        let videoStream: MediaStream | null = null;
        let video = ref<HTMLVideoElement | null>(null);
        const qrResult = ref("");

        const initReader = async () => {
            ; reader.value = new BrowserMultiFormatReader();
        };

        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');

            if (videoDevices.length > 0) {
                selectedDevice = videoDevices[0]; // Select the first video input device, you can use a different strategy to select a device.
                startScanning();
            } else {
                // Handle the case when no video input devices are available.
                console.error('No video input devices found.');
            }
        };

        const startScanning = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: selectedDevice!.deviceId
                }
            });
            videoStream = stream;
            if (video.value !== null)
                video.value.srcObject = stream;
            reader.value.decodeFromVideoDevice(
                selectedDevice!.deviceId,
                'video',
                result => {
                    if (result) {
                        qrResult.value = result.getText();
                        console.log("this is what we got", result)
                        scanner.play()
                        //stopScanning()
                        return qrResult.value;

                    }
                    else {
                        return 'No bar code or QR code found in the video stream'
                    }
                }
            )

        };
        /*  const stopScanning = () => {
             if (videoStream !== null) {
                 const tracks = videoStream.getVideoTracks();
                 tracks.forEach(track => {
                     track.stop();
                 });
                 videoStream = null
                 video.value = null
                 console.log("this is the video stream", videoStream)
                 console.log("this is the video", video.value)
             }
         }; */
        return {
            reader,
            devices,
            selectedDevice,
            videoStream,
            video,
            qrResult,
            initReader,
            getDevices,
            startScanning,

        }
    },
    methods: {
        async getFormOptions() {

            const vm = this
            vm.loading = true
            const response = await axiosWarehouseInstance
                .get('audit/form-options')
                .then(function (response) {
                    if (response.status == 200) {
                        vm.formOptions = response.data
                    }
                })
            vm.loading = false
        },
        handleFileUpload(event: any) {
            this.manifestFile = event.target.files[0];
            console.log("this is the manifest file", this.manifestFile)
        },
        manageTransfer(transfer_id: any) {

            this.dialogManage = true
            this.transferId = transfer_id

            console.log()
        },
        viewTransfer(transfer_id: any) {
            this.viewRequest = true
            this.transferId = transfer_id
            let items = this.requests.find((item) => item.id === this.transferId);
            console.log("items", items)
            if (items)
                this.transferProducts = items.products
            this.scanOutProducts = this.transferProducts.map((product: any) => product.product);
        },
        createWaybill(transfer_id: any) {
            this.dialogWaybill = true
            this.transferId = transfer_id
            console.log("this sis the transfer id: ", this.transferId)
            let items = this.requests.find((item) => item.id === this.transferId);
            console.log("items", items)
            if (items)
                this.transferProducts = items.products
            this.scanOutProducts = this.transferProducts.map((product: any) => product.product);

            console.log("this is the t/fer", this.transferProducts)
            //console.log("this is the t/fer", this.scanOutProducts)
        },
        async submitRequest() {
            let vm = this
            vm.loading = true
            vm.dialog = false
            const response = axiosWarehouseInstance.post('/ibt/transfer/request/', {
                products: vm.transferProducts,
                warehouse: vm.warehouse
            }).then(
                function (response) {
                    if (response.status == 200) {
                        vm.snackbarResponse = response.data.message
                        vm.snackbarType = 'success'
                        vm.showSnackbar()
                    }
                }
            ).catch((error) => {
                if (error.response) {
                    vm.snackbarResponse = error.response.data.message
                    vm.showSnackbar()
                }

            })
            vm.loading = false

        },
        async requestLists() {
            const vm = this
            const response = axiosWarehouseInstance.get('ibt/transfer/list').then(
                function (response) {
                    if (response.status = 200) {
                        vm.requests = response.data.requests
                    }
                }
            ).catch((error) => {
                if (error.response) {
                    vm.snackbarResponse = error.response.data.message
                }
                vm.showSnackbar()
            })

        },
        async changeStatus() {
            const vm = this
            vm.dialogManage = false
            vm.loading = true
            const response = await axiosWarehouseInstance.post('ibt/transfer/', {
                new_status: vm.selectedStatus,
                request_id: vm.transferId
            })
            vm.loading = false
        },
        checkBarScannerValue() {
            if (this.existingIdentifiers.includes(this.identifier)) {
                this.snackbarResponse = `${this.identifier} already loaded in warehouse`
                this.identifier = ""
                this.showSnackbar()
            }
            if (this.candidateIdentifiers.includes(this.identifier)) {
                this.snackbarResponse = `${this.identifier} already scanned`
                this.identifier = ""
                this.showSnackbar()
            }

            else {
                this.scannedIdentifiers.push({
                    "identifier": this.identifier,
                    "product": this.scanOutProduct
                })
                this.candidateIdentifiers.push(this.identifier)
                this.identifier = ""
            }

        },
        showSnackbar(): void {
            setTimeout(() => {
                this.openSnackBar = true;
            }, 3000);
        },
        getSignature() {
            this.sign = true
        },
        downloadPdf() {
            let pdfName = this.transferId.toString()
            let doc = new jsPDF()

            this.addInvoiceContent(doc, pdfName);
            console.log("this is the doc", doc)
            const pdfDataUrl = doc.output('dataurlstring').split(',').slice(-1)[0];
            console.log("this is the pdf url", pdfDataUrl)
            this.submitWaybill(pdfDataUrl)
            doc.save(pdfName + '.pdf')
        },
        addInvoiceContent(doc: any, pdfName: string) {
            // Set the font size and styles for the PDF content

            doc.setFontSize(12);
            doc.setFont('helvetica', 'normal');

            // Add the invoice title
            doc.addImage(tmwImage, 160, 0)
            doc.text(`WayBill for request ${pdfName}`, 10, 10);
            // Add the invoice details (e.g., date, invoice number, etc.)
            doc.text('Date: ' + this.getCurrentDate(), 10, 20);
            doc.text('Destination Warehouse: Paarl', 10, 30);
            doc.text('Pickup Warehouse: Gardens', 10, 40);


            // Add the items table headers
            doc.text('Product', 10, 70);
            doc.text('Quantity', 80, 70);
            doc.text('Requested By', 120, 70);

            // Sample invoice items (replace this with your own data)
            const items = this.transferProducts  // Add more items as needed


            // Add the invoice items to the table
            let startY = 80;
            items.forEach((item, index) => {
                const { product, quantity } = item;
                const rowY = startY + index * 10;
                doc.text(product, 10, rowY);
                doc.text(quantity.toString(), 80, rowY);
                doc.text("user", 120, rowY);
            });

            // Calculate the total amount
            const total = items.reduce((acc, item) => acc + item.quantity, 0);

            // Add the total amount to the bottom of the table
            doc.text('Total Quantity:' + total.toString(), 120, startY + items.length * 10 + 10);
            const signatureText = '______________________________';
            const signaturePad: any = this.$refs.signaturePad;


            // Add the signature image to the PDF
            if (signaturePad) {
                let signatureDataURL = signaturePad.saveSignature();
                let signature = signatureDataURL.data.split(",").slice(-1)[0]
                doc.addImage(signature, 'PNG', 20, startY + items.length * 10 + 25, 10, 10); // Adjust the coordinates as needed
            }
            doc.text(signatureText, 10, startY + items.length * 10 + 35);
        },

        getCurrentDate() {
            const currentDate = new Date();
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1;
            const year = currentDate.getFullYear();
            return `${day}/${month}/${year}`;
        },
        async submitWaybill(pdf: string) {
            let vm = this
            vm.loading = true
            const response = await axiosWarehouseInstance.post(
                'ibt/waybill/', {
                transfer_id: vm.transferId,
                identifiers: vm.scannedIdentifiers,
                pdf: pdf
            }
            ).then(
                function (response) {
                    if (response.status == 200) {
                        alert("Successfully submitted waybill")
                    }
                }
            ).catch(
                (error) => {
                    if (error.response) {
                        vm.snackbarResponse = error.response.data.message
                    }
                    vm.showSnackbar()
                }

            )

            vm.loading = false

        },
        auditDelivery(transfer_id: number) {
            this.audit = true
            this.transferId = transfer_id

        },
        async submitAudit() {
            let vm = this
            vm.loading = true
            this.audit = true
            const response = await axiosWarehouseInstance.post(
                'ibt/transfer/audit/', {
                identifiers: this.candidateIdentifiers,
                transfer_id: this.transferId
            }
            ).then(
                function (response) {
                    if (response.status == 200) {
                        alert("Audit Successful")
                    }
                }
            ).catch(
                (error) => {
                    if (error.response) {
                        vm.snackbarResponse = error.response.data.message
                    }
                    vm.showSnackbar()
                }
            )
            vm.loading = false


        },

        startQRReader() {
            this.initReader()
            this.getDevices()
            this.openQRScanner = true
        },
        checkQRReaderValues(identifiers: string[]) {
            for (let identifier of identifiers) {
                if (this.existingIdentifiers.includes(identifier)) {
                    this.snackbarResponse = `${identifier} already loaded in warehouse`
                    this.showSnackbar()
                }
                if (this.candidateIdentifiers.includes(identifier)) {
                    this.snackbarResponse = `${identifier} already scanned`
                    this.showSnackbar()
                }
                else {
                    this.scannedIdentifiers.push({
                        "identifier": identifier,
                        "product": this.scanOutProduct
                    })
                    this.candidateIdentifiers.push(identifier)
                }
            }
        },
        addTransferProduct() {
            this.transferProducts.push({
                name: this.product,
                quantity: parseInt(this.quantity)
            })
            this.product = ""
            this.quantity = ""
        }
    },
    watch: {
        qrResult: function (qrResult) {
            if (qrResult.split("\n").length > 1) {
                this.checkQRReaderValues(this.qrResult.split("\n"))
            }
            else {

            }
        }
    },
    mounted() {
        this.getFormOptions()
        this.requestLists()
    }

})

</script>

<style>
.signature-pad-container {
    border: 1px solid black;
    padding: 10px;
}
</style>