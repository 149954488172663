<template>
    
    <v-row class="bg-lightBlue" no-gutters>
        
        <v-col cols="12" sm="12" md="12" lg="7" xl="7">

            <v-col>
                <v-col>

                    <v-card class="bg-white">

                        <v-card-item v-if="ticketInfo">
                            <p class="text-strongRed font-weight-bold button-text">TICKET #{{ ticketId }}</p>
                        </v-card-item>


                        <v-card-item class="text-start">
                            <div class="mt-2">
                                <p class="mb-1 font-weight-bold button-text">Query</p>
                                <v-card id="query-card">
                                    <v-card-text>{{ ticketInfo.description }}</v-card-text>
                                </v-card>
                            </div>

                        </v-card-item>

                        <v-card-item class="text-start">
                            <div class="mt-2">
                                <p class="mb-1 font-weight-bold button-text">Add Comment</p>

                                <v-responsive class=" mb-2 mt-3 " max-width="700">
                                    <v-textarea v-model="commentNotes" rows="4" variant="outlined" clearable
                                        hide-details="auto" label="Enter a message"></v-textarea>
                                </v-responsive>

                                <v-responsive class=" mb-2 mt-5 " max-width="700">
                                    <v-file-input v-model="images" class="" :rules="[(v) => !!v || 'Image is required']"
                                        accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                        variant="outlined" label="Image of your document(Optional)">
                                    </v-file-input>
                                </v-responsive>
                                <v-responsive class=" mb-2 mt-3 text-end " max-width="700">
                                    <v-btn class="button-orange" size="small" @click="addComments()">
                                        <p class="font-weight-bold">SAVE</p>
                                    </v-btn>

                                </v-responsive>



                            </div>
                        </v-card-item>
                        <v-card-item v-if="ticketInfo.ticket_node" class="text-start">
                            <div class="mt-2">
                                <p class="mb-1 font-weight-bold button-text">Latest CPE Test</p>
                                {{ }}
                                <v-responsive class=" mb-2 mt-3 " max-width="700">
                                    <v-col v-for="(item, index) in additionalTicketInfo.cpe_test" :key="item.check" cols="12" md="12">
                                        <v-card class="mb-3">
                                            <v-card-text>
                                                    <div class="headline mb-2">{{index+1}} . {{ item.check }}:{{ item.message }}</div>
                                                    
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-responsive>


                            </div>
                        </v-card-item>
                    </v-card>
                </v-col>
            </v-col>

        </v-col>
        <!-- Manage Ticket -->
        <v-col cols="12" sm="12" md="12" lg="5" xl="5">
            <v-row>
                <v-col cols="12" sm="6" md="11" lg="12" xl="12" class="pl-4 pa-5">
                    <v-col>
                        <v-card class="customer-info">
                            <v-card-title class="text-body-2 font-weight-bold text-start ">
                                Customer Information
                            </v-card-title>
                            <v-card flat class="pl-2 pr-2 customer-info" :href="getCustomerViewUrl()" target="_blank">
                                <v-card-item class="bg-bluePaint">

                                    <v-row class="d-flex align-center">
                                        <v-col cols="auto">
                                            <div class="d-flex align-center">
                                                <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                    width="30" class="bg-bluePaint">
                                            </div>
                                        </v-col>
                                        <v-col cols="auto">
                                            <p class="text-body-1">{{ ticketInfo.customer }}</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <p>{{ ticketInfo.mobile_number }}</p>
                                        </v-col>
                                    </v-row>

                                </v-card-item>
                            </v-card>
                            <v-card-item class="pl-10">
                                <v-row v-for="(info, key) in ticketInfo" :key="key">
                                    <template v-if="shouldDisplayInfo(key as string)">
                                        <v-col cols="6" class="card-text px-4">{{ key }}</v-col>
                                        <v-col class="card-text px-1" cols="6" v-if="key !== 'ticket_node'">
                                            {{ info }}
                                        </v-col>
                                        <v-col class="card-text px-1" cols="6" v-else>
                                            <a :href="getTicketNodeUrl(info)">{{ info }}</a>
                                        </v-col>
                                    </template>
                                </v-row>

                            </v-card-item>
                        </v-card>
                    </v-col>
                </v-col>
            </v-row>

            <v-row class="mt-7">
                <v-col cols="12" sm="6" md="11" lg="12" xl="12" class="pl-4 pa-5">

                    <v-col>
                        <v-card class="customer-info">
                            <v-card-title class="mb-1 text-start">
                                <p class="text-body-2 font-weight-bold">Service Menu</p>
                            </v-card-title>

                            <v-card-item>

                                <v-row no-gutters class="pb-2">
                                    <v-col cols="6" @click="poolDialog = true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto" class="">
                                                <v-btn size="x-small" class="button-red" height="25" width="8">
                                                    <v-icon size="22"
                                                        class="text-white">mdi-account-arrow-right-outline</v-icon></v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">
                                                Release ticket to Pool
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="6" @click="assignToDialog = true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">
                                                <v-btn size="x-small" class="button-red" height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-wifi</v-icon>
                                                </v-btn>

                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">
                                                <p>Assign ticket to node</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters class="pb-2">
                                    <v-col cols="6" class="text-center" @click="escalateDialog = true">
                                        <v-row no-gutters align="center">

                                            <v-col cols="auto">
                                                <v-btn size="x-small" class="button-blue" height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-chevron-up</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">
                                                <p>Escalate</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="6" click="delegateDialog = true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">
                                                <v-btn size="x-small" class="button-blue" height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">

                                                <p>Delegate</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters class="pb-2">
                                    <v-col cols="6" class="text-center" @click="tagTeammateDialog = true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">

                                                <v-btn size="x-small" class="button-orange" height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-at</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">
                                                <p>Tag team mate</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="6" class="text-center pb-2 " @click="ticketTypeDialog = true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">

                                                <v-btn size="x-small" class="button-orange" height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-ticket</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">

                                                <p>Change ticket type</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pb-2">
                                    <v-col col="6" class="justify-start" align-self="center" v-if="customerMeta">
                                        <v-row no-gutters align="center">
                                            
                                            <v-col cols="auto">
                                                <v-btn size="x-small"  v-if="customerMeta.can_send_turn" class="text-center  button-dark"
                                                    target="_blank"  height="25" width="8">
                                                    <v-icon size="22" class="text-white">mdi-message-text</v-icon>
                                                </v-btn>
                                                <v-btn size="x-small" v-else  class=" button-dark" disabled
                                                    target="_blank" :href="customerMeta.turn_permalink" block
                                                    append-icon="mdi-message-text">
                                                </v-btn>

                                              
                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">

                                                <p> Open turn conversation</p>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                    <v-col col="6" class="justify-start" align-self="center" @click="troubleshootCPE()">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">
                                                <v-btn size="x-small" class=" button-dark" block>
                                                    <v-icon size="22" class="text-white">mdi-satellite</v-icon>
                                                </v-btn>

                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">

                                                <p>Troubleshoot CPE</p>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>
                                   <v-row no-gutters class="pb-2">
                                    
                                    <v-col col="6" class="justify-start" align-self="center" @click="statusDialog=true">
                                        <v-row no-gutters align="center">
                                            <v-col cols="auto">
                                                <v-btn size="x-small" class=" button-dark" block>
                                                    <v-icon size="22" class="text-white">mdi-ticket-outline</v-icon>
                                                </v-btn>

                                            </v-col>
                                            <v-col cols="auto" class="button-text pl-1">

                                                <p>Change Ticket Status</p>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>

                            </v-card-item>





                        </v-card>
                    </v-col>
                </v-col>
            </v-row>

            <v-row class="mt-7">
                <v-col cols="12" sm="6" md="11" lg="12" xl="12" class="pl-4 pa-5">
                    <v-col>
                        <v-card class="customer-info ">
                            <v-card-title class="font-weight-bold text-start"> Manage Ticket </v-card-title>
                            <v-card-item>

                                <v-row no-gutters align="center">
                                    <v-col cols="auto">

                                        <v-btn height="25" width="2" class="text-center button-red" size="x-small"
                                            @click="ticketDialog = true">
                                            <v-icon size="22" class="text-white">mdi-close-circle-outline</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto" class="button-text pl-1">Close Ticket</v-col>
                                </v-row>
                                <v-row no-gutters align="center" class="mt-2 mb-3">
                                    <v-col cols="auto">

                                        <v-btn height="25" width="2" class="text-center button-blue" size="x-small"
                                            @click="confirmSnooze()">
                                            <v-icon size="22" class="text-white">mdi-alarm-snooze</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto" class="button-text pl-1">Snooze Ticket</v-col>
                                </v-row>

                                <!-- <v-row class="mt-n1">
                                            <v-col cols="auto" class="mb-2">
            
                                                <v-btn height="25" width="2" class="text-center button-red" size="x-small"  @click="confirmSnooze()">
                                                    <v-icon size="22" class="text-white">mdi-alarm-snooze</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto" class="button-text">Snooze Ticket</v-col>
                                        </v-row>
                                     -->
                                <v-row no-gutters align="center">
                                    <v-col cols="9">
                                        <v-select density="compact" variant="solo" :items="snoozeOptions"
                                            label="Select date" v-model="selectedSnooze"></v-select>
                                    </v-col>

                                </v-row>


                            </v-card-item>

                        </v-card>
                    </v-col>
                </v-col>
            </v-row>

            <v-row no-gutters align="center">
                <v-col cols="12" sm="6" md="11" lg="12" xl="12" class="">
                    <v-col>
                        <v-card class="customer-info">
                            <v-card-title class="text-start text-body-2 font-weight-bold">Ticket History</v-card-title>
                            <v-card-item v-for="(ticket, index) in ticketHistory" :key="index">
                                <v-col class="button-text"><v-icon>mdi-message-text-fast</v-icon>{{ ticket.comment
                                }}</v-col>
                                <v-col class="mt-n3 button-text">{{ ticket.description }}</v-col>
                                <v-col class="mt-n3 button-text">{{ formatDate(ticket.date_created) }}
                                    <v-chip class="" color="orange" text-color="white" v-if="index === 0">
                                        latest
                                    </v-chip>
                                </v-col>
                            </v-card-item>
                        </v-card>
                    </v-col>
                </v-col>
            </v-row>


        </v-col>
    </v-row>
    <!-- dialogs -->
    <v-row justify="center">
        <v-dialog v-model="statusDialog" width="500">


            <v-card density="compact" outlined max-width="600" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Change Ticket Status</span>
                    </v-col>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-item>
                    <v-select v-model="ticketStatus" :items="ticketStatusChoices" label="Ticket Status Choices"></v-select>
                    <v-textarea variant="outlined" rows='1' v-model="notes" label="Notes"></v-textarea>
                </v-card-item>
                <v-card-actions>
                    <v-row class="text-center mx-auto">
                        <v-col class="text-start">
                            <v-btn elevation="2" size="small" class="text-center bg-grey btn"
                                @click="statusDialog = false">CANCEL</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>

                            <v-btn elevation="2" size="small" class="text-center bg-bluePaint btn"
                                @click="changeTicketStatus()">Change Status
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="poolDialog" width="500">


            <v-card density="compact" outlined max-width="600" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Release to pool</span>
                    </v-col>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-item>
                    <v-select v-model="selectedCsLevel" :items="csLevelChoices" label="CS Level Choices"></v-select>
                    <v-textarea variant="outlined" rows='1' v-model="releaseNotes" label="Notes"></v-textarea>
                </v-card-item>
                <v-card-actions>
                    <v-row class="text-center mx-auto">
                        <v-col class="text-start">
                            <v-btn elevation="2" size="small" class="text-center bg-grey btn"
                                @click="poolDialog = false">CANCEL</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>

                            <v-btn elevation="2" size="small" class="text-center bg-bluePaint btn"
                                @click="releaseToPool()">RELEASE TO POOL
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="ticketTypeDialog" width="500">
            <v-card density="compact" outlined max-width="600" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Change type</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                        <v-select v-model="selectTicketType" :items="ticketTypes" label="Type"></v-select>
                </v-card-item>
                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn"
                        @click="ticketTypeDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn" @click="addTicketType()">CHANGE
                        TYPE</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-row>

    <v-row justify="center">
        <v-dialog v-model="assignToDialog" width="500">
            <v-card density="compact" outlined max-width="800" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Assign to node</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                    <v-form>

                        <v-autocomplete v-model="selectNode" :items="assignToNode" label=" select a type"></v-autocomplete>
                    </v-form>
                </v-card-item>
                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn"
                        @click="assignToDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn" @click="submitToNode()">ASSIGN</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="escalateDialog" width="500">
            <v-card density="compact" outlined max-width="600" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Escalate</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                    <v-form>
                        <v-textarea v-model="escalateNote" label="Notes" auto-grow variant="outlined" rows="1"></v-textarea>
                    </v-form>
                </v-card-item>

                <v-card-actions>
                    <span class="text-body-1">To Team:</span>
                    <v-row no-gutters align="center">
                        <v-col cols="auto">

                            <v-btn elevation="2" class="mr-5 bg-bluePaint btn" size="x-small"
                                @click="setEscalateAction('Customer Service')">CUSTOMER
                                SERVICE</v-btn>
                        </v-col>
                        <v-col cols="auto">

                            <v-btn elevation="2" class="mr-5 bg-bluePaint btn" size="x-small"
                                @click="setEscalateAction('Technical')">TECHNICAL</v-btn>
                        </v-col>
                        <v-col cols="auto">

                            <v-btn elevation="2" class="mr-5 bg-bluePaint btn" size="x-small"
                                @click="setEscalateAction('Data Management')">DATA MANAGER
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>

                <v-card-item>
                    <v-form>
                        <v-autocomplete v-model="selectUserToAssign" :items="usersList"
                            label="select a user"></v-autocomplete>
                    </v-form>
                </v-card-item>


                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn"
                        @click="escalateDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn"
                        @click="setEscalateAction('Assign')">ESCALATE</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

    </v-row>
    <v-row justify="center">
        <v-dialog v-model="tagTeammateDialog" width="500">
            <v-card density="compact" outlined max-width="800" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Tag a Teammate</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                    <v-form>

                        <v-autocomplete v-model="selectUserToAssign" :items="usersList"
                            label="Select a team mate"></v-autocomplete>
                    </v-form>
                </v-card-item>
                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn"
                        @click="tagTeammateDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn" @click="addUserTagged()">TAG
                        USER</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="delegateDialog" width="500">
            <v-card density="compact" outlined max-width="800" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Delegate</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                    <v-form>
                        <v-textarea v-model="delegateNote" label="Notes" variant="outlined" rows='1'></v-textarea>
                        <v-autocomplete v-model="selectUserToAssign" :items="usersList" label="Type"></v-autocomplete>
                    </v-form>
                </v-card-item>
                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn "
                        @click="delegateDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn"
                        @click="addDelegateTicket()">DELEGATE</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="ticketDialog" width="500">
            <v-card density="compact" outlined max-width="800" justify="center">
                <v-card-title class="bg-bluePaint">
                    <v-col class="text-center">
                        <span class="text-body-1">Close Ticket</span>
                    </v-col>
                </v-card-title>
                <v-card-item>
                    <v-form>
                        <v-textarea v-model="ticketNotes" auto-grow variant="outlined" rows="1" row-height="2"></v-textarea>
                        <v-autocomplete v-model="selectState" :items="ticketStates" label="State"></v-autocomplete>
                    </v-form>
                </v-card-item>
                <v-card-actions>
                    <v-btn elevation="2" size="small" class="ml-4 bg-grey btn" @click="ticketDialog = false">CANCEL</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn elevation="2" size="small" class="mr-5 bg-bluePaint btn"
                        @click="closeOrOpenTicket()">CLOSE</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>
    <LoadingOverlay v-if="loading" />
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
interface TicketInfo {
    customer: string
    mobile_number: string
    ticket_id: number
    source: string
    ticket_node: number
    location: string
    status: string
    area: string
    target_pool: string
    description: string
    state: boolean
    closing_comment: string
    cpe_test: any
    [key: string]: any;
}
interface AdditionalTicketInfo {
    cpe_test: any
    [key: string]: any;
}
interface CustomerMeta {
    can_send_turn: boolean
    turn_permalink: string
}
interface Cslevels {
    cs_level_choices: string[];
}
interface TicketTypes {
    ticket_types: string[];
}
interface TicketHistory {
    date_created: string;
    comment: string;
    full_name: string;
    description: string
}
interface AssignToNode {
    assign_to_node: string[]
}
interface UsersAndStates {
    users_list: string[]
    ticket_states: string[]
    ticket_created_assigned: string[]
}
interface TicketStatusChoices {
    ticket_status: string[];
}
export default defineComponent({
   
    props: {
        ticketId: {
            type: Number,
            required: true
        },
        description: {
            type: String,
        },
        isAttemptedContactUnsuccessful: {
            type: Boolean
        },
        isAttemptedContactSuccessful: {
            type: Boolean
        },
        searchedTicket: {
            type: Number || undefined,
            required: false
        },
        sortBy:{
            type:String,
            required:false
        }


    },
    data: () => ({
        ticketInfo: {} as TicketInfo,
        additionalTicketInfo: {} as AdditionalTicketInfo,
        csLevelChoices: {} as Cslevels[],
        ticketTypes: [] as TicketTypes[],
        ticketHistory: [] as TicketHistory[],
        assignToNode: [] as AssignToNode[],
        error: false,
        poolDialog: false as boolean,
        ticketTypeDialog: false as boolean,
        assignToDialog: false as boolean,
        commentDialog: false as boolean,
        ticketSummaryDialog: false as boolean,
        customerContactDialog: false as Boolean,
        selectedCsLevel: null,
        selectTicketType: null,
        selectNode: null,
        notes: '',
        contactNotes: '',
        commentNotes: '',
        releaseNotes: '',
        images: [],
        payload: {},
        csrf_token: 'csrf_token',
        delegateDialog: false as boolean,
        escalateDialog: false as boolean,
        tagTeammateDialog: false as boolean,
        ticketDialog: false as boolean,
        selectUserToAssign: null,
        usersList: {} as UsersAndStates[],
        ticketStates: {} as UsersAndStates[],
        ticketCreatedAssigned: {} as UsersAndStates[],
        customerMeta: {} as CustomerMeta,
        delegateNote: '',
        escalateNote: '',
        action: '',
        selectState: '',
        ticketNotes: '',
        snoozeOptions: [
            '30 Mins',
            '1 Hour',
            '2 Hours',
            '4 Hours',
            '8 Hours',
            '12 Hours',
            '1 Day',
            '3 Days',
            '7 Days'
        ],
        selectedSnooze: null,
        loading:false,
        ticketStatus:"",
        statusDialog:false,
        ticketStatusChoices: {} as TicketStatusChoices[],

    }),
    methods: {
        async getTicketDetail(ticketId: number) {

            let vm = this;
            vm.loading = true
            this.notes = ""
            this.contactNotes = "",
                this.commentNotes = "",
                this.releaseNotes = ""
            let response = await axios
                .get(`ticket-info/?ticket_id=${ticketId}`)
                .then(function (response) {
                    if (response.status === 200) {
                        vm.ticketInfo = response.data.display_info
                        vm.additionalTicketInfo = response.data.additional_info
                        vm.loading = false
                    }
                })
                .catch((error: string) => {
                    
                    this.error = true;
                    this.loading = false;
                })
                vm.loading = false



        },
        shouldDisplayInfo(key: string) {
            const excludedKeys = ['customer', 'mobile_number', 'ticket_id', 'target_pool'];
            return !excludedKeys.includes(key);
        },
        getTicketNodeUrl(node: string) {
            try {
                let node_id = parseInt(node.split("-")[1])
                return `https://vertex.ap3k.co/node/${node_id}/`
            }
            catch (e) {

            }


        },
        getCustomerViewUrl() {
            const baseUrl = 'https://vertex.ap3k.co'; // Replace with your base URL
            const cellphoneParam = `?cellphone=${this.ticketInfo.mobile_number}`;

            return `${baseUrl}/customer/view/${cellphoneParam}`;
        },

        async getCustomerMeta(ticketId: number) {
            let vm = this;
            vm.loading = true
            let response = await axios
                .get(`customer-meta/?ticket_id=${ticketId}`)
                .then(function (response) {
                    if (response.status === 200) {
                        vm.customerMeta = response.data
                        vm.loading = false
                    }
                })
                .catch((error: string) => {
                    
                    this.error = true;
                    this.loading = false;
                })
                vm.loading = false
        },
        async getTicketContents(ticketId: number) {
            let vm = this
            vm.loading = true
            let response = await axios
                .get(`ticket-contents/?ticket_id=${ticketId}`)
                .then(function (response) {
                    if (response.status === 200) {
                        vm.csLevelChoices = response.data.cs_level_choices;
                        vm.ticketTypes = response.data.ticket_types;
                        vm.ticketHistory = response.data.comment_and_history;
                        vm.assignToNode = response.data.assign_to_node;
                        vm.ticketStatusChoices = response.data.ticket_status
                        vm.loading = false
                    }
                })
                .catch((error: string) => {
                    
                    this.error = true;
                })
                vm.loading = false
        },
        async getUsersAndStates() {
            let vm = this
            vm.loading = true
            let response = await axios
                .get('ticket/users/states')
                .then(function (response) {
                    if (response.status === 200) {
                        vm.usersList = response.data.users_list
                        vm.ticketCreatedAssigned = response.data.ticket_created_assigned
                        vm.ticketStates = response.data.ticket_states
                        vm.loading = false
                    }
                })
                .catch((error: string) => {
                    
                    this.error = true;
                })
                vm.loading = false
        },
        async addContents() {
            this.loading = true
            try {
                const response = await axios.post('ticket/add-contents/',
                    this.payload
                );
            } catch (error) {
                
            }
            finally {
                this.notes = ""
            }
            this.loading = false
        },
        addTicketType() {
            this.payload = {
                ticket_id: this.ticketId,
                selected_ticket_type: this.selectTicketType,
                agent_action: "add_ticket_type"
            }
            this.addContents()
            this.ticketTypeDialog = false
        },
        submitToNode() {
            this.payload = {
                ticket_id: this.ticketId,
                selected_node: this.selectNode,
                agent_ation: "add_node"
            }
            this.addContents()
            this.assignToDialog = false
        },
        releaseToPool() {
            this.payload = {
                ticket_id: this.ticketId,
                cs_level_choice: this.selectedCsLevel,
                notes: this.releaseNotes,
                agent_action: "add_to_pool"
            }
            this.addContents()
            this.poolDialog = false;
        },
        async getFileInBase64(file: File): Promise<string> {
            return new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = error => reject(error);
            })
        },
        async addComments() {
            this.loading = true
            const encodedImages = new Array();
            for (let image of this.images) {
                let result = await this.getFileInBase64(image).then((res) => {
                    encodedImages.push(String(res));
                }, (failure) => {
                    
                });
            }

            this.payload = {
                ticket_id: this.ticketId,
                notes: this.commentNotes,
                image: encodedImages,
                agent_action: "add_comments"
            }
            this.addContents()
            this.loading=false

        },
        async troubleshootCPE() {
            this.loading = true
            try {
                const response = await axios.get(`troubleshoot/cpe/?ticket_id=${this.ticketInfo.ticket_id}`)
            }
            catch (error) {
                
            }
            this.loading = false
        },

        addCustomerContact(contacted: boolean) {
            this.payload = {
                ticket_id: this.ticketId,
                notes: this.contactNotes,
                is_attempted_contact_unsuccessful: !contacted,
                is_attempted_contact_successful: contacted,
                agent_action: "add_customer_contact"
            }
            this.addContents()
        },
        async addUserTagged() {
            this.loading = true
            try {
                const response = await axios.post('tag-teammate/', {
                    ticket_id: this.ticketId,
                    selected_user: this.selectUserToAssign
                });
                //success message from the API
            } catch (error) {
                
            }
            this.tagTeammateDialog = false;
            this.loading = false
        },
        async addDelegateTicket() {
            this.loading = true
            try {
                const response = await axios.post('ticket/delegate/', {
                    ticket_id: this.ticketId,
                    notes: this.delegateNote,
                    selected_user: this.selectUserToAssign
                });
                //success message from the API
            } catch (error) {
                
            }
            this.delegateDialog = false;
            this.loading = false
        },
        setEscalateAction(action: string) {
            this.action = action
            this.submitEscalateAction()

        },
        submitEscalateAction() {
            const data = {
                ticket_id: this.ticketId,
                notes: this.escalateNote,
                selected_user: this.selectUserToAssign,
                action: this.action,
            };
            axios.post('ticket/escalate/', data)
                .then(response => {
                    ///pass handle response
                })
                .catch(error => {
                    // handle error
                });
            this.escalateDialog = false;
        },
        async closeOrOpenTicket() {
            this.loading = true
            try {
                const response = await axios.post('ticket/close/open/', {
                    ticket_id: this.ticketId,
                    notes: this.ticketNotes,
                    state: this.selectState
                });
                sessionStorage.clear()
                //success message from the API
            } catch (error) {
                
            }
            this.ticketDialog = false;
            this.loading = false

        },
        async confirmSnooze() {
            this.loading = true
            try {
                const response = await axios.post('ticket/snooze/', {
                    ticket_id: this.ticketId,
                    time: this.selectedSnooze
                })
                //success message from the API
            } catch (error) {
                
            }
            this.loading = false
        },
        async changeTicketStatus(){
            this.loading = true
            let vm = this
            try {
                const response = await axios.post('ticket/change-status/', {
                    ticket_id: this.ticketId,
                    state:this.ticketStatus,
                    notes:this.notes
                }).then(function(response){
                    vm.notes = ""
                })
                //success message from the API
            } catch (error) {
                
            }
            this.notes = ""
            this.loading = false
            this.statusDialog = false

        },
        formatDate(date: string) {
            let formatedDate = new Date(date);
            return formatedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        isValidDate(dateString: string) {
            return !isNaN(Date.parse(dateString));
        },
    },
    watch: {
        // whenever question changes, this function will run
        ticketId(newTicket, old) {
            
            if (newTicket) {

                sessionStorage.setItem("persistingTicket", newTicket);
                let persistingTicket = localStorage.getItem("persistingTicket") as unknown as number;
                this.getTicketDetail(newTicket)
                
                this.getTicketContents(newTicket)
                this.getCustomerMeta(newTicket)
            }
        },
    },
    mounted() {
        this.getTicketDetail(this.ticketId)
        this.getTicketContents(this.ticketId)
        this.getUsersAndStates()
        this.getCustomerMeta(this.ticketId)
    },
});
</script>

<style scoped>
body {
    font-size: 50px;
    font-family: 'Roboto';
}

.conversation-texts {
    margin-left: 0.5rem;
    padding-top: 0.2rem;
}

#conversation-card {
    border: 1px solid rgba(107, 101, 101, 0.222)
}

#query-card {
    border: 1px solid rgba(107, 101, 101, 0.222)
}

#contact-card {
    border: 1px solid rgba(107, 101, 101, 0.222)
}

#service-menu {
    box-sizing: border-box;
    align-items: center;
    background: #FFFFFF;
    border: 0.4px solid #CCCCCC;
}



.service-action {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px;
    height: 36px;
    border: 0.4px solid #D3DBE6;
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    background: linear-gradient(90deg, #385196 0%, #2F437D 100%);
}


#service-action-turn {
    width: 600px;
    align-items: center;
    background: linear-gradient(90deg, #2F437D 0%, #385196 50%, #2F437D 100%);
    border-radius: 8px;
}

.open-and-close-ticket {
    width: 600px;
    gap: 10px;
    align-items: center;
    background: #DF291C;
    border-radius: 5px;
    margin: 0 3px 0 3px
}

#ticket-history-card {
    box-sizing: border-box;
    background: #E5E5E5;
    border: 0.4px solid #CCCCCC;
}

#ticket-history {
    background: #FFFFFF;
    border: 0.4px solid #CCCCCC;
}

.btn {
    text-transform: unset !important;
}

img {
    border-radius: 50%;
}

@media only screen and (min-width: 1020px) {
    .service-action {

        width: 500px;
    }

    #service-action-turn {
        width: 900px;
        align-items: center;
        background: linear-gradient(90deg, #2F437D 0%, #385196 50%, #2F437D 100%);
        border-radius: 8px;
    }

    .open-and-close-ticket {
        width: 830px;
        gap: 10px;
        align-items: center;
        background: #DF291C;
        border-radius: 5px;
        margin: 0 3px 0 3px
    }

}

@media only screen and (max-width: 391px) {
    .service-action {
        width: 200px;
        font-size: 17px;
    }
}

.customer-info {
    background: #E5E5E5;
    border: 1px solid rgba(147, 45, 85, 0.2);
    border-radius: 5px;
}

.card-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 15px;
    color: #292929;
}

.button-red {
    background: radial-gradient(50% 50% at 50% 50%, #932D55 0%, #85294D 100%);
}

.button-blue {
    background: radial-gradient(50% 50% at 50% 50%, #3A7CA5 0%, #346F94 100%);
}

.button-orange {
    background: radial-gradient(50% 50% at 50% 50%, #E85D04 0%, #D95704 100%);
}

.button-dark {
    background: radial-gradient(50% 50% at 50% 50%, #16425B 0%, #12374D 100%);
    border-radius: 4px;
}

.button-text {
    font-family: 'Roboto';
    font-size: 15px;
}

.button-container {
    display: flex;
}

.button-container v-btn {
    flex: 1;
}
</style>
