<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                <asset-lists :headers="headers" :list-name="'Staff Equipment List'" :list-items="staffEquipmentList" />
            </v-col>
        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '@/stores/warehouse_store';
import Vue from 'vue'
import AssetLists from '../components/AssetLists.vue'

interface StaffEquipmentList {
    id: string
    product__name: string
    supplier__name: string
    manufacturer: string
    purchase_price: number
    stock: number
    lost: number
    deployed: number
    assigned: number
};

export default defineComponent({
    name: 'InventoryList',
    components: {
        AssetLists
    },
    data: () => ({
        staffEquipmentList: [] as StaffEquipmentList[],
        loading: true,
        headers: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'stock', title: 'Stock' },
            { key: 'lost', title: 'Lost' },
            { key: 'deployed', title: 'Deployed' },
            { key: 'assigned', title: 'Assigned' },

        ],
    }),

    methods: {
        async getStaffEquipment(): Promise<void> {
            let vm = this

            try {
                const response = await
                    axiosWarehouseInstance.get('staff-equipment/list');
                vm.staffEquipmentList = response.data.staff_equipment
            }

            catch (error) {
                console.error(error);
                //throw error
            }

            finally {
                this.loading = false
            }

        },
    },
    created() {
        this.getStaffEquipment()
    }

})

</script>
    
<style></style>