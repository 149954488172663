import Landing from "./views/Landing.vue";

const routes = [
    {
        path: "",
        component: Landing,
        name: "Dashboard",
    },
    {
        path: "CallCenterFloorDashboard",
        component: () => import("./views/CallCenterFloorDashboard.vue"),
        name: "DashboardCallCenterFloor",
    }
];

export default routes;
