<template>
    <v-col cols="12" class="">
        <h1 class="text-center">Conducted Audits</h1>
        {{ formOptions }}
        <v-row no-gutters class="justify-end">
            <v-col cols="12" xs="4" sm="12" md="12" lg="12" xl="12" xxl="12">
                <v-btn flat class="float-right"><span id="add-info">Conduct Audit <span class="text-center"
                            id="add-button"><a>+</a></span></span></v-btn>
            </v-col>
            <div class="float-right">
                <input v-model="search" type="search" id="default-search"
                    class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search" required>
            </div>
        </v-row>
        <v-row no-gutters>

            <v-data-table :headers="headers" :items="desserts" :search="search">
                <template v-slot:item.actions="{ item }">
                    <v-btn density="compact" icon="mdi-download-circle"></v-btn>

                    <v-btn density="compact" icon="mdi-eye-arrow-right-outline" class="ml-7"></v-btn>
                </template></v-data-table>
        </v-row>

        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" enctype="multipart/form-data" lazy-validation valid="valid">
                    <v-autocomplete  :items="formOptions.invoices" variant="outlined" label="Select Invoice"></v-autocomplete>
                    <v-autocomplete  :items="formOptions.products" variant="outlined" label="Select Product"></v-autocomplete>
                    
                    <v-btn class="me-4 float-right bg-navigationDrawer" @click="uploadFile()">
                        submit
                    </v-btn>
                </v-form>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import axiosWarehouseInstance from '@/stores/warehouse_store';
import { ref, onMounted } from 'vue'
import axios from 'axios';
interface FormOptions {
    products: Array<String>,
    invoices: Array<String>,
}
export default defineComponent({

    data() {
        return {
            headers: [
                {
                    align: 'start',
                    key: 'name',
                    sortable: false,
                    title: 'Invoice',
                },
                { key: 'calories', title: 'Product' },
                { key: 'fat', title: 'Audited By' },
                { key: 'carbs', title: 'Date Audited' },
                { key: 'actions' },
            ] as any,
            search: "",
            desserts: [
                {
                    name: 'Frozen Yogurt',
                    calories: 159,
                    fat: 6.0,
                    carbs: 24,
                    protein: 4.0,
                    iron: 1,
                },
                {
                    name: 'Ice cream sandwich',
                    calories: 237,
                    fat: 9.0,
                    carbs: 37,
                    protein: 4.3,
                    actions: 1,

                },],
            product: '',
            formOptions: {} as FormOptions,
            dialog: true,
            manifestFile: "" 

        }
    },
    methods: {
        async getFormOptions() {
            const vm = this
            const response = await axiosWarehouseInstance
                .get('audit/form-options')
                .then(function (response) {
                    if (response.status == 200) {
                        vm.formOptions = response.data
                    }
                })
        },
        handleFileUpload(event: any) {
            this.manifestFile = event.target.files[0];
            console.log("this is the manifest file",this.manifestFile)
        },
        uploadFile() {
            if (!this.manifestFile) {
                return;
            }

            let formData = new FormData();
            console.log("this is the form data", formData)
            formData.append('file', this.manifestFile);
            console.log("this is the form data", formData)
            axios.post('audit/invoice/', formData)
                .then(response => {
                    // Handle the response from the API
                    console.log(response.data);
                })
                .catch(error => {
                    // Handle errors
                    console.error(error);
                });
        }

    },
    mounted(){
        this.getFormOptions()
    }

})

</script>