<template>
    <v-container>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
        <h2>Low Stock Inventory</h2>
        <v-data-table :headers="headers" :items="low_stock_inventory" :search="search">
            <template v-slot:[`item.date_created`]="{ value }">
                {{ formatDate(value.props.title.date_created) }}
            </template>
            <template v-slot:[`item.received`]="{ value }">

                {{ value.props.title.received }}
            </template>

        </v-data-table>
    </v-col>

   
    <LoadingOverlay v-if="loading" />
</v-container>
</template>
<script lang="ts">
interface LowStockItem {
    product__name: string;
    available: number;
    required: number;
}

interface LowStockInventory {
    low_stock_inventory: LowStockItem[];
}
import { defineComponent, PropType } from 'vue';
import Edit from "../components/Edit.vue"
import axiosWarehouseInstance from '../../../stores/warehouse_store';
export default defineComponent({
    components: { Edit, },
    props: {
        headers: {
            type: [] as any[],
            required: true,
        },
      

    },
    data() {
        return {
            selectedProductId: 0,
            editDialog: false,
            viewType: this.viewType,
            search: "",
            loading: false,
            low_stock_inventory: [] as LowStockItem[],
            headers: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'available', title: 'Available' },
            { key: 'required', title: 'Required' },
            { key: '', title: 'Actions' },
          

        ]

        };
    },
    methods: {
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        isValidDate(dateString: string) {
            return !isNaN(Date.parse(dateString));
        },
        openEditDialog(id: number, edit: boolean) {
            this.selectedProductId = id
            this.editDialog = true
        },
        updateDialogOption(newValue: boolean) {
            this.editDialog = newValue;
        },

        async getLowStockManagement() {
            let vm = this

            vm.loading = true
            const response = await axiosWarehouseInstance.get('low-stock').then(
                function (response) {
                    if (response.status = 200) {
                        vm.low_stock_inventory = response.data.low_stock_inventory
                        console.log(response.data.low_inventory_stock)
                    }

                }
            ).catch((error) => {

            })
            vm.loading = false
        }
    },
    mounted() {
        this.getLowStockManagement()
    }


})
</script>