
<script lang="ts">

import axios from "axios"

// will have to move interface into a file
interface Navigation {
  search: string,
  showDrawer: boolean,
  showSearch: boolean,
  open: string[],
 
  identifiers: string[]
  searchIdentifier: string
  //title, component, icon
}


import { defineComponent } from 'vue'


export default defineComponent({
  name: 'InnerNavigation',

  data: (): Navigation => ({
    showDrawer: true,
    showSearch: true,
    search: "",
    open: ["Users"],
    identifiers: [] as string[],
    searchIdentifier: ""

  }),
  methods: {
    searchAsset() {
      this.$router.push({ name: "AllAssets", params: { identifier: this.searchIdentifier } })
    }
  }
})
</script>
<template>
  <div>
        <div>Hello World ScheduleLanding</div>
    </div>
</template>
<style scoped>
.rounded-search-bar .v-input__control {
  border-radius: 200px;
  /* Set the desired border radius */
  width: 10px
}

.vertex-logo {
  background-color: #295a6e;
  color: white;
}

.list-items {
  color: #003F94;

}

.vertex-logo {
  color: white;
  text-decoration: none;
  cursor: auto;
}
</style>