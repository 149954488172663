<template>
    <v-card class="mx-auto pa-2" max-width="368">
        <v-card-item>
            <v-row no-gutters>
                <v-col class="d-flex justify-space-between">
                    <span class="text-h6">Shift Status</span>
                    <v-chip v-if="shiftStatus === null" color="grey">
                        Loading...
                    </v-chip>
                    <v-chip v-else-if="shiftStatus.status === 'Active'" color="green" text-color="white">
                        {{ shiftStatus.status }}
                    </v-chip>
                    <v-chip v-else-if="shiftStatus.status === 'Not On Shift'" color="red" text-color="white">
                        {{ shiftStatus.status }}
                    </v-chip>
                    <v-chip v-else-if="shiftStatus.status === 'On Break'" color="warning" text-color="white">
                        {{ shiftStatus.status }}
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-if="isLoading">
                        Loading...
                    </div>

                    <div v-else>
                        <div>
                            <v-table v-if="shiftStatus.breaksTaken">
                                <thead>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Duration (mins)</th>
                                </thead>
                                <tbody>
                                    <tr v-for="_break in shiftStatus.breaksTaken">
                                        <td>{{ _break.start }}</td>
                                        <td>{{ _break.end }}</td>
                                        <td>{{ _break.duration_seconds / 60 }}</td>
                                    </tr>
                                    </tbody>
                            </v-table>
                            
                            <p v-if="shiftStatus.status == 'On Break'" class="float-right text-h4">{{ elapsedTime }}</p>
                        </div>
                        <br>
                        <v-btn :color="buttonColor" @click="toggleBreak" :disabled="isToggleLoading" class="float-right">
                            {{ breakButtonText }}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-item>
    </v-card>
</template>
  
<script lang="ts">
import axios from 'axios'
import { useMainStore } from '../stores/main'

export default {
    data() {
        return {
            shiftStatus: {},
            isLoading: true,
            agentData: null as any,
            isToggleLoading: false,
            elapsedTime: ''
        } as any
    },

    computed: {
        breakButtonText() {
            return this.agentData.onBreak ? 'End Break' : 'Start Break'
        },
        buttonColor() {
            if (this.shiftStatus.status === 'Active') {
                return 'success'
            } else if (this.shiftStatus.status === 'On Break') {
                return 'warning'
            } else {
                return 'success'
            }
        },
    },

    methods: {
        toggleBreak() {
            const endpoint = this.agentData.onBreak ? 'shift/break/stop/' : 'shift/break/start/'
            this.isToggleLoading = true

            axios.get(endpoint).then(() => {
                this.getShiftStatus()
                this.agentData.onBreak = !this.agentData.onBreak
            })
            .catch(err => {
                alert(err.response.data.message)
            })
            .finally(() => {
                this.isToggleLoading = false
            })
        },

        getShiftStatus() {
            axios.get('shift/status/')
                .then(response => {
                    this.shiftStatus = response.data
                    this.agentData.onBreak = (this.shiftStatus.status === 'On Break')
                })
                .catch(err => {
                    
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        formattedTime() {
            let now: any = new Date();
            let breakStartTime: any = new Date(this.shiftStatus.onBreakSince * 1000);

            let diffInSeconds = Math.floor((now - breakStartTime) / 1000);
            let hours: any = Math.floor(diffInSeconds / 3600);
            let minutes: any = Math.floor((diffInSeconds - (hours * 3600)) / 60);
            let seconds: any = diffInSeconds - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }

            return hours + ':' + minutes + ':' + seconds;
        }
    },

    watch: {
        'shiftStatus.onBreakSince': {
            immediate: true,
            handler(newVal, oldVal) {
                if (this.shiftStatus.status === 'On Break') {
                    this.timer = setInterval(() => {
                        this.elapsedTime = this.formattedTime();
                    }, 1000);
                } else {
                    clearInterval(this.timer);
                }
            }
        }
    },

    setup() {
        const main = useMainStore()
        return {
            agentData: main.agentData
        }
    },

    mounted() {
        this.getShiftStatus()
    }
}
</script>
