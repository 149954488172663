<template>
    <v-col>
    <v-row no-gutters class="float-right ">
        <v-col cols="12" xs="4" sm="12" md="12" lg="12" xl="12" xxl="12">
            <v-btn @click="openAddDialog(addDialog)" flat class="float-right"><span id="add-info">Add {{ viewType }}<span
                        class="text-center" id="add-button"><a>+</a></span></span></v-btn>
        </v-col>
    </v-row>

    <Add :add-dialog="addDialog" :view-type="viewType" :updateDialogOption="updateDialogOption"
        :device-types="deviceTypes" :asset-class="assetClass" />
    </v-col>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Add from "../components/Add.vue"
import Edit from "../components/Edit.vue"
export default defineComponent({
    components: {
        Add,
        Edit
    },
    props: {
        search: {
            type: String as PropType<String>
        },
        addDialog: {
            type: Boolean as PropType<boolean>,
            required: true
        },
        viewType: {
            type: String as PropType<string>,
            required: true
        },
        deviceTypes: {
            type: Array,
            default: []
        },
        assetClass: {
            type: Array,
            default: []
        }
    },
    data: () => ({
        searchString: "",
        //openDialog:false,
        addDialog: false,
    }),
    methods: {
        openAddDialog(openDialog: boolean) {
            this.addDialog = true

        },
        closeDialog() {
            this.addDialog = false;
        },
        updateDialogOption(newValue: boolean) {
            this.addDialog = newValue;
        },
    }
})

</script>
<style scoped>
#add-button {
    color: white;
    border: 0.1rem solid #9E305B;
    border-radius: 3px;
    background: #9E305B;
    width:10px;
    height:2px;
    padding:5px
}
#add-info{
    font-size:20px;
    text-transform:capitalize
    
}
.btn {
  text-transform: unset !important;
}
</style>