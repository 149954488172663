<template>
    <v-col cols="12" class="mx-auto text-center">
        <v-dialog v-model="addDialog" content-class="center-dialog">
            <v-card density="compact" outlined min-width="600" justify="center">
                <v-card-title>
                    <v-col class="text-center">
                        <span class="text-h5">{{ viewType }}</span>
                    </v-col>
                </v-card-title>
                <v-card-text v-if="viewType == 'Products'">
                    <v-text-field variant="outlined" label="Name" v-model="name">
                    </v-text-field>
                    <v-select variant="outlined" label="Type of Asset" v-model="assetGroup" :items="assetClass">
                    </v-select>
                    <v-text-field variant="outlined" label="Model" v-model="model">
                    </v-text-field>
                    
                    <v-text-field variant="outlined" label="Model Name" v-model="modelName">
                    </v-text-field>
                    <v-select variant="outlined" v-model="deviceType" :items="deviceTypes" label="Device Type">
                    </v-select>

                    <v-checkbox
                        label="Activate Tracking - Product needs to be tracked by an identifier (eg. mac address or serial number)"
                        type="checkbox" class="text-tmwGreen" v-model="identifierTracking">
                    </v-checkbox>
                    <v-checkbox label="Product is detectable on network" type="checkbox" class="text-tmwGreen"
                        v-model="activeTracking">
                    </v-checkbox>
                </v-card-text>
                <v-card-text v-if="viewType == 'Suppliers'" min-width="200">
                    <v-text-field variant="outlined" label="Name" v-model="name">
                    </v-text-field>
                    <v-text-field variant="outlined" label="Contact" v-model="contact">
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-col>
                        <v-btn class="bg-tmwRed" @click="closeDialog()">Cancel</v-btn>
                    </v-col>
                    <v-divider></v-divider>
                    <v-col>
                        <v-btn v-if="viewType == 'Products'" class="bg-navigationDrawer"
                            @click="addSubmitFormProduct()">Save</v-btn>
                        <v-btn v-else="viewType=='Suppliers'" class="bg-navigationDrawer"
                            @click="addSubmitFormSupplier()">Save</v-btn>
                    </v-col>



                </v-card-actions>
            </v-card>
        </v-dialog>
        <LoadingOverlay v-if="loading" />
    </v-col>
    <v-row no-gutters class="position-relative">
        <div class="text-center">
            <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="3000"
                transition="dialog-bottom-transition">
                <span>{{ snackbarResponse }}</span>
                <template v-slot:actions>
                    <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
        </div>
    </v-row>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import axios from 'axios'


export default defineComponent({
    props: {
        addDialog: {
            type: Boolean as PropType<boolean>,
            required: true
        },

        candidateName: {
            type: String as PropType<string>,
        },
        candidateModel: {
            type: String as PropType<string>,
        },
        candidateModelName: {
            type: String as PropType<string>,
        },
        candidateDeviceType: {
            type: String as PropType<string>,
        },
        candidateContact: {
            type: String as PropType<string>,
        },
        trackByIdentifier: {
            type: Boolean as PropType<boolean>,
        },
        isTrackingActive: {
            type: Boolean as PropType<boolean>,
        },
        viewType: {
            type: String as PropType<string>,
            required: true
        },
        deviceTypes: {
            type: Array
        },
        updateDialogOption: {
            type: Function,
            required: true
        },
        endpoint: {
            type: String as PropType<string>
        },
        assetClass: {
            type: Array
        },

    },
    data() {
        return {
            addDialog: this.addDialog,
            name: this.candidateName,
            model: this.candidateModel,
            modelName: this.candidateModelName,
            deviceType: this.candidateDeviceType,
            contact: this.candidateContact,
            identifierTracking: this.trackByIdentifier,
            activeTracking: this.isTrackingActive,
            deviceTypes: this.deviceTypes,
            endpoint: "",
            loading: false,
            snackbarResponse: "",
            snackbarType: "error",
            openSnackBar: false,
            assetGroup:""

        };
    },
    methods: {
        async addSubmitFormSupplier() {
            const newSupplier = {
                name: this.name,
                contact_number: this.contact,
            };

            let vm = this
            vm.loading = true
            let response = await axios
                .post("add_supplier", newSupplier)
                .then(function (response) {
                    if (response.status === 201) {
                        // vm.supplierList.push(response.data.supplier);
                        vm.name = '';
                        vm.contact = '';
                        vm.snackbarResponse = "Successfully Added Supplier"
                        vm.snackbarType ="success"
                    }

                })
                .catch((error: any) => {
                    if (error) {
                        vm.snackbarResponse = error.response.data.message
                    }
                })
            this.closeDialog() 
            this.loading = false
            this.openSnackBar = true



        },
        formartDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        async addSubmitFormProduct() {
            let vm = this
            vm.loading = true
            let response = await axios
                .post("add-product", {
                    name: this.name,
                    model: this.model,
                    model_name: this.modelName,
                    device_type: this.deviceType,
                    track_by_identifier: this.identifierTracking,
                    tracking_active: this.activeTracking,
                    asset_class:this.assetGroup

                }).then(function(response){
                    vm.snackbarResponse = "Successfully Added Product"
                    vm.snackbarType ="success"
                })
                .catch((error: any) => {
                    vm.snackbarResponse = error.response.data.message
                })
            vm.closeDialog()
            vm.loading = false
            vm.openSnackBar = true


        },
        closeDialog() {
            this.toggleDialogOption()
        },
        toggleDialogOption() {
            this.updateDialogOption(!this.addDialog);
        },
        showSnackbar(): void {
            setTimeout(() => {
                this.openSnackBar = true;
            }, 2000);
        },
    }
})
</script>
<style>
.center-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>