<template>
    <v-container>
        <h2>All Assets</h2>
        <v-row no-gutters>
            <v-col cols="12" xs="4" sm="12" md="12" lg="12" xl="12" xxl="12">
                <v-btn :to="{ name: 'LoadAssets' }" flat class="float-right"><span id="add-info">Load Assets<span
                            class="text-center" id="add-button">+</span></span></v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
            <v-col>
                <div class="float-right">
                    <input v-model="search" type="search" id="default-search"
                        class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search Asset" required>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">

                <v-data-table :headers="headers" :items="assets" :search="search"></v-data-table>

            </v-col>
        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '@/stores/warehouse_store';
interface Asset {
    serial: string
    mac: string
    status: string
    type: string,
    node__name: string,
    product__name: string
}

export default defineComponent({

    data: () => {
        return {
            assets: [] as Asset[],
            search: "",
            loading: true,
            headers: [
                {
                    align: 'start',
                    key: 'mac',
                    sortable: false,
                    title: 'Mac Address',
                },
                { key: 'serial', title: 'Serial No' },
                { key: 'status', title: 'Status' },
                { key: 'assigned_to__username', title: 'Assigned To' },
                { key: 'node__name', title: 'Node Name' },
                { key: 'product__name', title: 'Product Name' },
                


            ] as any[]

        }
    },
    methods: {
        async getAllIdentifiers() {
            let vm = this
            try {
                const response = await
                    axiosWarehouseInstance.get("all-assets")
                this.assets = response.data.all_assets


                if (typeof (vm.$route.params.identifier) === 'string') {
                    this.search = vm.$route.params.identifier
                }
            }
            catch (error) {

            }
            finally {
                vm.loading = false
            }

        }
    },
    mounted() {
        this.getAllIdentifiers()
    }
})
</script>
<style>
#add-button {
    color: white;
    border: 0.1rem solid #9E305B;
    border-radius: 3px;
    background: #9E305B;
    width: 10px;
    height: 2px;
    padding: 5px
}

#add-info {
    font-size: 20px;
    text-transform: capitalize
}

.btn {
    text-transform: unset !important;
}
</style>