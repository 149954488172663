<template>
    <v-container>
        <h2 class="mb-10">Load Assets</h2>
        <v-row class="" no-gutters>
        
            <v-col v-if="!scanning" cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" class=" justify-content-start">
                <v-form ref="form" lazy-validation valid="valid">
                    <v-select v-model="product" :items="products" :search-input="true" label="Select Product"
                        item-title="name" required></v-select>
                    <v-select v-model="deviceType" :items="deviceTypes" :search-input="true" label="Select Device Type"
                        required></v-select>
                    <v-text-field v-if="(nonIdentifiableProducts.includes(product))" variant="outlined" v-model="count">

                    </v-text-field>
                    <v-select v-model="invoice" :items="invoices" :search-input="true" label="Select Invoice(optional)"
                        item-title="name" required></v-select>
                    <v-row no-gutters v-if="scannedIdentifiers.length == 0 && !(nonIdentifiableProducts.includes(product))"
                        class="">
                        <v-col>
                            <h3>Select Scanner</h3>
                            <v-checkbox label="QR Code" type="checkbox" class="" v-model="qrScan"></v-checkbox>
                            <v-checkbox label="Bar Code" type="checkbox" class=" mt-0 mb-0" v-model="barScan"></v-checkbox>
                        </v-col>

                        <v-col>
                            <h3>Select Identifier</h3>
                            <v-checkbox label="Mac Address" type="checkbox" class="" v-model="mac"></v-checkbox>
                            <v-checkbox label="Serial Number" type="checkbox" class="" v-model="serial"></v-checkbox>
                        </v-col>

                    </v-row>
                    <div v-if="(nonIdentifiableProducts.includes(product))" class="float-right">
                        <v-btn @click="loadAssets" id="save-assets">Load</v-btn>
                    </div>
                </v-form>

            </v-col>
            <v-col cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" class=" justify-content-start">
                <div v-if="serialField">
                    <v-text-field v-model="serialNumber" @change="checkBarScannerValue()"></v-text-field>
                </div>
                <div v-if="barDiv">
                    <video ref="video" id="video"></video>
                </div>
                <div v-if="scanning" class="">
                    <v-btn @click="loadAssets" id="save-assets">Load</v-btn>
                </div>
            </v-col>

            <v-col v-if="scannedIdentifiers.length > 0" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6"
                class="mx-auto">

                <v-data-table :headers="[{
                    align: 'start',
                    key: 'identifier',
                    sortable: false,
                    title: 'Identifier',
                }]" :items="scannedIdentifiers">

                </v-data-table>
                <p class="text-end">Items Scanned:{{ scannedIdentifiers.length }}</p>

            </v-col>
        </v-row>
        <v-row v-if='!scanning && !nonIdentifiableProducts.includes(product)' no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="justify-center">
                <div @click="initiateScanning()" class="rounded-box text-center">
                    <div class="scan-text  mt-4">
                        Click
                    </div>
                    <div class="scan-text">
                        to
                    </div>
                    <div class="scan-text">
                        Scan
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters class="position-relative">
            <div class="text-center">
                <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="3000"
                    transition="dialog-bottom-transition">
                    <span>{{ snackbarResponse }}</span>
                    <template v-slot:actions>
                        <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>
<!-- <v-btn class="float-right bg-tmwGreen" @click="loadAssets()">
    Load Assets
</v-btn> -->
<!-- 
            <div class="text-center">
                <v-snackbar multi-line v-model="openSnackBar" timeout="1000" :color="snackbarType"
                    transition="dialog-bottom-transition">
                    <span class="text-center font-weight-bold ">{{ snackbarResponse }}</span>
                    <template v-slot:actions>
                        <v-btn class="text-black" variant="text" @click="openSnackBar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div> -->
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axiosWarehouseInstance from '@/stores/warehouse_store';
import { MultiFormatReader, BarcodeFormat, QRCodeReader } from '@zxing/library';
import { BrowserMultiFormatReader } from '@zxing/library';
import { Console } from 'console';
import useSound from 'vue-use-sound';
import scannerSound from "/scanner.mp3";

const hints = new Map();
interface Devices {
    devices: string[]
} 
export default defineComponent({
 
    data: () => ({
        product: null as any,
        deviceType: null,
        deviceTypes: [],
        serial: false,
        mac: false,
        activeTracking: null,
        qrScan: false,
        barScan: false,
        scannedIdentifiers: [] as any[],
        serialField: false,
        serialNumber: "",
        candidateIdentifiers: [] as string[],
        snackbarResponse: "",
        snackbarType: "error",
        openSnackBar: false,
        existingIdentifiers: [] as string[],
        qrResult: "",
        scanning: false,
        loading: true,
        identifier_type: "",
        barDiv: false,
        products: [] as any[],
        nonIdentifiableProducts: [] as string[],
        count: 0,
        invoice: null,
        invoices: [] as string[]



    }),
    setup() {
        //scanner soun

        const scanner = new Audio(scannerSound)
        //console.log("this is the scanner sound", scannerSound)
        const reader = ref(new BrowserMultiFormatReader());
        const devices: MediaDeviceInfo[] = [];
        let selectedDevice: MediaDeviceInfo | null = null;
        let videoStream: MediaStream | null = null;
        let video = ref<HTMLVideoElement | null>(null);
        const qrResult = ref("");

        const initReader = async () => {
            ; reader.value = new BrowserMultiFormatReader();
        };

        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');

            if (videoDevices.length > 0) {
                selectedDevice = videoDevices[0]; // Select the first video input device, you can use a different strategy to select a device.
                startScanning();
            } else {
                // Handle the case when no video input devices are available.
                console.error('No video input devices found.');
            }
        };


        const startScanning = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: selectedDevice!.deviceId
                }
            });
            videoStream = stream;
            if (video.value !== null)
                video.value.srcObject = stream;
            reader.value.decodeFromVideoDevice(
                selectedDevice!.deviceId,
                'video',
                result => {
                    if (result) {
                        qrResult.value = result.getText();
                        console.log("this is what we got", result)
                        scanner.play()
                        console.log("should be playing sound")
                        //stopScanning()
                        return qrResult.value;

                    }
                    else {
                        return 'No bar code or QR code found in the video stream'
                    }
                }
            )

        };
        /*  const stopScanning = () => {
             if (videoStream !== null) {
                 const tracks = videoStream.getVideoTracks();
                 tracks.forEach(track => {
                     track.stop();
                 });
                 videoStream = null
                 video.value = null
                 console.log("this is the video stream", videoStream)
                 console.log("this is the video", video.value)
             }
         }; */
        return {
            reader,
            devices,
            selectedDevice,
            videoStream,
            video,
            qrResult,
            initReader,
            getDevices,
            startScanning,

        }
    },
    methods: {
        initiateScanning() {
            if (this.qrScan == false && this.barScan == false) {
                this.snackbarResponse = `Select Scan Method`
                this.showSnackbar()
            }
            if (this.mac == false && this.serial == false) {
                this.snackbarResponse = `Select Type of Identifier`
                this.showSnackbar()
            }
            if (this.barScan == true && (this.serial == true || this.mac == true)) {
                this.scanning = true
                this.startBarScanner()

            }
            if (this.qrScan == true && (this.serial == true || this.mac == true)) {
                this.scanning = true
                this.startQRReader()

            }

        },
        startQRReader() {
            this.initReader()
            this.getDevices()
            this.barDiv = true
        },
        startBarScanner() {
            this.serialField = true
        },
        checkBarScannerValue() {
            if (this.existingIdentifiers.includes(this.serialNumber)) {
                this.snackbarResponse = `${this.serialNumber} already loaded in warehouse`
                this.serialNumber = ""
                this.showSnackbar()
            }
            if (this.candidateIdentifiers.includes(this.serialNumber)) {
                this.snackbarResponse = `${this.serialNumber} already scanned`
                this.serialNumber = ""
                this.showSnackbar()
            }

            else {
                this.scannedIdentifiers.push({
                    "identifier": this.serialNumber
                })
                this.candidateIdentifiers.push(this.serialNumber)
                this.serialNumber = ""
            }

        },
        checkQRReaderValues(identifiers: string[]) {
            for (let identifier of identifiers) {
                if (this.existingIdentifiers.includes(identifier)) {
                    this.snackbarResponse = `${this.serialNumber} already loaded in warehouse`
                    this.showSnackbar()
                }
                if (this.candidateIdentifiers.includes(identifier)) {
                    this.snackbarResponse = `${this.serialNumber} already scanned`
                    this.serialNumber = ""
                    this.showSnackbar()
                }
                else {
                    this.scannedIdentifiers.push({
                        "identifier": identifier
                    })
                    this.candidateIdentifiers.push(identifier)
                }
            }
        },

        showSnackbar(): void {
            setTimeout(() => {
                this.openSnackBar = true;
            }, 2000);
        },
        setNonIdentifiableProducts(products: any[]) {
            for (let product of products) {
                if (product.track_by_identifier == false) {
                    this.nonIdentifiableProducts.push(product.name)
                }
            }
        },
        showConsole() {
            console.log("hello")
        },

        async getIdentifiers() {
            let vm = this
            try {
                const response = await axiosWarehouseInstance.
                    get("all-identifiers")
                this.existingIdentifiers = response.data.identifiers
            }
            catch (error) {

            }
            vm.loading = false
        },
        async getProductNames() {
            let vm = this
            try {
                const response = await axiosWarehouseInstance.
                    get("product-names")
                this.products = response.data.products
                this.deviceTypes = response.data.device_types
                this.invoices = response.data.invoices
                vm.setNonIdentifiableProducts(this.products)

            }
            catch (error) {

            }
            vm.loading = false
        },

        async loadAssets() {
            let vm = this
            vm.loading = true
            if (vm.mac) {
                vm.identifier_type = "mac"
            }
            else {
                vm.identifier_type = "serial"
            }
            try {
                const response = await axiosWarehouseInstance.
                    post("load-assets/",
                        {
                            product_name: this.product,
                            identifier_type: this.identifier_type,
                            identifier_list: this.candidateIdentifiers,
                            device_type: this.deviceType,
                            count: this.count,
                            invoice:this.invoice

                        })
                vm.snackbarResponse = response.data.message
                vm.snackbarType = "success"
                vm.showSnackbar()

            }
            catch (error) {
                vm.snackbarResponse = "Something went wrong in loading the assets. Try Again Later"
                vm.showSnackbar()
                console.log("this is the error", error)
            }

            vm.loading = false
            vm.candidateIdentifiers = []
            vm.scannedIdentifiers = []
        }
    },
    watch: {
        qrResult: function (qrResult) {
            if (qrResult.split("\n").length > 1) {
                this.checkQRReaderValues(this.qrResult.split("\n"))
            }
            else {

            }
        }
    },
    mounted() {
        this.getIdentifiers()
        this.getProductNames()
    }


})


</script>
<style scoped>
.rounded-box {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    left: calc(50% - 100px/2);
    top: calc(50% - 100px/2);
    border: 1px solid #932D55;
    border-radius: 8px;
}

#scan-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #000000;
}

#save-assets {
    color: white;
    border: 1px solid #003F94;
    border-radius: 5px solid #003F94;
    background: #003F94;
}

#video {
    width: 400px;

}
</style>