import WarehouseDashboard from "./views/WarehouseDashboard.vue";
import Landing from "./views/Landing.vue";
import Home from '../warehouse/views/Home.vue'
import InventoryList from '../warehouse/views/InventoryList.vue'
import Products from '../warehouse/views/Products.vue'
import Suppliers from '../warehouse/views/Suppliers.vue'
import LoadAssets from '../warehouse/views/LoadAssets.vue'
import StaffEquipmentList from '../warehouse/views/StaffEquipmentList.vue'
import HighsiteList from '../warehouse/views/HighsiteList.vue'
import OtherAssets from '../warehouse/views/OtherAssets.vue'
import Returns from '../warehouse/views/Returns.vue'
import LogSupplierReturn from '../warehouse/views/LogSupplierReturn.vue'
import UnacceptedAssets from '../warehouse/views/UnacceptedAssets.vue'
import OrderInvoices from '../warehouse/views/OrderInvoices.vue'
import AddOrderInvoice from '../warehouse/views/AddOrderInvoice.vue'
import OrderInvoiceView from '../warehouse/views/OrderInvoiceView.vue'
import AllAssets from '../warehouse/views/AllAssets.vue'
import MyAssets from '../warehouse/views/MyAssets.vue'
import Dashboard from '../warehouse/views/Dashboard.vue'
import AuditInvoice from '../warehouse/views/AuditInvoice.vue'
import IBT from '../warehouse/views/IBT.vue'
import ManageIBT from '../warehouse/views/ManageIBT.vue'
import DepoSelection from '../warehouse/views/DepoSelection.vue'
import StockManagement from '../warehouse/views/StockManagement.vue'
import StockTake from '../warehouse/views/StockTake.vue'


let routes = [
    {
        path: "",
        component: Landing,
        name: "Warehouse",
    },
    {
      path: 'home',
      name:'Home',
      component: Home,
    
    },
    {
        path: 'inventory/list',
        name:'InventoryList',
        component:InventoryList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'products',
        name: 'Products',
        component: Products
      },
      {
        path: 'suppliers',
        name: 'Suppliers',
        component: Suppliers
      },
      {
        path: 'order-invoice',
        name: 'OrderInvoices',
        component: OrderInvoices,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'add-order_invoice',
        name: 'AddOrderInvoice',
        component: AddOrderInvoice,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'order_invoice/view/:id',
        name: 'OrderInvoiceView',
        component: OrderInvoiceView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'load-assets',
        name: 'LoadAssets',
        component: LoadAssets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'staff-equipment/list',
        name: 'StaffEquipmentList',
        component: StaffEquipmentList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'highsite/list',
        name: 'HighsiteList',
        component: HighsiteList,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'other-assets/list',
        name: 'OtherAssets',
        component: OtherAssets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'returns/list',
        name: 'Returns',
        component: Returns,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'supplier-returns/log',
        name: 'LogSupplierReturn',
        component: LogSupplierReturn,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'unaccepted-assets',
        name: 'UnacceptedAssets',
        component: UnacceptedAssets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'all-assets/:identifier?',
        name: 'AllAssets',
        component: AllAssets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'my-assets',
        name: 'MyAssets',
        component: MyAssets,
        meta: {
          requiresAuth: true,
        },
      },
        {
          path: 'warehouse-dashboard',
          name: 'WarehouseDashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        }, {
          path: 'warehouse-dashboard/audit-invoice',
          name: 'AuditInvoice',
          component: AuditInvoice,
          meta: {
            requiresAuth: true,
          },
        }
        , {
          path: 'warehouse-dashboard/transfers',
          name: 'IBT',
          component:IBT,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'warehouse-dashboard/transfers/manage/:id',
          name: 'ManageIBT',
          component:ManageIBT,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'warehouse-dashboard/stock-management',
          name: 'StockManagement',
          component:StockManagement,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'warehouse-dashboard/stock-take',
          name: 'StockTake',
          component:StockTake,
          meta: {
            requiresAuth: true,
          },
        },
   
];

export default routes;
