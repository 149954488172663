<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '../../../stores/warehouse_store';
import ListsToolbar from "../components/ListsToolbar.vue"
import Lists from "../components/Lists.vue"

interface OrderInvoice {
    id: number,
    invoice_number: string,
    date_created: string,
    user_ordered__first_name: string,
    user_ordered__last_name: string,
    supplier__name: string,
    total_inc_vat: number,
    approved: boolean,
    paid: boolean,
    received: boolean,
    user_received__first_name: string,
    user_received__last_name: string,
    date_received: string
    date_approved: string,
    user_approved__first_name: string,
    user_approved__last_name: string,
    date_paid: string
    user_paid__first_name: string,
    user_paid__last_name: string
}
export default defineComponent({
    components: {
        ListsToolbar,
        Lists,
        
    },
    data: () => ({
        orderInvoiceList: [] as OrderInvoice[],
        invoiceNumber: "",
        dateCreated: "",
        userOrder: "",
        supplier: "",
        totalIncVat: "",
        approved: "",
        paid: false,
        delivered: false,
        search: "",
        loading: true,
        headers: [
            {
                align: 'start',
                key: 'invoice_number',
                sortable: false,
                title: 'Invoice ID',
            },
            { key: 'date_created', title: 'Date Created' },
            { key: 'supplier__name', title: 'Supplier' },
            { key: 'total_inc_vat', title: 'Total Cost' },
            { key: 'received', title: 'Delivered' },
            { key: 'actions', title: 'Actions' },

        ] as any[],
        selectedProductId: 0,
        editDialog: false,
        viewType:"Order Invoices"
    }),

    methods: {
        formattedPrice(amount: number) {
            return amount.toLocaleString('en-US', { style: 'currency', currency: 'ZA' })
        },
        async getOrderInvoice() {
            let vm = this
            let response = await axiosWarehouseInstance
                .get("order-invoice/")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.orderInvoiceList = response.data.order_invoices

                    }
                })
                .catch((error: string) => {
                    if (error) {

                    }
                })
            vm.loading = false

        },
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        redirectToAddOrderInvoice() {
            this.$router.push({ name: "AddOrderInvoice" })
        },
        redirectToOrderInvoiceView(id: number) {

            this.$router.push({ name: "OrderInvoiceView", params: { id: id } })
        },
        openEditDialog(id: number, edit: boolean) {
            console.log("trying to edit")
            this.selectedProductId = id
            this.editDialog = true
        },
        updateDialogOption(newValue: boolean) {
            this.editDialog = newValue;
        }

    },
    created() {
        this.getOrderInvoice()
    }
})

</script>
<template>
    <v-container class="">
        <h2>Orders/Invoices</h2>
        <v-row no-gutters class="float-right ">
            <v-col cols="12" xs="4" sm="12" md="12" lg="12" xl="12" xxl="12">
                <v-btn flat :to="{ name: 'AddOrderInvoice' }"><span id="add-info">Add Invoice<span class="text-center"
                            id="add-button"><a>+</a></span></span></v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-12">
            <v-col>
                <div class="float-right">
                    <input v-model="search" type="search" id="default-search"
                        class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search Invoice" required>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters align-content="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto text-center">
                <v-data-table :headers="headers" :items="orderInvoiceList" :search="search">
                    <template v-slot:[`item.total_inc_vat`]="{ value }">

                        R{{ formattedPrice(value["props"]["title"].total_inc_vat) }}
                    </template>
                    <template v-slot:[`item.date_created`]="{ value }">
                        {{ formatDate(value.props.title.date_created) }}
                    </template>
                    <template v-slot:[`item.received`]="{ value }">

                        {{ value.props.title.received }}
                    </template>
                    <template v-slot:[`item.actions`] = "{ value }">
                        <v-btn color="navigationDrawer" small @click="redirectToOrderInvoiceView(value.props.title.id)">Edit</v-btn>
                    </template>
                </v-data-table>

            </v-col>
        </v-row>
        <v-row>
        </v-row>
        <v-dialog v-model="editDialog">
        <Edit :edit-dialog="editDialog" :view-type="viewType" :updateDialogOption="updateDialogOption"
            :selected-product-id="selectedProductId" />
        </v-dialog>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>
<style scoped>
tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

#add-button {
    color: white;
    border: 0.1rem solid #9E305B;
    border-radius: 3px;
    background: #9E305B;
    width: 10px;
    height: 2px;
    padding: 5px
}

#add-info {
    font-size: 20px;
    text-transform: capitalize
}

.btn {
    text-transform: unset !important;
}
</style>