<template>
    <v-container fluid>
        <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
        <div v-else>
            <v-row>
                <v-col md="12">
                    <v-data-table :headers="answeredHeaders" :items="inboundCalls" hide-default-footer class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Inbound Calls</v-toolbar-title>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.cid_number`]="{ item }">
                            {{ zeroPadded(item.cid_number) }}
                        </template>
                        <template v-slot:[`item.timeOnCall`]="{ item }">
                            {{ timeOnCall(item) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn class="mx-2" fab dark small color="green" @click="snoop(item.extension)">
                                <v-icon dark>mdi-headphones</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:bottom></template>
                    </v-data-table>
                </v-col>

                <v-col md="12">
                    <v-data-table :headers="outboundHeaders" :items="outboundCalls" hide-default-footer class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Outbound Calls</v-toolbar-title>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.timeOnCallOutbound`]="{ item }">
                            {{ timeOnCallOutbound(item) }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn class="mx-2" fab dark small color="green" @click="snoop(item.cid_num)">
                                <v-icon dark>mdi-headphones</v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:bottom></template>
                    </v-data-table>
                </v-col>

            </v-row>
        </div>
    </v-container>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';
import image from '@/assets/img/user.jpg'

interface QueueMember {
    status: string;
    cid_number: string;
    serving_agent: string;
    agent_name: string;
    joined_epoch: number;
    answer_epoch: number;
    extension: string;
}

interface Call {
    uuid: string;
    direction: string;
    cid_name: string;
    cid_num: string;
    agent_name: string;
    created_epoch: number;
    dest: string;
    callstate: string;
}

interface AvailableAgent {
    name: string;
    status: string;
    img: string;
}

interface CallCenterStatus {
    queue_members: QueueMember[];
    available_agents: AvailableAgent[];
    inbound_calls: Call[];
    outbound_calls: Call[];
}

interface ManagerDashboard {
    callCenterStatus: CallCenterStatus;
    isLoading: boolean;
    answeredHeaders: any;
    tryingHeaders: any;
    outboundHeaders: any;
    queueHeaders: any;
    timeout: any;
    currentTime: any;
    imageUrl: any;

}


export default defineComponent({
    data: (): ManagerDashboard => ({
        callCenterStatus: { queue_members: [], available_agents: [], inbound_calls: [], outbound_calls: []},
        isLoading: true,
        answeredHeaders: [
            { title: 'Status', key: 'status' },
            { title: 'Number', key: 'cid_number' },
            { title: 'Agent', key: 'agent_name' },
            { title: 'Duration', key: 'timeOnCall' },
            { title: "", key: "actions", sortable: false }
        ],
        tryingHeaders: [],
        queueHeaders: [],
        outboundHeaders: [
            { title: 'Status', key: 'callstate' },
            { title: 'Number', key: 'dest' },
            { title: 'Agent', key: 'agent_name' },
            { title: 'Duration', key: 'timeOnCallOutbound' },
            { title: "", key: "actions", sortable: false }
        ],
        timeout: null,
        currentTime: null,
        imageUrl: image
    }),
    methods: {
        getCallCenterStatus() {
            axios.get('/call/center/status/')
                .then(response => {
                    this.callCenterStatus = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        formatDateTime(epoch: number) {
            if (epoch) {
                const date = new Date(epoch * 1000);
                return `${date.toLocaleTimeString('en-US')}`;
            } else {
                return 'N/A';
            }
        },
        timeWaited(item: QueueMember) {
            if (item.answer_epoch && item.joined_epoch) {
                return item.answer_epoch - item.joined_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeOnCall(item: QueueMember) {
            if (item.answer_epoch) {
                return Math.floor(this.currentTime / 1000) - item.answer_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeOnCallOutbound(item: Call) {
            if (item.created_epoch) {
                return Math.floor(this.currentTime / 1000) - item.created_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeWaiting(item: QueueMember) {
            if (item.joined_epoch) {
                return Math.floor(this.currentTime / 1000) - item.joined_epoch;
            }
            else {
                return 'N/A';
            }
        },
        zeroPadded(num: string) {
            console.log(num)
            if (num.startsWith("0")){
                return num
            }
            return "0" + num.slice(2);
        },
        snoop(item: any) {
            this.$emit('dial', "*33"+ item);
        }
    },
    computed: {
        inboundCalls() {
            console.log(this.callCenterStatus.queue_members.filter(member => member.status === "Answered"))
            return this.callCenterStatus.queue_members.filter(member => member.status === "Answered");
        },
        outboundCalls(){
            return this.callCenterStatus.outbound_calls.filter(call => call.callstate === "ACTIVE");
        }
    },
    mounted() {
        this.getCallCenterStatus();
        if (!this.timeout) {
            this.timeout = setInterval(this.getCallCenterStatus, 5000);
        }

        setInterval(() => {
            this.currentTime = Date.now();
        }, 1000);
    },
    unmounted() {
        console.log("Unmounted")
        clearInterval(this.timeout);
    }
})



</script>
