<template>
    <v-container>
        <v-row no-gutters class="mb-8">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
                <v-card class="text-center" max-width="344" elevation="10" @click = "ppe=false,getInventory('main')">
                    <v-card-title>
                        Main Installation Inventory
                    </v-card-title>
                    <v-card-subtitle>
                        Normally items that every installation will require, e.g Litebeams and Mikrotik
                    </v-card-subtitle>

                </v-card>

            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
                <v-card class="text-center" max-width="344" elevation="10" @click="ppe=false,getInventory('sundry')">
                   
                    <v-card-title>
                        Sundry Installation Inventory
                    </v-card-title>
                    <v-card-subtitle>
                        Mostly re-usable items and not common for all installations
                    </v-card-subtitle>

                </v-card>

            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
                <v-card class="text-center" max-width="344" elevation="10" @click="ppe=true,getInventory('ppe')">
                   
                    <v-card-title>
                        Tech PPE
                    </v-card-title>
                    <v-card-subtitle>
                        Items that techs use repeatedly
                    </v-card-subtitle>

                </v-card>

            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                <asset-lists :headers=" ppe == true ? ppeHeaders : headers" :list-name="''" :list-items="inventoryList" action="assign" assignee="user" />
            </v-col>
        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '@/stores/warehouse_store';
import Vue from 'vue'
import AssetLists from '../components/AssetLists.vue'

interface Inventory {
    id: string
    product__name: string
    supplier__name: string
    manufacturer: string
    purchase_price: number
    stock: number
    lost: number
    deployed: number
    assigned: number
    dispatched:number
    not_in_warehouse:number,
    weeks_remaining:number
};

export default defineComponent({
    name: 'InventoryList',
    components: {
        AssetLists
    },
    data: () => ({
        inventoryList: [] as Inventory[],
        loading: true,
    
        headers: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'stock', title: 'Stock' },
            { key: 'lost', title: 'Lost' },
            { key: 'deployed', title: 'Deployed' },
            {key:'dispatched', title:'Dispatched'},
            { key: 'assigned', title: 'Assigned' },
            { key: 'weeks_remaining', title: 'Weeks Remaining' },
          
        ],
        ppeHeaders: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'stock', title: 'Stock' },
            { key: 'lost', title: 'Lost' },
            { key: 'assigned', title: 'Assigned' },
          
        ],
        mainItems:true,
        sundryItems:false,
        ppe:false
    }),

    methods: {
        async getInventory(type:string): Promise<void> {
            let vm = this
            vm.loading = true
            try {
                const response = await
                    axiosWarehouseInstance.post(`list-inventory/?type=${type}`);
                vm.inventoryList = response.data.inventory
            }

            catch (error) {
                console.error(error);
                //throw error
            }

            finally {
                this.loading = false
            }

        },
    },
    created() {
        let type = "main"
        this.getInventory(type)
    }

})

</script>

<style></style>