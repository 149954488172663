<script lang="ts">
import { defineComponent } from 'vue'
import axiosWarehouseInstance from '@/stores/warehouse_store';

interface Assignment {
    user_id: number;
    asset_name: string;
    date_assigned: string;
    assigned_by: string;
    mac: string;
    serial: string;
    [key: string]: any

}
interface ProductCount {
    product: string;
    count: number;
}

export default defineComponent({
 
    data() {
        return {
            myAssets: [] as Assignment[],
            headers: [
                { title: 'Product Name', align: 'center', key: 'product' },
                { title: 'Count', align: 'center', key: 'count' },



            ] as any,
            search: "",
            groupedProducts: [] as any[],
            selectedProduct: '',
            productDetails: [] as string[],
            dialog: false,
            selectedChips: [] as string[],
            snackbarResponse: "",
            snackbarType: "error",
            openSnackbar:false,
            loading: true


        }
    },
    methods: {
        async getAssignedAssets() {
            const vm = this
            vm.loading = true
            const response = await axiosWarehouseInstance.
                get("my-assets").
                then(
                    function (response) {
                        if (response.status == 200) {
                            vm.myAssets = response.data.assets
                            vm.groupedProducts = vm.countProducts(vm.myAssets)
                        }
                    }
                )
            vm.loading = false

        },
        countProducts(data: Assignment[]): ProductCount[] {
            const counts: ProductCount[] = [];


            for (let i = 0; i < data.length; i++) {
                const product = data[i].product;
                const existingProduct = counts.find((item) => item.product === product);

                if (existingProduct) {
                    existingProduct.count += 1;
                } else {
                    counts.push({ product, count: 1 });
                }
            }

            return counts;
        },

        show(event: any, value: any) {
            const clickedItem = value.item;
            this.selectedProduct = clickedItem.value.product
            console.log(this.myAssets)
            this.productDetails = this.getDetailsByProductName(this.myAssets, this.selectedProduct)
            this.dialog = true
            console.log(this.productDetails)

        },
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },

        getDetailsByProductName(data: Assignment[], productName: string): any[] {
            let details = []

            for (let i = 0; i < data.length; i++) {

                if (data[i].product === productName) {
                    console.log(data[i].mac)
                    if (data[i].mac) {
                        details.push(data[i].mac);
                    }
                    else {
                        details.push(data[i].serial);
                    }

                }
            }
            return details;
        },
        toggleChip(identifier: string) {
            const index = this.selectedChips.indexOf(identifier);
            if (index === -1) {
                // Add the identifier to selectedChips array
                this.selectedChips.push(identifier);
            } else {
                // Remove the identifier from selectedChips array
                this.selectedChips.splice(index, 1);
            }
        },
        async acceptAssets() {
            const vm = this
            vm.loading = true
            let identifiers = []
            if (vm.selectedChips.length > 0) {
                identifiers = vm.selectedChips
            }
            else {
                identifiers = vm.productDetails
            }
            const response = await axiosWarehouseInstance
                .post("accept", { identifiers: identifiers })
                .then(function (response) {
                    if (response.status == 200) {
                        vm.snackbarResponse = response.data.message
                        vm.snackbarType = 'success'
                    }
                    else {

                    }
                }).catch((error: any) => {
                    if (error.response) {
                        vm.snackbarResponse = error.response.data.message
                    }
                })
            vm.loading = false
            vm.dialog = false
            vm.showSnackbar()
        },
        showSnackbar(): void {
            setTimeout(() => {
                this.openSnackbar = true;
            }, 2000)
        },

    },

    mounted() {
        this.getAssignedAssets()
    }
})
</script>

<template>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-8">
       
        <h3 class="text-center mt-8 mb-8">Assets Not yet Accepted</h3>
        <v-data-table :headers="headers" :items="groupedProducts" @click:row="show">
            <template v-slot:item.date_assigned="{ item }">
                {{ formatDate(item.props.title.date_assigned) }}
            </template>
            <template v-slot:item.manage="{ item }">
            </template>
            <template v-slot:item.accept="{ item }">

                <v-btn class="bg-navigationDrawer">Accept All</v-btn>
            </template>

        </v-data-table>
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto">
        <v-dialog v-model="dialog" max-width="700px">
            <v-card class="">
                <v-card-title>Identifiers</v-card-title>
                <v-card-item>
                    <v-chip v-for=" identifier in productDetails " class="ma-2" closable @click="toggleChip(identifier)"
                        :class="{ 'selected': selectedChips.includes(identifier) }">
                        {{ identifier }}

                    </v-chip>
                </v-card-item>
                <v-card-actions>
                    <v-btn class=" bg-navigationDrawer float-right" color="" @click="acceptAssets()">Accept Selected
                        Assets</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class=" bg-navigationDrawer float-right" color="" @click="acceptAssets()"
                        title="Return All Assets">
                        Accept All Assets
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-col>
    <SnackBar v-if="openSnackbar" :snackbarResponse="snackbarResponse" :snackbarType="snackbarType"/>
    <LoadingOverlay v-if="loading" />
</template>
