<template>
    <v-text-field v-on:keyup.enter="dial" v-model="phoneNumberToDial" label="Phone Number"></v-text-field>
    <v-row align="start" no-gutters>
        <v-col v-for="n in 9" :key="n" cols="4">
            <v-btn @click="phoneNumberToDial += n" variant="text" width="100%" height="5rem">
                {{ n }}
            </v-btn>
        </v-col>
        <v-col :key="-1" cols="4">
            <v-btn @click="phoneNumberToDial = ''" variant="text" width="100%" height="5rem">
                Clear
            </v-btn>
        </v-col>
        <v-col :key="0" cols="4">
            <v-btn @click="phoneNumberToDial += '0'" variant="text" width="100%" height="5rem">
                0
            </v-btn>
        </v-col>
        <v-col :key="-2" cols="4">
            <v-btn @click="phoneNumberToDial = phoneNumberToDial.slice(0, phoneNumberToDial.length - 1);" variant="text"
                width="100%" height="5rem">
                <v-icon>mdi-backspace</v-icon>
            </v-btn>
        </v-col>
    </v-row>
    <v-divider></v-divider>
    <div>
        <v-row align="center" no-gutters>
            <v-col cols="6">
                <v-btn color="warning" variant="text" width="100%" height="5rem">
                    Cancel
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn @click="dial" color="success" variant="text" width="100%" height="5rem">
                    Call
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';

export default defineComponent({
    data: () => ({
        phoneNumberToDial: ""
    }),
    methods: {
        dial() {
            this.$emit('dial', this.phoneNumberToDial);
        }
    },
    mounted() {
    }
});

</script>
