
<template>
    <v-navigation-drawer expand-on-hover rail app v-model="showDrawer" v-if="showAppBars">
        <v-list>
            <v-list-item v-if="userInfo">
                <template v-slot:prepend>
                    <v-avatar color="surface-variant">
                        <v-img :src="userInfo.image"></v-img>
                    </v-avatar>
                </template>
                <v-list-item-title class="text-h6 text-third" v-text="userInfo.display_name"></v-list-item-title>
            </v-list-item>

            <v-list-item prepend-icon="mdi-view-dashboard" :to="{ name: 'Dashboard' }" class="list-items" value="dashboard">
                Dashboard
            </v-list-item>

            <v-list-item prepend-icon="mdi-ticket-account" :to="{ name: 'TicketView' }" class="list-items" value="tickets">
                Tickets
            </v-list-item>

            <v-list-item prepend-icon="mdi-phone-log" :to="{ name: 'CallList' }" class="list-items" value="calls">
                Calls Assigned
            </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list-item prepend-icon="mdi-arrow-decision" :to="{ name: 'ManagerDashboard' }" class="list-items"
            value="managerDashboard">
            Inbound
        </v-list-item>
        <v-list-item prepend-icon="mdi-face-agent" :to="{ name: 'ManagerDashboard' }" class="list-items"
            value="agentDashboard">
            Manager Dashboard
        </v-list-item>

        <v-list-item prepend-icon="mdi-thumbs-up-down" :to="{ name: 'QA' }" class="list-items" value="qa">
            QA
        </v-list-item>
    </v-navigation-drawer>
    <SoftPhone v-if="userInfo" :sip-creds="userInfo" ref="softPhone" />
    <v-main>
        <div v-if="isLoading" class="loading-container">
            <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <router-view v-slot="{ Component, route }">
            <component @dial="dial" :is="Component" />
        </router-view>
    </v-main>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useMainStore } from "@/stores/main";

interface Navigation {
    search: string;
    showDrawer: boolean;
    showSearch: boolean;
    rail: boolean;
    userInfo: any;
    shiftStatus: any;
    agentData: any;
    softPhone: any;
    isLoading: boolean;
    showAppBars: boolean;
    mode: string;
}

export default defineComponent({
    name: "App",
    data: (): Navigation => ({
        search: "",
        showDrawer: true,
        showSearch: true,
        rail: true,
        userInfo: null,
        shiftStatus: null,
        agentData: null,
        softPhone: null,
        isLoading: true,
        showAppBars: true,
        mode: "APP",
    }),
    methods: {
        dial(numberToDial: string) {
            if (this.softPhone) {
                this.softPhone.call(numberToDial);
            }
        },
        getShiftStatusColor() {
            if (!this.shiftStatus) {
                return "grey";
            }
            switch (this.shiftStatus.status) {
                case "Active":
                    return "green";
                case "Not On Shift":
                    return "red";
                case "On Break":
                    return "yellow";
                default:
                    return "grey";
            }
        },
    },
    async mounted() {
        const main = useMainStore();
        await main.fetchAllData();
        this.userInfo = main.userInfo;
        this.shiftStatus = main.shiftStatus;
        this.agentData = main.agentData;
        this.isLoading = false;
        console.log("Mounted Customer Support App");
    },
    setup() {
        const softPhone = ref(null);
        return {
            softPhone,
        };
    }
});
</script>
