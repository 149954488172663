<template>
  <v-container>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto">
      <v-dialog v-model="assignDialog" max-width="700px">
        <v-card>
          <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" lazy-validation valid="valid">
            <h2 color="#9E305B" class=" mt-2 mb-4">{{ selectedProduct }}</h2>

            <p>Assign to:</p>
            <v-autocomplete v-if="assignee == 'highsite'" variant="outlined" v-model="selectedHighsite" :items="highsites"
              :rules="[v => !!v || 'Item is required']" required></v-autocomplete>
            <v-autocomplete v-else variant="outlined" v-model="selectedUser" :items="users"
              :rules="[v => !!v || 'Item is required']" required></v-autocomplete>

            <p v-if="assignableAssets.length > 0">Select identifiers</p>
  
            <v-autocomplete v-if="assignableAssets.length > 0" variant="outlined" v-model="candidateAssets"
              :items="assignableAssets"  :search-input="true" required multiple  @input="performSearch"></v-autocomplete>
            <p v-if="assignableAssets.length == 0">Number of Assets</p>
            <v-text-field v-if="assignableAssets.length == 0" variant="outlined" v-model="count">

            </v-text-field>
            <v-card-actions>

              <v-btn class="" id="assign-assets" @click="closeDialog()">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>

              <v-btn class="" id="assign-assets" @click="assignAssets()">
                Assign
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>
    <v-row no-gutters class="position-relative">
      <div class="text-center">
        <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="10000"
          transition="dialog-bottom-transition">
          <span>{{ snackbarResponse }}</span>
          <template v-slot:actions>
            <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-row>
    <LoadingOverlay v-if="loading" />
  </v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import axios from 'axios'



interface CandidateAssets {
  CandidateAssets: []
}
export default defineComponent({
  name: 'AssignAssetsComponent',
  props: {
    selectedProductId: {
      type: Number,
      required: true
    },
    selectedProduct: {
      type: String,
      required: true
    },
    updateDialogOption: {
      type: Function,
      required: true
    },
    assignDialog: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    assignableAssets: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    action: {
      type: String as PropType<string>,
      required: false
    },
    assignee: {
      type: String as PropType<string>,
      required: false
    },
  },

  data() {
    return {
      selectedUser: "",
      selectedHighsite: "",
      count: 0,
      identifiers: '',
      snackbarResponse: "",
      snackbarType: "error",
      openSnackBar: false,
      available: false,
      candidate: [] as string[],
      candidateAssets: [] as string[],
      loading: false,
      assignDialog: this.assignDialog,
      highsites: [] as string[],
      visibleAssets: [] as any[], // Subset of visible assets
      searchQuery:""

    }
  },
  methods: {
    async assignAssets(): Promise<void> {
      let vm = this
      vm.loading = true
      let response = await axios.post("confirm-assignment/", {
        user: this.selectedUser,
        highsite: this.selectedHighsite,
        candidate_assets: vm.candidateAssets,
        count: vm.count,
        product: this.selectedProduct,
        assignee: this.assignee
      })
        .then((response) => {
          console.log("this is the response message", response);
          if (response.status === 200) {
            console.log("response status was 200")

            vm.snackbarResponse = response.data.message;

            vm.snackbarType = "success";
            vm.available = true;

          }
        })
        .catch((error) => {
          console.log("an error occurred", error.response.data);
          this.snackbarResponse = error.response.data.message;
        })
      vm.toggleDialogOption();
      vm.showSnackbar();
      vm.resetData();
      vm.loading = false

    },
    showSnackbar(): void {
      setTimeout(() => {
        console.log("this is the snackbar response and type", this.snackbarResponse, this.snackbarType)
        this.openSnackBar = true;
      }, 2000);
    },
    closeDialog() {
      this.toggleDialogOption()
    },
    resetData() {
      this.selectedUser = ""
      this.count = 0
      this.identifiers = ''
      this.candidateAssets = []
    },
    toggleDialogOption() {
      this.updateDialogOption(!this.assignDialog);
    },
    async getHighsite(): Promise<void> {
      let vm = this
      const response = await axios.get('get-highsites').then(function (response) {
        if (response.status == 200) {
          vm.highsites = response.data.highsites
        }
      })
    },

    performSearch() {
      console.log("this is the search")
      setTimeout(() => {
        this.visibleAssets= this.assignableAssets.filter((asset:any) =>
          asset.includes(this.searchQuery.toLowerCase())
        );

        // Hide the loading indicator
       
      }, 1000); // Simulated delay for demonstration purposes
    },
   
    },
  

  created() {

  },
  mounted() {
    if (this.assignee == 'highsite') {
      this.getHighsite()
    }
    console.log("trying to get available assets")
  }
});
</script>
<style scoped>
#assign-assets {
  color: white;
  border: 1px solid #003F94;
  border-radius: 5px solid #003F94;
  background: #003F94;
}
</style>