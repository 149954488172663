<template>
    <v-app id="app">
        <v-layout>
            <v-app-bar
                :elevation="2"
                density="compact"
                theme="dark"
                extended
                extension-height="10"
                app
                class="bg-bluePaint d-flex align-end"
                v-if="showAppBars"
            >
                <v-row no-gutters align="center">
                    <v-col cols="auto">
                        <AppSelect />
                    </v-col>
                    <v-col cols="auto">
                   
                        <div
                            class="text-h6 text-center px-4 py-4 font-weight-bold vertex-logo"
                        >
                            <a
                                class="vertex-logo"
                                href="https://vertex.ap3k.co/"
                                >Vertex</a
                            >
                        </div>
                    </v-col>
                </v-row>
            </v-app-bar>

            <v-main>
                <router-view v-slot="{ Component, route }">
                    <transition name="fade" mode="out-in" :key="route.fullPath">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </v-main>

        </v-layout>

    </v-app>
    
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useMainStore } from "./stores/main";
import AppSelect from "./components/AppSelect.vue";
import router from "./router";


interface Navigation {
    search: string;
    showDrawer: boolean;
    showSearch: boolean;
    rail: boolean;
    userInfo: any;
    shiftStatus: any;
    agentData: any;
    softPhone: any;
    isLoading: boolean;
    showAppBars: boolean;
    mainStore: any;
    selectedApp: any;
}

export default defineComponent({
    name: "App",
    data: (): Navigation => ({
        search: "",
        showDrawer: true,
        showSearch: true,
        rail: true,
        userInfo: null,
        shiftStatus: null,
        agentData: null,
        softPhone: null,
        isLoading: true,
        showAppBars: true,
        mainStore: null,
        selectedApp: null,
    }),
    async mounted() {
        this.mainStore = useMainStore();
        watch(
            () => this.mainStore.selectedApp,
            (newValue) => {
                console.log(newValue);
                this.selectedApp = newValue;
                console.log("this is the selected app", this.selectedApp)
                console.log("this is the route component name", newValue.route)
                this.$router.push({ name: newValue.route});
            }
        );

        watch(
            () => this.mainStore.hideMainNav,
            (newValue) => {
                console.log(newValue);
                this.showAppBars = !newValue;
            }
        );
        await this.mainStore.fetchAllData();
        this.userInfo = this.mainStore.userInfo;
        this.shiftStatus = this.mainStore.shiftStatus;
        this.agentData = this.mainStore.agentData;
        this.isLoading = false;
        
    },
    setup() {

        console.log(window.location.href);
        if (window.location.href.includes("customer-support-live-dashboard")) {
            console.log("this is the customer support live dashboard");
            router.push({ name: "CustomerSupportLiveDashboard" });
        }
        const softPhone = ref(null);
        
        return {
            softPhone,
        };
    },
    components: { AppSelect }
});
</script>
<style>
body {
    font-size: 17px;
    font-family: "Roboto";
}

.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}

a {
    text-decoration: none;
}

#vertex {
    color: white;
    background-color: #295a6e;
    text-decoration: none;
}

.vertex-logo {
    color: white;
    text-decoration: none;
    cursor: auto;
}
</style>
