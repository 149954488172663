<script setup lang="ts">
import InstallationDetails from './InstallationDetails.vue'
</script>


<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Call Details for ID: {{ selectedCall.id }}
                    </v-card-title>
                    <v-card-text>
                        <p>Call Type: {{ selectedCall.call_type }}</p>
                        <p>Title: {{ selectedCall.title }}</p>
                        <!-- Add more details here -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <InstallationDetails :installation="installation"></InstallationDetails>
</template>
  
<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';

interface CallListItem {
    id: number;
    call_type: string;
    title: string;
}

export default defineComponent({
    props: {
        selectedCall: {
            type: Object as () => CallListItem,
            required: true,
        },
    },
    data: () => ({
        installation: null as any
    }),
    methods: {
        async fetchCallDetails() {
            try {
                // Make the API call to fetch additional details for the selected call
                const response = await axios.get(`/call/${this.selectedCall.id}/details/`);
                this.installation = response.data.installation
                // Assuming the response contains the additional details, update the data properties
                // with the received data
                // For example:
                // this.myAdditionalData = response.data.additionalData;
            } catch (error) {
                console.error('Error fetching call details:', error);
            }
        },
    },
    mounted() {
        // Fetch the additional call details when the component is mounted
        this.fetchCallDetails();
    },
});
</script>
  
<style>
/* Add your styles here */
</style>
