<script setup lang="ts">
import MicTest from '@/components/MicTest.vue'
import ShiftStatus from '@/components/ShiftStatus.vue'
</script>

<template>
    <div>
        <v-container fluid>
            <div class="dashboard-container">
                <div v-if="isLoading" class="loading-container">
                    <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <div v-else-if="agentStats != null">
                    <v-row class="mb-2">
                        <v-col cols="12" md="6" lg="3">
                            <v-card outlined class="text-center">
                                <v-card-title>Calls Made Today</v-card-title>
                                <v-card-text>
                                    <h1>{{ agentStats.total_calls_made_today }}</h1>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-card outlined class="text-center">
                                <v-card-title>Calls Answered Today</v-card-title>
                                <v-card-text>
                                    <h1>{{ agentStats.total_calls_answered_today }}</h1>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-card outlined class="text-center">
                                <v-card-title>Average Call Duration</v-card-title>
                                <v-card-text>
                                    <h1>{{ agentStats.average_call_duration }} mins</h1>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="3">
                            <v-card outlined class="text-center">
                                <v-card-title>Average Time to Answer</v-card-title>
                                <v-card-text>
                                    <h1>{{ agentStats.average_time_to_answer }} secs</h1>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <v-row>
                <v-col cols="12" md="6" lg="3">
                    <ShiftStatus class="mb-2" />
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <MicTest class="mb-2" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import axios from 'axios'
import { defineComponent } from 'vue';

export default defineComponent({
    data: () => ({
        agentStats: null as any,
        isLoading: true
    }),
    methods: {
        async getAgentStats() {
            axios.get('agent/stats/').then(response => {
                    this.agentStats = response.data
                })
                .catch(err => {
                    
                })
                .finally(() => {
                    this.isLoading = false
                })
        },
    },
    mounted() {
        this.getAgentStats()
    }
})

</script>

<style>
.dashboard-container {
  /* Adjust the height to match the expected height of your dashboard */
  min-height: 100px;
  position: relative;
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
