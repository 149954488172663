

<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                <h2 class="mb-10">Update Invoice</h2>
                <v-timeline side="end">
                    <v-timeline-item v-for="timeline in items" :dot-color="timeline.color" size="small">
                        <div class="d-flex">
                            <div>
                                <strong>{{ timeline.stage }}</strong>
                                <div class="text-caption">
                                    {{ formatDate(timeline.date) }}
                                </div>
                                <div class="text-caption">
                                    {{ timeline.user }}
                                </div>
                            </div>
                            <v-checkbox v-if="timeline.stage !='Created' && timeline.color !='success'" :label="`Mark as ${timeline.stage}`"></v-checkbox>
                        </div>
                    </v-timeline-item>
                 
                </v-timeline>
            </v-col>

            <v-col cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6" class=" justify-content-start">
                <h2 class="mb-10">Invoice Detail</h2>
                <v-row no-gutters>
                    <v-col cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                        <p>Invoice Number:{{ generalDetails.invoice_number }}</p>
                        <p>Supplier:{{ generalDetails.supplier__name }}</p>
                        <p>Product(s)</p>
                        <p>Quantity:{{ invoiceLines.unit_count }}</p>
                        <p>Purpose:{{ generalDetails.purpose }}</p>

                    </v-col>
                </v-row>
                <v-row no-gutters class="float-right mt-16">
                    <v-col cols="" xs="12" sm="12" md="6" lg="6" xl="6" xxl="6">
                        <p>Subtotal:R{{ generalDetails.total_ex_vat }}</p>
                        <p>Discount:R{{ generalDetails.discount }}</p>
                        <p>VAT:R{{ generalDetails.vat }}</p>
                        <p>Total:R{{ generalDetails.total_inc_vat }}</p>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>

<script lang="ts">
import AssetLists from '../components/AssetLists.vue'
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '@/stores/warehouse_store';
interface OrderInvoiceLine {
    unit_count:number,
    product_code: string,
    description: string,
    quantity: number,
    unit_price: string,
    amount: number,
}
interface GeneralDetails{
    [key: string]: any;
}

interface OrderInvoiceStatus {
    created: {
        user: string;
        is_done: boolean
        date: string;
    };
    approved: {
        user: string;
        is_done: boolean;
        date: string;
    };
    paid: {
        user: string;
        is_done: boolean;
        date: string;
    };
    received: {
        user: string;
        is_done: boolean;
        date: string;
    };
    [key: string]: any;
}

export default defineComponent({
    components: {
        AssetLists
    },
    data: () => ({
        events: [] as { id: number, text: string, time: string }[],
        input: null as string | null,
        nonce: 0 as number,
        items: [] as any[],
        headers: {
            "Product Code": "product__product_code",
            "Description": "product__description",
            "Quantity": "unit_count",
            "Unit Price": "price",
            "Amount": "total_amount",
        },
        orderInvoiceDetails: {} as OrderInvoiceLine,
        timelineDetails: {} as OrderInvoiceStatus,
        generalDetails: {} as GeneralDetails,
        invoiceLines: {} as OrderInvoiceLine,
        dialog: false as boolean,
        markAsApproved: false as boolean,
        markAsPaid: false as boolean,
        markAsReceived: false as boolean,
        snackbarResponse: "",
        snackbarType: "error",
        openSnackBar: false,
        invoiceNote: "",
        noteDialog: false as boolean,
        loading: false,
        marked:true

    }),

    computed: {
        timeline(): { id: number, text: string, time: string }[] {
            return this.events.slice().reverse()
        },
    },

    methods: {
        async getInvoiceDetails() {

            let vm = this
            vm.loading=true
            try {
                const response = await axiosWarehouseInstance
                    .post("order-invoice/view/", { id: vm.$route.params.id })
                this.timelineDetails = response.data.timeline_details
                this.generalDetails = response.data.general_details[0]
                this.invoiceLines = response.data.invoice_lines[0]
            }
            catch (error) {

            }
            finally {
                this.setStatusItems()
            }
            vm.loading=false
        },
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        setStatusItems() {
            for (const [status, data] of Object.entries(this.timelineDetails)) {
                let color = ""
                let disabled = false
                let icon = ""
                if (data.is_done) {
                    color = "success"
                    icon = "mdi-check"
                    disabled = true
                }
                else {
                    color = "gray"
                    icon = "mdi-loading"
                    disabled = false

                }
                this.items.push(
                    {
                        color: color,
                        stage: status.charAt(0).toUpperCase() + status.slice(1),
                        disabled: disabled,
                        icon: icon,
                        user: data.user.charAt(0).toUpperCase() + data.user.slice(1),
                        date: this.formatDate(data.date)

                    }
                )
            }


        },
        async editStatus() {
            try {
                const response = await axiosWarehouseInstance
                    .post("invoice/edit-status/", { id: this.$route.params.id, mark_as_approved: this.markAsApproved, mark_as_paid: this.markAsPaid, mark_as_received: this.markAsReceived })
                if (response.status == 200) {
                    this.snackbarResponse = response.data.message
                    this.snackbarType = "success"
                }
            }
            catch (error: any) {
                this.snackbarResponse = error.response.message
            }
            finally {
                this.dialog = false
                this.showSnackbar()
            }
        },
        async addNote() {
            let vm = this
            try {
                const response = await axiosWarehouseInstance
                    .post("invoice/add-note/", { id: this.$route.params.id, note: this.invoiceNote })
                if (response.status == 200) {
                    this.snackbarResponse = response.data.message
                    this.snackbarType = "success"
                }
            }
            catch (error: any) {

                this.snackbarResponse = error.response.message
            }
            finally {
                vm.loading = false
                vm.dialog = false
                vm.showSnackbar()
            }
        },
        showSnackbar(): void {

            setTimeout(() => {
                this.openSnackBar = true;
            }, 3000);
        },
    },
    mounted() {
        this.getInvoiceDetails()
    }

})

</script>