
<template>
  <v-container class="">
    <h2 class="mb-10">Assignments</h2>
    <v-row no-gutters>
      <v-col cols="12" xs="4" sm="12" md="4" lg="4" xl="4" xxl="4" class="">
        <v-autocomplete :items="users" v-model="searchUser" variant="solo" label="Select User" required></v-autocomplete>
      </v-col>
      <v-col cols="12" xs="4" sm="12" md="4" lg="4" xl="4" xxl="4" class="">
        <v-autocomplete :items="['10 Days', '20 Days','All Time']" v-model="interval" variant="solo" label="Select User" required></v-autocomplete>
      </v-col>
    
     
    </v-row>
    <v-row>
      <v-col cols="12" xs="4" sm="12" md="12" lg="12" xl="12" xxl="12" class="">
        <v-data-table v-model:items-per-page="itemsPerPage" :headers="headers" :items="groupedProducts" item-value="name"
          class="elevation-1" @click:row="show">

        </v-data-table>
      </v-col>
    </v-row>
    <v-row no-gutters>
      {{ productDetails }}
      <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="mx-auto">
        <v-dialog v-model="dialog" max-width="700px">
          <v-card class="">
            <v-card-title>Identifiers</v-card-title>
            <v-card-item>
              <v-chip v-for=" identifier in productDetails " class="ma-2" closable @click="toggleChip(identifier)"
                :class="{ 'selected': selectedChips.includes(identifier) }">
                {{ identifier }}

              </v-chip>
            </v-card-item>
            <v-card-actions>
              <v-btn class=" bg-navigationDrawer float-right" color="" @click="returnAssets()">Return Selected Assets</v-btn>
              <v-spacer></v-spacer>
              <v-btn class=" bg-navigationDrawer float-right" color="" @click="returnAssets()" title="Return All Assets">
                Return All Assets
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <SnackBar v-if="openSnackbar" :snackbarResponse="snackbarResponse" :snackbarType="snackbarType"/>
    <LoadingOverlay v-if="loading" />
  </v-container>
</template>

<script lang="ts">
import axiosWarehouseInstance from '@/stores/warehouse_store';
import { defineComponent, reactive, ref } from 'vue';

interface ProductCount {
  product: string;
  count: number;
}
interface AssignedAsset {
  assigned_to__username: string;
  date_assigned: string;
  asset__product__name: string;
  asset__serial: string | null;
  asset__mac: string | null;
  status: string;
  [key: string]: any
}
/* const state = reactive<UnacceptedAssets>({ // Here I assigned State as the reactive type
  people: [], // Another fix: I assume it should be an array of people? so it has to be empty **array** by default.
}); */

export default defineComponent({
 

  data() {
    return {
      reveal: false,
      assetDetails: [] as any,
      dialog: false,
      //means serial or mac addresses of assets extracted using the getSpecificIdentifiers
      candidateAssets: [""],
      assetsTobeUnassigned: [] as string[],
      users: [] as string[],
      userId: "",
      search: "",

      snackbarResponse: "",
      snackbarType: "error",
      openSnackbar: false,
      loading: false,
      searchStatus: '',
      searchUser: '',
      interval:'',
      headers: [
        { title: 'Product Name', align: 'center', key: 'product' },
        { title: 'Count', align: 'center', key: 'count' },


      ] as any,
      itemsPerPage: 100,
      groupedProducts: [] as any[],
      selectedProduct: "",
      productDetails: [] as string[],
      selectedChips: [] as string[]
    }
  },

  methods: {

    async getUnacceptedAssets() {
      let vm = this
      vm.loading = true
      try {
        let response = await
          axiosWarehouseInstance.post('assigned/', {
            username: this.search,
            interval:this.interval
          })
        this.assetDetails = response.data.details
        vm.groupedProducts = vm.countProducts(vm.assetDetails)
        console.log("these are the grouped assets", vm.groupedProducts)

      }
      catch (error) {

      }
      vm.loading = false
    },


    async returnAssets() {
      let vm = this
      vm.loading = true
      let identifiers = []
      if (vm.selectedChips){
        identifiers = vm.selectedChips
      }
      else{
        identifiers = vm.productDetails
      }
      try {
        const response = await
          axiosWarehouseInstance.post("returns/warehouse/", { identifiers:identifiers })
        vm.snackbarResponse = `Assets have been assigned`;
        vm.snackbarType = "success";
      }
      catch (error: any) {
        vm.snackbarResponse = error.response.data.message;
        vm.snackbarType = "error";
      }
      finally {
        vm.showSnackbar()
        vm.loading = false
        vm.selectedChips = []
        vm.productDetails = []
        vm.dialog = false
      }

    },
    
    countProducts(data: AssignedAsset[]): ProductCount[] {
      const counts: ProductCount[] = [];


      for (let i = 0; i < data.length; i++) {
        const product = data[i].asset__product__name;
        const user = data[i].assigned_to__username
        const existingProduct = counts.find((item) => item.product === product);

        if (existingProduct) {
          existingProduct.count += 1;
        } else {
          counts.push({ product, count: 1 });
        }
      }

      return counts;
    },
    toggleChip(identifier:string) {
            const index = this.selectedChips.indexOf(identifier);
            if (index === -1) {
                // Add the identifier to selectedChips array
                this.selectedChips.push(identifier);
            } else {
                // Remove the identifier from selectedChips array
                this.selectedChips.splice(index, 1);
            }
        },
    show(event: any, value: any) {
      const clickedItem = value.item;
      this.selectedProduct = clickedItem.value.product
      console.log(this.assetDetails)
      this.productDetails = this.getDetailsByProductName(this.assetDetails, this.selectedProduct)
      this.dialog = true
      console.log(this.productDetails)

    },
    //gets a list of serial numbers or mac addresses on puts them on the v-autocomplete

    getDetailsByProductName(data: AssignedAsset[], productName: string): any[] {
      let details = []

      for (let i = 0; i < data.length; i++) {
        if (data[i].asset__product__name === productName) {
          if (data[i].mac) {
            details.push(data[i].asset__mac);
          }
          else {
            details.push(data[i].asset__serial);
          }

        }
      }
      return details;
    },
    showSnackbar(): void {
      setTimeout(() => {
        
        this.openSnackbar = true;
      }, 2000)
    },
    formatDate(date: string) {
      let formartedDate = new Date(date);
      return formartedDate.toLocaleDateString("en-us", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
    },
    async getUsers() {
      try {
        let response = await
          axiosWarehouseInstance.get('users')
        this.users = response.data.users
      }
      catch (error) {

      }
    }


  },
  watch: {
    searchStatus(newStatus, oldStatus) {
      if (newStatus) {
        this.search = newStatus
      }


    },
    searchUser(newUser, oldUser) {
      if (newUser) {
        this.search = newUser
        this.getUnacceptedAssets()
      }
    }

  },
  mounted() {
    this.getUsers()
  }

})
</script>
<style>
.button-container {
  display: flex;
  justify-content: space-between;
}

.selected {
  background-color:#385196; /* Set your desired color for selected chips */
  color: #ffffff; /* Set the text color for selected chips */
}

</style>
