<template>
    <v-table>
        <thead>
            <tr>
                <th class="text-left">
                    Number
                </th>
                <th class="text-left">
                    Date
                </th>
                <th class="text-left">
                    Direction
                </th>
                <th>

                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in callLog" :key="index">
                <td>{{ item.number }}</td>
                <td>{{ item.date_called }}</td>
                <td>
                    <v-icon v-if="item.direction == 'INBOUND'">mdi-phone-incoming</v-icon>
                    <v-icon v-if="item.direction == 'OUTBOUND'">mdi-phone-outgoing</v-icon>
                </td>
                <td>
                    <v-btn size="small" color="green" @click=redial(item.number)>
                        Call
                    </v-btn>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts">

import axios from 'axios'
import { defineComponent } from 'vue';

export default defineComponent({
    data: () => ({
        callLog: null as any,
    }),
    methods: {
        getCallLog() {
            let vm = this;
            let response = axios
                .get('call/log/')
                .then(function (response) {
                    if (response.status === 200) {
                        vm.callLog = response.data
                        
                    }
                })
                .catch((error: string) => {
                    
                    vm.callLog = null
                })
        },
        redial(phoneNumber: any){
            this.$emit('redial', phoneNumber);
        }
    },
    mounted() {
        this.getCallLog()
    }
});

</script>
