import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style.css";
import './axios-config'; 

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createPinia } from "pinia";
import LoadingOverlay from "../src/components/LoadingOverlay.vue";
import SoftPhone from "../src/components/SoftPhone.vue";
import CallLog from "../src/components/CallLog.vue";
import Dialer from "../src/components/Dialer.vue";
import * as Sentry from "@sentry/vue";
import VueApexCharts from "vue3-apexcharts";

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: "light",
        themes: {
            light: {
                colors: {
                    primary: "#3f51b5",
                    secondary: "#D32F2F",
                    accent: "#8c9eff",
                    error: "#b71c1c",
                    darkGrey: "#24292e",
                    pacificBlue: "#0693e3",
                    darkRed: "#92140c",
                    white: "#ffffff",
                    gray: "#808080",
                    mintGreen: "#99ff99",
                    limeGreen: "#4CAF50",
                    blackPanther: "#424242",
                    gray64: "#A3A3A3",
                    darkBlue: "#155d86",
                    tmwGreen: "#009A46",
                    tmwRed: "#DF291C",
                    tmwOrange: "#fdb813",
                    tmwBlue: "#00acdc",
                    lightGrey: "#DCDCDC",
                    darkBlue2: "#295a6e",
                    lightBlue: "#F0F3F8",
                    bluePaint: "#0E213D",
                    strongRed: "#932D55",
                },
            },
        },
    },
});

const app = createApp(App);

const isDev = process.env.NODE_ENV === 'development';

if (isDev===false){
    Sentry.init({
        app,
        dsn: "https://bb3968df99d94a0edd1aac32706fee8b@o106966.ingest.sentry.io/4505749350907904",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}


const pinia = createPinia();

app.component("LoadingOverlay", LoadingOverlay);
app.component("SoftPhone", SoftPhone);
app.component("CallLog", CallLog);
app.component("Dialer", Dialer);
// app.component("LiveDashboard", LiveDashboard);

app.use(pinia);

app.use(router);

app.use(vuetify);

app.use(VueApexCharts);
app.mount("#app");
