<template>
    <v-container>
        <v-row no-gutters>
            <v-col cols="12" xs="4" sm="12" md="4" lg="4" xl="4" xxl="4" class="">
                <v-autocomplete v-model="returnType" :items="['Warehouse', 'Scrapping']" variant="solo" label="Return"
                    required></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="4" sm="12" md="8" lg="8" xl="8" xxl="8">
                <v-btn :to="{ name: 'LogSupplierReturn' }" flat class="float-right">Log a Return<span>+</span></v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                <v-data-table v-if="scrappingItems" :headers="scrappingHeaders" :items="scrappingItems">

                    <template v-slot:[`item.actions`]="{ value }">
                        <v-btn color="navigationDrawer" size='small' @click="dialog = true, selectedEvent=value.raw.id">Change Status</v-btn>
                        <v-btn class="float-right" color="navigationDrawer" size='small'
                            @click="viewDialog = true, identifiers = value.raw.asset_return__identifiers">View Assets</v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-dialog v-model="dialog" max-width="700px">
                <v-card>
                    <v-form class="ml-3 mt-3 mb-3 mr-3" ref="form" enctype="multipart/form-data" lazy-validation
                        valid="valid">


                        <v-select variant="outlined" v-model="selectedStatus" :items="['WAITING', 'DISPOSED', 'TRANSIT']"
                            label="Change status"></v-select>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                            <v-btn class="bg-navigationDrawer" @click="changeStatus()">Submit</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>

            </v-dialog>
            <v-dialog v-model="viewDialog" max-width="700px">
                <v-card>
                    <v-table density="compact">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Identifiers
                                </th>
                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in identifiers" :key="item">
                                <td>{{ item }}</td>
                               
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>

            </v-dialog>
        </v-row>
    </v-container>
</template>

<script lang="ts">

interface ScrapItem {
    id: number;
    asset_return__identifiers: [];
    status: string;
    asset_return__product__name: string;
}

interface ScrapItemsResponse {
    scrap_items: ScrapItem[];
}
import { defineComponent } from 'vue';
import Lists from '../components/Lists.vue';
import axiosWarehouseInstance from '../../../stores/warehouse_store';

export default defineComponent({
    components: {
        Lists,
    },

    data: () => ({
        loading: true,
        returnType: "",
        scrappingHeaders: [
            {
                align: 'start',
                key: 'id',
                sortable: false,
                title: 'ID',
            },
            { key: 'asset_return__product__name', title: 'Product' },
            { key: 'status', title: 'Status' },

            { key: 'actions', title: 'Actions' },


        ] as any,
        scrappingItems: [] as ScrapItemsResponse[],
        dialog: false,
        selectedStatus: "",
        viewDialog: false,
        identifiers: [] as any,
        identifierHeaders: [{ 'title': 'Identifiers' }, { 'key': 'identifiers' }] as any,
        selectedEvent:0


    }),
    methods: {
        async getReturns() {
            try {
                let response = await
                    axiosWarehouseInstance.get(`returns-list/${this.returnType.toLowerCase()}`)
                this.scrappingItems = response.data.scrap_items
            }
            catch (error) {

            }

        },
        async changeStatus() {
            const vm = this
            vm.dialog = false
            vm.loading = true
            const response = await axiosWarehouseInstance.post('scrapping/change-status/', {
                status: vm.selectedStatus,
                id:vm.selectedEvent

            })
            vm.loading = false
            
        },
    },
    watch: {
        returnType(newUser, oldUser) {
            if (newUser) {
                this.getReturns()
            }


        },

    },

})

</script>