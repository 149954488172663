import {
    createRouter,
    createWebHistory,
    createMemoryHistory,
    createWebHashHistory
} from "vue-router";
import CustomerSupport from "@/apps/customer-support/Index.vue";
import CustomerSupportRoutes from "@/apps/customer-support/routes";
import WarehouseRoutes from "@/apps/warehouse/routes";
import Warehouse from "@/apps/warehouse/Index.vue";
import Schedule from "@/apps/schedule/Index.vue";
import ScheduleRoutes from "@/apps/schedule/routes";
import Dashboard from "@/apps/dashboard/Index.vue";
import DashboardRoutes from "@/apps/dashboard/routes";

// Get Current Path for Router
let currentPath = window.location.pathname;
let history = import.meta.env.DEV ? createWebHistory() : createWebHashHistory(currentPath)
 
export default createRouter({
    history: history,
    routes: [
        {
            path: "/customer-support/",
            component: CustomerSupport,
            children: CustomerSupportRoutes
        },
        {
            path: "/warehouse/",
            component: Warehouse,
            children: WarehouseRoutes
        },
        {
            path: "/schedule/",
            component: Schedule,
            children: ScheduleRoutes
        },
        {
            path: "/dashboard/",
            component: Dashboard,
            children: DashboardRoutes
        }
    ],
});
