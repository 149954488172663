<script setup lang="ts">
import { ref, watch } from 'vue'
import { AVMedia, AVWaveform } from 'vue-audio-visual'
import { useUserMedia } from '@vueuse/core'

import testUrl from "/test.mp3"

const showMedia = ref(false)
const { stream, enabled } = useUserMedia()

watch(enabled, () => {
    if (showMedia.value) return
    if (enabled) showMedia.value = true
})
</script>


<template>
    <v-card class="mx-auto" max-width="368">
        <v-card-item>
            <v-row>
                <v-col>
                    <p class="text-h6">Mic Test</p>
                </v-col>
                <v-col>
                    <v-btn color="primary" @click="enabled = !enabled" type="submit" class="float-right">
                        <v-icon v-if="enabled">mdi-stop</v-icon>
                        <v-icon v-else>mdi-play</v-icon>
                        {{ enabled ? 'Stop' : 'Start' }}
                    </v-btn>
                </v-col>
            </v-row>
            <div v-show="!showMedia" class="card mt-3">
                <div class="card-body">
                    Click "Start" and allow audio if asked.
                </div>
            </div>
            <div v-show="showMedia" class="row row-cols-2">
                
                <div class="col">
                    <AVMedia :media="stream" type="frequ" frequ-direction="mo" :frequ-lnum="60"
                        line-color="darkorange" />
                </div>
            </div>
        </v-card-item>

        <v-divider></v-divider>
        
        <v-card-item>
            <p class="text-h6">Headset Test</p>
            <AVWaveform :src="testUrl" noplayed-line-color="#CFCFCF" played-line-color="lime" />
        </v-card-item>
    </v-card>

</template>
  