<template>
    <v-container>
        <h2>Authorized Suppliers</h2>
            <ListsToolbar :add-dialog="addDialog" :view-type="'Suppliers'" />
            <Lists :headers="headers" :list-type="supplierList" :edit-dialog="editDialog" :view-type="'Suppliers'" />
    </v-container>
</template>

<script lang="ts" >
import axiosWarehouseInstance from '@/stores/warehouse_store';
import { defineComponent } from 'vue'
import ListsToolbar from "../components/ListsToolbar.vue"
import Lists from "../components/Lists.vue"
import { format } from "path";


interface Suppliers {
    id: number,
    name: string,
    contact_number: string,
    date_created: string
}

export default defineComponent({
    components: {
        ListsToolbar,
        Lists
    },
    data: () => ({
        supplierList: [] as Suppliers[],
        supplierName: '',
        contactNumber: '',
        search: '',
        dialog: false,
        addDialog: false,
        editDialog: false,
        headers: [
            {
                align: 'start',
                key: 'name',
                sortable: false,
                title: 'Name',
            },
            { key: 'contact_number', title: 'Contact Number' },
            { key: 'date_created', title: 'Date Created' },
            { key: 'actions', title: 'Actions' }

        ]
    }),

    methods: {
        async suppliers() {
            let vm = this
            let resppnse = await axiosWarehouseInstance
                .get("suppliers")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.supplierList = response.data.suppliers
                        
                    }
                })
                .catch((error: string) => {
                    if (error) {
                        
                    }
                })


        },
    },
    created() {
        this.suppliers();

    }
})

</script>
<style scoped>
</style>