import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

const baseURL = import.meta.env.VITE_AXIOS_BASE_URL;
if (baseURL) {
  axios.defaults.baseURL = baseURL;
}

const timeout = import.meta.env.VITE_AXIOS_TIMEOUT;
if (timeout) {
  axios.defaults.timeout = parseInt(timeout, 10);
}

console.log("these are the axios defaults", axios.defaults)
