<template>
    <v-container>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row no-gutters justify="space-between">
          
            <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-autocomplete placeholder="Invoice" v-model="invoice" variant="outlined"
                    :items="invoices"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12"  md="3" lg="3" xl="3">
                <v-autocomplete placeholder="Interval" v-model="interval" variant="outlined"
                    :items="['Weekly', 'Bi-Weekly', 'Monthly', 'All Time']"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12"  md="3" lg="3" xl="3" >
                <v-autocomplete placeholder="Product" v-model="product" variant="outlined"
                    :items="products"></v-autocomplete>
            </v-col>


            <v-col cols="12" sm="12"  md="2" lg="2" xl="2">
                <v-btn @click="getDashboardData" class="bg-navigationDrawer">Submit</v-btn>

            </v-col>
        </v-row>

    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card class="h-auto">
            <v-row no-gutters class="mx-auto justify-center">
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" xxl="3">
                    <v-card class="dashboard-card w-auto" id="assets-warehouse" tile>
                        <v-card-title>Assets in Warehouse</v-card-title>
                        <v-card-subtitle>this month</v-card-subtitle>
                        <v-card-item>
                            <span class="no-of-assets">{{ dashboardData.warehouse }}</span>
                        </v-card-item>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" xxl="3">
                    <v-card class="dashboard-card" color="" id="assets-pending-delivery" tile>
                        <v-card-title>Pending Delivery</v-card-title>
                        <v-card-subtitle>this month</v-card-subtitle>
                        <v-card-item>
                            <span class="no-of-assets">{{ dashboardData.pending_delivery }}</span>
                        </v-card-item>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" xxl="3">
                    <v-card class="dashboard-card" color="" id="orders-made" tile>
                        <v-card-title>Orders Made</v-card-title>
                        <v-card-subtitle>this month</v-card-subtitle>
                        <v-card-item>
                            <span class="no-of-assets">{{ dashboardData.orders }}</span>
                        </v-card-item>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3" xxl="3">
                    <v-card class="dashboard-card" color="" id="assets-delivered" tile>
                        <v-card-title>Assets Delivered</v-card-title>
                        <v-card-subtitle>this month</v-card-subtitle>
                        <v-card-item>
                            <span class="no-of-assets">{{ dashboardData.delivered }}</span>
                        </v-card-item>

                    </v-card>
                </v-col>




            </v-row>
        </v-card>
    </v-col>


    <v-col class="mt-10 " cols="12" xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
        <v-card class="w-auto" id="recent-stock-movement" tile>
            <v-card-subtitle>this month</v-card-subtitle>

            <Bar v-if="!loading" class="" :data="chartData" :options="options" />

        </v-card> 
    </v-col>
    <!-- <p v-for="data in dashboardData">{{ data }}</p> -->
    <LoadingOverlay v-if="loading" />
</v-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '../../../stores/warehouse_store';
import Vue from 'vue'
import { store } from '../../../stores/warehouse_store'
import {
    Chart as ChartJS,
    Title, 
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel';
ChartJS.register(CategoryScale, LinearScale, BarElement, TrapezoidElement, FunnelController, Title, Tooltip, Legend)

interface DictionaryArray {
    [index: number]: { [key: string]: any };
};

interface DashboardData {
    low_stock_inventory: DictionaryArray,
    warehouse: number,
    orders: number,
    delivered: number,
    pending_delivery: number,
    assigned: number,
    deployed: number,
    lost: number,


    cost: number
}
interface StockMovement {
    movements: number[]
}
interface ChartData {
    labels: string[];
    datasets: ChartDataset[];
}

interface ChartDataset {
    data: number[];
    label: string;
    backgroundColor: string[];
}
export default defineComponent({
    name: 'DashboardSummary',
    components: {
        Bar
    },
    data: () => ({
        dashboardData: {} as DashboardData,
        loading: true,
        movements: [],
        chartData: {} as ChartData,
        options: {
            responsive: true
        },
        products: [],
        interval: null,
        product: null,
        invoices: [],
        invoice: [] as any,
        store

    }),
    methods: {
        async getDashboardData(): Promise<void> {
            let vm = this
            vm.loading = true
            try {
                const response = await
                    axiosWarehouseInstance.post('ams', { interval: vm.interval, product: vm.product,invoice_number:vm.invoice[0] });
                vm.dashboardData = response.data
                vm.movements = response.data.movements
                vm.products = response.data.products
                vm.invoices = response.data.invoices
                vm.chartData = {
                    labels: ['Purchase', 'Assigned', 'Deployed', 'Lost/Damage'],
                    datasets: [
                        {
                            data: vm.movements,
                            label: "Recent Movements in Inventory",
                            backgroundColor: ['#9E305B', "#3A7CA5", '#388697', '#16425B']
                        }
                    ]
                }
            }
            catch (error) {
                console.log(error);
                //throw error
            }

            finally {
                this.loading = false
            }


        },

    },
    created() {
        this.getDashboardData()
    }

})
</script>

<style>
.dashboard-card {
    color: #FFFFFF
}

.low-stock-actions {
    color: #003F94;
    border: 1px solid #003F94;
    border-radius: 5px solid #003F94;
    background: white
}

#create-invoice {
    color: white;
    background: radial-gradient(50% 50% at 50% 50%, #004AAD 0%, #003F94 100%);
}

#low-stock-inventory {
    color: #9E305B;
    min-height: 500px;

}

#recent-stock-movement {
    color: #9E305B;
    min-height: 500px;
}

.no-of-assets {
    font-size: 48px;
    line-height: 56px;
}

#assets-warehouse {
    box-sizing: border-box;
    background: radial-gradient(50% 50% at 50% 50%, #9E305B 0%, #932D55 100%);
    border: 1px solid #D7DCEA;
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

#assets-pending-delivery {
    box-sizing: border-box;

    background: #3A7CA5;
    border: 1px solid #B8E6BF;
    border-radius: 0px 10px 10px 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

#orders-made {
    box-sizing: border-box;
    background: #388697;
    border: 1px solid #FFFFFF;
    border-radius: 0px 10px 10px 10px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

#assets-delivered {
    box-sizing: border-box;
    background: #16425B;
    border: 1px solid #FFFFFF;
    border-radius: 0px 10px 10px 10px;
    flex: none;
    order: 3;
    flex-grow: 0;
}</style>
