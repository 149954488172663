import ScheduleDashboard from "./views/ScheduleDashboard.vue";
import Landing from "./views/Landing.vue";
import path from "path";

const routes = [
    {
        path: "",
        component: Landing,
        name: "Schedule",
    },
    {
        path: "dashboard",
        component: ScheduleDashboard,
        name: "ScheduleDashboard",
    },
    {
        path: "teams",
        component: () => import("./views/Teams.vue"),
        name: "ScheduleTeams",
    },
    {
        path: "overview",
        component: () => import("./views/Overview.vue"),
        name: "ScheduleOverview"
    }
];

export default routes;
