
<template>
    <v-layout>

        <div class="mx-2 my-2" style="background-color: #FAF8F5;max-width:450px;">
            <div class="px-2 py-2 rounded" style="background-color: #E5E5E5;">
                <div class="searchBar mt-3 pl-2">
                    <v-autocomplete variant="solo" append-inner-icon="mdi-magnify" id="ticket-search"
                        v-model="selectedStatus" label="Sort By Ticket Type" :items="ticketStatuses" >
                    </v-autocomplete>


                    <v-dialog v-model="sortDialog" width="500">
                        <v-card elevation="6" v-if="sortDialog" class="mx-auto">
                            <v-card-title>Sort Tickets By</v-card-title>
                            <v-divider></v-divider>
                            <v-list>
                                <v-list-item-group v-model="sortFilter">

                                    <v-list-item class="hoverable-list-item" v-for="(item, i) in sortingOptions" :key="i"
                                        @click="sortByFilter(item)">

                                        {{ item }}
                                    </v-list-item>

                                </v-list-item-group>
                            </v-list>
                        </v-card>
                    </v-dialog>

                </div>
                <v-row class="justify-end mr-3">



                    <p class="float-right"><v-icon @click="sortDialog = true" class="ml-15" icon="mdi-sort">

                        </v-icon></p>

                </v-row>
                <v-row>
                    <v-data-table :sort-by='sortBy'
                    :headers="tableHeaders" :items="allocatedTickets"
                        @click:row="show"
                        :search="selectedStatus" :class="{ 'selected-ticket': selectedTicket }" 
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus'
                        }">


                        <template  v-slot:[`item.id`]="{ item }">
                            
                            <v-row no-gutters class="mt-2">
                                <v-col>#{{ item.raw.id }}</v-col>
                                <v-col>
                                    
                                    <p class="float-right">{{ formatDate(item.raw.date_created) }}</p>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mb-2">
                                <v-col cols="8">
                                    <p class="text-subtitle-1">{{ item.raw.ticket_type__name }}</p>
                                </v-col>
                                <v-col>
                                    <p class="float-right"><v-icon color="error"
                                            v-if="item.raw.due_time_sla">mdi-alert-circle</v-icon>
                                        <v-icon color="yellow"
                                            v-if="item.raw.first_response_sla">mdi-alert-circle</v-icon>
                                        <v-icon color="grey"
                                            v-if="item.raw.next_response_sla">mdi-alert-circle</v-icon>
                                    </p>
                                </v-col>



                            </v-row>
                        </template>

                        <template v-slot:no-data>
                            No allocated tickets available.
                        </template>
                    </v-data-table>
                </v-row>

                <!-- <v-list active-class="selected-ticket" style="background-color: #E5E5E5;">
                    <v-list-item style="background-color: #E5E5E5;" :active="ticket.id == selectedTicket"
                        class="justify-space-between" :title="'#' + ticket.id" id="ticket-item"
                        v-for="(ticket, index) in allocatedTickets" :key="index" @click="selectedTicket = ticket.id">
                        <template v-slot:append>
                            {{ formatDate(ticket.date_created) }}
                        </template>

                        <template v-slot:subtitle>
                            {{ ticket.ticket_type__name }}
                        </template>
                    </v-list-item>
                </v-list> -->
            </div>

        </div>
        <v-main style="background-color: #FAF8F5;">
            <!-- <div class="d-flex justify-start align-start mt-1 pl-5">
                <v-btn class="bg-bluePaint" size="x-small" @click.stop="drawer = !drawer">
                    Tickets
                </v-btn>
            </div> -->
         
            <TicketContent v-if="selectedTicket" :ticketId="selectedTicket" :searched-ticket="searchedTicket" />
        </v-main>
        <v-row justify="center">

        </v-row>
    </v-layout>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios'
import TicketContent from '@/components/TicketContent.vue'
import { useMainStore } from '@/stores/main'
import { ref, watch } from 'vue'

interface AllocatedTickets {
    id: number;
    closed: boolean;
    description: string;
    ticket_type__name: string;
    date_created: string;
    ticket_state: null | string; // Replace 'null' with a more specific type if needed
    date_last_touched: string;
    due_by_time: string;
    first_response_due: null | string; // Replace 'null' with a more specific type if needed
    due_time_sla: boolean;
    next_response_sla: boolean;
    first_response_sla: boolean;
    [key: string]: any
}

export default defineComponent({
    components: {
        TicketContent

    },
    data: () => ({
        selectedTicket:0,
        selectedStatus: "Open",
        searchedTicket: undefined,
        allocatedTickets: [] as AllocatedTickets[],
        allocatedTicketsIDs: [] as any[],
        persistingTicket: 0,
        drawer: true as boolean,
        sortingOptions: [
            'Date Created',
            'Due by Time',
            'Status',
            'First Response Due',
            'Next Response Due'
        ],
        sortBy: [] as any,
        sortFilter: "Date Created",
        sortDialog: false,
        tableHeaders: [
            {
                title: 'Status',
                key: 'agent_ticket_status',
                sortable: true,
                align: 'left',

            },
            {
                title: 'Ticket',
                key: 'id',
                sortable: false,
                align: 'left',
                colspan: 2,

            },
            /*  {
                 title: 'Ticket Type',
                 key: 'ticket_type__name',
                 sortable: true,
                 align: 'left',
             },
              */

        ] as any,
        ticketStatuses: ['', 'Open', 'Pending', 'Resolved', 'Waiting on Customer',
            'Waiting on 3rd Party', 'Awaiting Activation']





    }),

    methods: {
        async getTickets() {
            let vm = this;
            let response = await axios
                .get("allocated-tickets/")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.allocatedTickets = response.data.allocated_tickets;
                        vm.allocatedTicketsIDs = vm.allocatedTickets.map(ticket => ticket.id)
                        vm.selectedTicket = vm.allocatedTicketsIDs[0]

                    }
                })
                .catch((error: string) => {
                    if (error) {

                    }
                })

        },
        formatDate(date: string): string {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", { day: 'numeric', month: 'short' });
        },
        getSelectTicket(value:any) {
            
            this.selectedTicket = value;
        },
        isValidDate(dateString: string) {
            return !isNaN(Date.parse(dateString));
        },

        sortByFilter(item: string) {
            let sortKeys = {
                'Date Created': 'date_created',
                'Due by Time': 'due_by_time',
                'Status': 'status',
                'First Response Due': 'first_response_due',
                'Next Response Due': 'date_last_touched',

            } as any
            let filter = item
            this.sortFilter = item
            this.sortBy = [{ key: sortKeys[filter], order: 'asc' }]
            this.sortDialog = false
        },
        show(event: any, value: any) {
            const clickedItem = value.item.props.title.id;
            this.selectedTicket = clickedItem
            
            

        },

    },
  

  
    watch: {
        // whenever question changes, this function will run
        selectedTicket(newTicket, old) {

            if (newTicket) {
                sessionStorage.clear()
                sessionStorage.setItem("persistingTicket", newTicket);
                let persistingTicket = localStorage.getItem("persistingTicket") as unknown as number;
                
            }
            if (old) {
                
            }

        },
    },
    mounted() {
        
        try {
            let persistingTicket = sessionStorage.getItem("persistingTicket") as unknown as number;
            if (persistingTicket) {
                this.selectedTicket = persistingTicket
            }

        }
        catch {
            
        }

        this.getTickets()
        

    }
})

</script>

<style scope>
.searchBar {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#searchQueryInput {
    width: 100%;
    height: 2.3rem;
    background: #f5f5f5;
    outline: auto;
    border: none;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
}

#searchQuerySubmit {
    width: 3.5rem;
    height: 2.8rem;
    margin-left: -3.5rem;
    background: none;
    border: none;
    outline: none;
}

#searchQuerySubmit:hover {
    cursor: pointer;
}

#ticket-list {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    position: absolute;
    width: 232px;
    height: 967px;
    left: 48px;
    top: 56px;
}



.selected-ticket {
    background-color: #0E223D !important;
    color: #FFFFFF;
    border-radius: 0 5px 5px 0;
}


#ticket-item:hover {
    background-color: #385196;
    color: #FFFFFF;
}

#ticket-item {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px;
    gap: 10px;
    width: 256px;
    height: 48px;
    background: #FAFAFA;
    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0
}

.hoverable-list-item:hover {
    background-color: #e0e0e0;
    /* Change background color on hover */
    cursor: pointer;
    /* Change cursor to pointer on hover */
}

.v-data-table>.v-data-table__wrapper>table {
    padding: 2px;
}
</style>
