import { defineStore } from 'pinia'
import axios from 'axios';


export const useMainStore = defineStore("main", {
    state: () => ({
        selectedTicket: 0,
        agentData: {
            onBreak: false
        },
        shiftStatus: null as any,
        userInfo: null as any,
        selectedApp: null as any,
        hideMainNav: false,
    }),
    actions: {
        setHideMainNav(hideMainNav: boolean) {
            this.hideMainNav = hideMainNav;
        },
        setApp(selectedApp: any) {
            this.selectedApp = selectedApp;
        },
        setOnBreak(onBreak: boolean) {
            this.agentData.onBreak = onBreak;
        },
          async getShiftStatus() {
            try {
              const response = await axios.get('shift/status/');
              if (response.status === 200) {
                this.shiftStatus = response.data;
              }
            } catch (error) {
              console.error('Error getting shift status:', error);
            }
          },
      
          async getUserInfo() {
            try {
              const response = await axios.get('user/info/');
              if (response.status === 200) {
                this.userInfo = response.data;
              }
            } catch (error) {
              console.error('Error getting user info:', error);
            }
          },
      
          async fetchAllData() {
            await this.getUserInfo();
            await this.getShiftStatus();
          },
    }
})
 

