<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios'

interface Suppliers {
    id: number
    name: string
}
interface SupplierProduct {
    id: number
    name: string
}


export default defineComponent({
    data: () => ({
        selectedSupplier: "",
        supplier_id: 0,
        supplierList: [] as Suppliers[],
        supplierProducts: [] as SupplierProduct[],
        supplier_product_id: 0,
        candidateItems: [] as any[],
        selectedProduct: "",
        dialog: false,
        quantity: 1,
        unitPrice: 1,
        amount: 0,
        candidate: "",
        purpose: "",
        refNumber: "",
        supplierProductNames: [] as string[],
        supplierNames: [] as string[],
        subtotal: 0,
        discount: 0,
        vat: 0,
        total: 0,
        vatRate: 0.15,
        snackbarResponse: "",
        snackbarType: "error",
        openSnackBar: false,
        loading: true,
        totalQuantity: 0,
        totalDiscount: 0,
        totalVat:0

    }),
    methods: {
        async supplierInvoice() {
            let vm = this
            vm.loading = true
            let response = await axios
                .get("supplier-product")
                .then(function (response) {
                    if (response.status === 200) {
                        vm.supplierList = response.data.supplier_invoiceList
                        vm.supplierProducts = response.data.supplier_products
                        vm.getSupplierNames()

                    }
                })
                .catch((error: any) => {
                    if (error) {

                    }
                })
            vm.loading = false

        },
        async addOrderInvoice() {
            let vm = this
            vm.loading = true
            try {
                const response = await axios
                    .post("add-order-invoice", {
                        supplier_id: this.supplier_id,
                        invoice_items: this.candidateItems,
                        supplier: this.selectedSupplier,
                        invoice_number: this.refNumber,
                        purpose: this.purpose,
                        name: this.selectedProduct,

                        unit_price: this.unitPrice,
                        amount: this.amount,
                        subtotal: this.subtotal,
                        discount: this.totalDiscount,
                        vat: this.totalVat,
                        total: this.totalVat + this.subtotal + - this.discount,


                    }
                    )
                if (response.status === 200) {
                    this.candidateItems = response.data
                    vm.snackbarResponse = "Successfully added invoice"
                    vm.snackbarType = "success"
                }
            }
            catch (error) {
                vm.snackbarResponse = "Something went wrong with uploading the invoice"
            }
            finally {

                vm.loading = false
                vm.showSnackbar()
            }
            // finally{
            //     this.resetItems()
            // }

        },

        getSupplierProductNames() {
            this.supplierProducts.forEach(element => {

                this.supplierProductNames.push(element.name)

            });


        },
        getSupplierNames() {

            this.supplierList.forEach(element => {

                this.supplierNames.push(element.name)

            });


        },
        calculateAmount() {
            this.amount = this.quantity * this.unitPrice

        },
        addItems() {
            this.candidateItems.push({
                "supplier_product_id": this.supplier_product_id,
                "name": this.selectedProduct,
                "quantity": this.quantity,
                "unit_price": this.unitPrice,
                "amount": this.amount
            })
            this.subtotal += this.amount
            this.totalDiscount += parseFloat(String(this.discount))
            console.log("this is the vat", this.vat)
            this.totalVat = parseFloat(String(this.vat)) + this.totalVat
            this.total = this.subtotal + this.totalVat - parseFloat(String(this.discount))
            console.log(this.vat, this.totalVat, this.subtotal, this.discount)
            console.log(typeof(this.vat),typeof(this.totalVat),typeof(this.subtotal), typeof(this.discount))
            this.quantity = 0
            this.unitPrice = 0
            this.amount = 0
            this.discount = 0
            this.vat = 0

        },
        addToTable() {

            if (this.candidateItems.includes(this.candidate)) {

                this.snackbarResponse = `${this.candidate} has already been added`
                this.snackbarType = "error";
                this.showSnackbar()
            }
            else {
                this.candidateItems.push(this.candidate)
            }
            this.candidate = ""
        },


        openDialog() {
            this.dialog = true
            this.getSupplierProductNames()
        },
        closeDialog() {
            this.dialog = false;
        },

        showSnackbar(): void {
            setTimeout(() => {
                this.openSnackBar = true;
            }, 3000);
        }

    },
    watch: {
        selectedProduct: function (productName) {
            const product = this.supplierProducts.find((p) => p.name === productName)
            if (product) {
                this.supplier_product_id = product.id


            }
        },
        selectedSupplier: function (supplierName) {
            const supplier = this.supplierList.find((p) => p.name === supplierName)
            if (supplier) {
                this.supplier_id = supplier.id
            }
        }

    },
    created() {
        this.supplierInvoice();

    },
  
})
</script>
<template>
    <v-container>
        <v-row>
            {{ totalVat }}
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="mx-auto">
                <h2 class="text-center mt-2 mb-6 pb-4">Add Order</h2>
                <v-form ref="form" lazy-validation valid="valid">
                    <v-select variant="outlined" v-model="selectedSupplier" :items="supplierNames"
                        :rules="[v => !!v || 'Item is required']" label="Select supplier" required>
                    </v-select>


                    <v-text-field variant="outlined" v-model="refNumber" :rules="[v => !!v || v || 'Item is required']"
                        label="Quote or Invoice Ref" required></v-text-field>
                    <v-textarea v-model="purpose" variant="outlined">

                    </v-textarea>


                    <v-btn class="bg-navigationDrawer" @click="addOrderInvoice()">Submit Invoice</v-btn>


                    <v-btn class="bg-navigationDrawer float-right " @click="openDialog()">Add Items</v-btn>

                    <v-table v-if="candidateItems.length > 0">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Product Name
                                </th>

                                <th class="text-left">
                                    Quantity
                                </th>
                                <th class="text-left">
                                    Unit Price
                                </th>
                                <th class="text-left">
                                    Amount
                                </th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="candidate in candidateItems">
                                <td>{{ candidate.name }}</td>
                                <td>{{ candidate.quantity }} </td>
                                <td>{{ candidate.unitPrice }}</td>
                                <td>{{ candidate.amount }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-table v-if="candidateItems.length > 0" class="mt-10 pt-10">
                        <tfoot class="pt-3 mt-3 pa-3">
                            <tr>
                                <td colspan="1">Subtotal</td>

                                <td>
                                    R{{ subtotal }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">Discount</td>
                                <td>
                                    R{{ totalDiscount }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="1">VAT</td>
                                <td>R{{ totalVat }}</td>
                            </tr>
                            <tr>
                                <td colspan="1">Total</td>

                                <td>R{{ total }}</td>
                            </tr>
                        </tfoot>
                    </v-table>

                </v-form>
            </v-col>
            <div class="text-center">
                <v-snackbar multi-line v-model="openSnackBar" timeout="4500" :color="snackbarType"
                    transition="dialog-bottom-transition">
                    <span class="text-center font-weight-bold ">{{ snackbarResponse }}</span>
                    <template v-slot:actions>
                        <v-btn class="text-black" variant="text" @click="openSnackBar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-row>
        <v-row>
            <v-dialog v-model="dialog" ref="dialog" persistent>
                <v-card density="compact" outlined max-width="950" justify="center">
                    <v-card-title>
                        <v-col class="text-center">
                            <span class="text-h5">Line Items</span>
                        </v-col>

                    </v-card-title>
                    <v-card-text>

                    </v-card-text>
                    <v-card-text>
                        <label for="Product Name">Product Name</label>
                        <v-select class="pt-2" v-model="selectedProduct" :items="supplierProductNames">
                        </v-select>
                        <v-table>
                            <thead>
                                <tr>
                                    <th>
                                        Quantity
                                    </th>
                                    <th>
                                        Unit price
                                    </th>
                                    <th>
                                        VAT
                                    </th>
                                    <th>
                                        Amount
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <v-text-field v-model="quantity" type="number" @keydown.up="quantity++"
                                            @keydown.down="quantity--" @change="calculateAmount()">

                                        </v-text-field>
                                    </td>

                                    <td>

                                        <v-text-field v-model="unitPrice" type="number" @keydown.up="unitPrice++"
                                            @keydown.down="unitPrice--" @change="calculateAmount()">

                                        </v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="vat">

                                        </v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="amount">

                                        </v-text-field>
                                    </td>
                                </tr>
                            </tbody>


                        </v-table>
                        <v-table>
                            <thead>
                                <tr>

                                    <th>
                                        Discount
                                    </th>

                                    <th>
                                        Total
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>

                                        <v-text-field v-model="discount" type="number" @keydown.up="discount++"
                                            @keydown.down="discount--">
                                        </v-text-field>
                                    </td>


                                    <td>
                                        <v-text-field v-model="total">

                                        </v-text-field>
                                    </td>
                                </tr>
                            </tbody>


                        </v-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-col>
                            <v-btn class="bg-navigationDrawer" @click="closeDialog()">Done</v-btn>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col>
                            <v-btn class="bg-navigationDrawer" @click="addItems()">Save</v-btn>

                        </v-col>


                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row no-gutters class="position-relative">
            <div class="text-center">
                <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="3000"
                    transition="dialog-bottom-transition">
                    <span>{{ snackbarResponse }}</span>
                    <template v-slot:actions>
                        <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-row>
        <LoadingOverlay v-if="loading" />
    </v-container>
</template>