<script setup lang="ts">
    import AssignedCallContent from '@/components/AssignedCallContent.vue'
</script>


<template>
    <v-layout>
        <div class="mx-2 my-2">
            <div class="px-2 py-2 rounded">
                <v-list active-class="selected-ticket">
                    <template v-for="(call, index) in allocatedCalls" :key="index">
                        <v-list-item :active="call === selectedCall" class="justify-space-between" :title="call.title"
                            :id="'call-list-' + call.id" 
                            @click="selectedCall = call;">
                            <template v-slot:default>
                                <template v-if="call.call_type === 'QUALIFYING CALL'">
                                    <v-chip color="info" size="small">{{ call.call_type }}</v-chip>
                                </template>
                                <template v-else-if="call.call_type === 'CLOSING CALL'">
                                    <v-chip color="success"  size="small">{{ call.call_type }}</v-chip>
                                </template>
                            </template>
                        </v-list-item>
                        <v-divider class="my-2"></v-divider>
                    </template>
                </v-list>
            </div>
        </div>
        <v-main>
            <AssignedCallContent v-if="selectedCall" :selectedCall="selectedCall" />
        </v-main>
    </v-layout>
</template>
  
<script lang="ts">

import { defineComponent } from 'vue';
import axios from 'axios'

export default defineComponent({
    data: () => ({
        selectedCall: null as any,
        searchedTicket: undefined,
        allocatedCalls: [] as any,
        allocatedTicketsIDs: [],
        persistingTicket: 0,
        drawer: true as boolean
    }),
    methods: {
        async getCallList() {
            try {
                // Make the API call to get the call list
                const response = await axios.get('/call/list/');

                // Extract the "calls" array from the API response data
                const callsArray = response.data.calls;

                // Update the allocatedCalls data with the received data
                this.allocatedCalls = callsArray;
            } catch (error) {
                console.error('Error fetching call list:', error);
            }
        },
    },
    mounted() {
        this.getCallList()
    }
});
</script>
  
<style>
    .selected-ticket {
        background-color: #E4E4E4 !important;
        color: black;
    }
</style>
