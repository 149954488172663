
<template>
    <v-main>
        <router-view v-slot="{ Component, route }">
            <component  :is="Component" />
        </router-view>
    </v-main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMainStore } from "@/stores/main";

export default defineComponent({
    name: "DashboardIndex",
    data: () => ({}),
    methods: {},
    mounted() {
        const mainStore = useMainStore();
        if (window.location.hash === '#live') {
            mainStore.setHideMainNav(true);
        } 
    },
});
</script>
