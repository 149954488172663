<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
                <v-card class="text-center" max-width="344" elevation="10" @click="assign = true, deploy = false, viewRegistry=false">
                    <v-row align="center" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-icon class="display-1" size="40" color="navigationDrawer darken-2"
                                cover>mdi-magnify-scan</v-icon>
                        </v-col>
                    </v-row>
                    <v-card-title>
                        Assign Highsite Inventory
                    </v-card-title>
                    <v-card-subtitle>
                        Assign Highsite Inventory to Technicians, Network Staff
                    </v-card-subtitle>

                </v-card>

            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
                <v-card class="text-center" max-width="344" elevation="10" @click="deploy = true, assign = false,viewRegistry=false">
                    <v-row align="center" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-icon class="display-1" size="40" color="navigationDrawer darken-2"
                                cover>mdi-truck-delivery</v-icon>

                        </v-col>
                    </v-row>

                    <v-card-title>
                        Deploy to Highsites
                    </v-card-title>
                    <v-card-subtitle>
                        Assign Highsites deployed to Highsites
                    </v-card-subtitle>

                </v-card>

            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">


                <v-card class="text-center" max-width="344" elevation="10" @click="getHighsiteRegistry">
                    <v-row align="center" justify="center">
                        <v-col cols="12" class="text-center">
                            <v-icon class="display-1" size="40" color="navigationDrawer darken-2"
                                cover>mdi-file-chart-outline</v-icon>
                        </v-col>
                    </v-row>

                    <v-card-title>
                        View Highsite Assets
                    </v-card-title>
                    <v-card-subtitle>
                        See which assets are assigned to a highsite
                    </v-card-subtitle>

                </v-card>

            </v-col>
        </v-row>
        <v-row v-if="assign && !deploy && !viewRegistry " >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                <asset-lists :headers="assignHeaders" :list-name="'Assign Assets'" :list-items="highsiteList"
                    action="assign" assignee="user" />
            </v-col>
        </v-row>
        <v-row v-if="deploy && !assign && !viewRegistry ">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
                <asset-lists :headers="deployHeaders" :list-name="'Deploy Assets'" :list-items="highsiteList"
                    action="deploy" assignee="highsite" />
            </v-col>
        </v-row>

        <v-row class="mt-10" v-if="viewRegistry && !assign && !deploy">
            <v-col>
                <h1 class="text-center text-h4">Highsite Registry</h1>
                <v-data-table :headers="headers" :items="assetHighsites"  class="table-responsive">
                    <template v-slot:[`item.assets`]="{ value }" >
                        <p  v-for="(key, x ) in value.raw.assets" :key="x" >{{ x }}:{{ key}}</p>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>


        <!-- <script>
export default {
  data() {
    return {
      headers: [
        { text: "Highsite", value: "prefix" },
        { text: "Asset Types", value: "assets" },
        { text: "Total Assets", value: "total_assets" },
      ],
      assetHighsites: [], // Populate this array with your data
    };
  },
};
</script> -->

        <LoadingOverlay v-if="loading" />
    </v-container>
</template>
    
<script lang="ts">
import { defineComponent } from 'vue';
import axiosWarehouseInstance from '../../../stores/warehouse_store';
import Vue from 'vue'
import AssetLists from '../components/AssetLists.vue'
interface Highsite {
    id: string
    product__name: string
    supplier__name: string
    manufacturer: string
    purchase_price: number
    stock: number
    lost: number
    deployed: number
    assigned: number
};

export default defineComponent({
    name: 'HighsiteList',
    components: {
        AssetLists
    },
    data: () => ({
        highsiteList: [] as Highsite[],
        loading: true,
        assignHeaders: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'stock', title: 'Stock' },


        ],
        deployHeaders: [
            {
                align: 'start',
                key: 'product__name',
                sortable: false,
                title: 'Name',
            },
            { key: 'assigned', title: 'Assigned' },


        ],
        headers: [
        { title: "ID", key: "id" },
            { title: "Highsite", key: "name" },
            { title: "Asset Types", key: "assets" },
            { title: "Total Assets", key: "total_assets" },
        ] as any,
        assetHighsites: [] as any,
        //

        assign: false,
        deploy: false,
        viewRegistry: false
    }),

    methods: {
        async getHighsiteList(): Promise<void> {
            let vm = this

            try {
                const response = await
                    axiosWarehouseInstance.get('highsite/list');
                vm.highsiteList = response.data.highsite
            }

            catch (error) {
                console.error(error);
                //throw error
            }

            finally {
                this.loading = false
            }

        },
        async getHighsiteRegistry(): Promise<void> {
            let vm = this
            vm.loading=true
            try {
                const response = await
                    axiosWarehouseInstance.get('highsite-registry/');
                vm.assetHighsites= response.data.highsite_registry
            }

            catch (error) {
                console.error(error);
                //throw error
            }

            finally {
                vm.viewRegistry=true, 
                vm.deploy=false,
                vm.assign=false
                this.loading = false
            }

        },
    },
    created() {
        this.getHighsiteList()
    }

})

</script>
    
<style></style>