<template>
    <v-container>
        <v-row no-gutters>
            <h2>{{ listName }}</h2>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <div class="float-right">
                    <input v-model="search" type="search" id="default-search"
                        class=" bg-white block w-full pb-1 pt-1 pl-12 pr-12 text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search Product" required>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>

                <v-data-table :headers="headers" :items="listItems" :search="search" @click:row="show">
                    <template v-slot:[`item.date_created`]="{ value }">
                        {{ formatDate(value) }}
                    </template>
                    <template v-slot:[`item.assigned`]="{ value }">
                        {{ value < 0 ? 0 : value }} </template>
                            <template v-slot:[`item.deployed`]="{ value }">

                                {{ value.raw.deployed == 0 ? value.raw.not_in_warehouse : value.raw.deployed }}
                            </template>
                            <template v-slot:[`item.dispatched`]="{ value }">

                                {{ value.raw.deployed == 0 ? 0 : value.raw.dispatched }}
                            </template>
                            <template v-slot:[`item.weeks_remaining`]="{ value }">
                                {{ weeksToWeeksAndDays(value.props.title.weeks_remaining) }}
                            </template>
                </v-data-table>
            </v-col>
        </v-row>


        <assign-assets :updateDialogOption="updateDialogOption" :assign-dialog="assignDialog"
            :selected-product-id="selectedProductId" :selected-product="selectedProduct"
            :assignable-assets="assignableAssets" :users="users" :assignee="assignee" />
    </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import AssignAssets from './AssignAssets.vue'
import axios from 'axios'
export default defineComponent({
    components: {
       
        AssignAssets
    },
    props: {
        headers: {
            type: [] as any[],
            required: true,
            
        },
        listName: {
            type: String as PropType<string>,
            required: true
        },
        listItems: {
            type: [] as any[],
            required: true
        },
        action: {
            type: String as PropType<string>,
            required: false
        },
        assignee: {
            type: String as PropType<string>,
            required: false
        },

    },

    data() {
        return {
            listName: this.listName,
            search: "",
            assignDialog: false,
            selectedProductId: 0,
            selectedProduct: "",
            assignDetails: [] as any,
            assignableAssets: [] as any,
            assignedAssets: [] as any,
            users: [] as string[]
        }
    },
    methods: {
        async getAvailableAssets(): Promise<void> {
            let vm = this

            console.log("trying to get availabe assets with product id", vm.selectedProductId)
            try {
                let response = await
                    axios.post("assignable-assets", { product_id: vm.selectedProductId })
                if (response.status == 200) {
                    vm.assignableAssets = response.data.assets
                    vm.users = response.data.users
                }
            }
            catch (error: any) {
                if (error.response) {
                    if (error.response.status === 404) {

                    }
                }
            }
            finally {

            }
        },
        async getAssignedAssets(): Promise<void> {
            let vm = this
            try {
                let response = await
                    axios.post(`assigned-assets/?product_id=${vm.selectedProductId}`,)
                if (response.status == 200) {
                    vm.assignableAssets = response.data.assets
                    vm.users = response.data.users
                }
            }
            catch (error: any) {
                if (error.response) {
                    if (error.response.status === 404) {

                    }
                }
            }
            finally {

            }
        },
        formatDate(date: string) {
            let formartedDate = new Date(date);
            return formartedDate.toLocaleDateString("en-us", {
                day: "numeric",
                month: "short",
                year: "numeric",
            });
        },
        isValidDate(dateString: string) {
            return !isNaN(Date.parse(dateString));
        },

        show(event: any, value: any) {
            const clickedItem = value.item;
            console.log("these are the headers", clickedItem.value)
            this.selectedProductId = clickedItem.value.product
            this.selectedProduct = clickedItem.value.product__name
            console.log("this is the value", clickedItem.value.product)
            this.assignDialog = true

            if (this.action == "assign") {
                this.getAvailableAssets()
            }
            else this.getAssignedAssets()
        },
        updateDialogOption(newValue: boolean) {
            this.assignDialog = newValue;
        },
        weeksToWeeksAndDays(weeks: number) :string {
            var weeksInt = Math.floor(weeks);
            var days = Math.round((weeks - weeksInt) * 7);
            
            return `${weeksInt} week(s) and  ${days} day(s)`
        }

    }

})
</script>