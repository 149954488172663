<template>
    <v-container>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="">
       
        <h2>Stock Take</h2>
        <v-data-table :headers="headers" :items="inventory">
            <template v-slot:[`item.physical_count`]="{ value }">
                <v-text-field class="mt-3" v-model="value.physical_count" @change="updateStockCount(value, $event)"></v-text-field>
            </template>
            <template v-slot:[`item.received`]="{ value }">

                {{ value.props.title.received }}
            </template>

        </v-data-table>
        <v-btn  @click="submitStockTake()" class="bg-navigationDrawer float-right"
                                density="compact">Submit</v-btn>
    </v-col>
    <LoadingOverlay v-if="loading" />
</v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';


import axiosWarehouseInstance from '../../../stores/warehouse_store';

export default defineComponent({
    
    props: {
        headers: {
            type: [] as any[],
            required: true,
        },
    },

    data: () => {
        return {
            headers: [
                {
                    align: 'start',
                    key: 'product__name',
                    sortable: false,
                    title: 'Name',
                },
                { key: 'count', title: 'Available' },
                { key: 'physical_count', title: 'Physical Count' },

            ],
            inventory: [],
            loading: false,
            stockCounts: [] as any,
            physicalCounts: {} as any,
            stockTake:[] as {}[]
        }
    },
    methods: {
        async getStockTakeItems() {
            let vm = this

            vm.loading = true
            const response = await axiosWarehouseInstance.get('stock-take/products').then(
                function (response) {
                    if (response.status = 200) {
                        vm.inventory = response.data.stock_take_items
                        console.log(response.data.low_inventory_stock)
                    }

                }
            ).catch((error) => {

            })
            vm.loading = false
        },
        updateStockCount(item:any,event:any) {
            console.log(item)
            console.log(item.value.id)
            console.log(item.physical_count)
            this.stockTake.push({"product":item.value.product,"current_count":item.value.count,"physical_count":item.physical_count})
            
        },
        getStockCount(index:number) {
            return this.stockCounts[index] || '';
        },

        async submitStockTake(){
            let vm = this
            vm.loading = true
            const response  = await axiosWarehouseInstance
            .post(`stock-take/submit/`,{stock_take_items:this.stockTake})
            .then(
                function(response){
                    if (response.status==200){

                    }
                }
            )
            .catch((error)=>{

            })
            vm.loading=false
        }
    },

    mounted() {
        this.getStockTakeItems()
    }
})

</script>