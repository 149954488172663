<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="ml-2">

        <v-autocomplete v-model="returnType" :items="['Decommissioned', 'Scrapping']" variant="solo" label="Return"
          required></v-autocomplete>
        <card v-if="returnType == 'Decommissioned'">

          <v-select variant="outlined" v-model="product" :items="products" item-title="name" label="Product"></v-select>
          <v-select v-if="!(nonIdentifiableProducts.includes(product))" variant="outlined" v-model="identifier_type"
            :items="['mac', 'serial']" label="Identifier Type"></v-select>
          <v-text-field @change="checkValue" v-if="!(nonIdentifiableProducts.includes(product))" variant="outlined"
            v-model="identifier" label="Identifiers"></v-text-field>
          <v-text-field v-if="(nonIdentifiableProducts.includes(product))" variant="outlined" v-model="count"
            label="No of items"></v-text-field>


          <v-btn class=" bg-navigationDrawer">
            clear
          </v-btn>
          <v-btn class="me-4 float-right bg-navigationDrawer" @click="returnDecommissionedAssets">
            submit
          </v-btn>

        </card>
        <card v-if="returnType == 'Scrapping'">

          <v-select variant="outlined" v-model="product" :items="products" item-title="name" label="Product"></v-select>
          <v-select v-if="!(nonIdentifiableProducts.includes(product))" variant="outlined" v-model="identifier_type"
            :items="['mac', 'serial']" label="Identifier Type"></v-select>
          <v-text-field @change="checkValue" v-if="!(nonIdentifiableProducts.includes(product))" variant="outlined"
            v-model="identifier" label="Identifiers"></v-text-field>
          <v-text-field v-if="(nonIdentifiableProducts.includes(product))" variant="outlined" v-model="count"
            label="No of items"></v-text-field>


          <v-btn class=" bg-navigationDrawer">
            clear
          </v-btn>
          <v-btn class="me-4 float-right bg-navigationDrawer" @click="returnDecommissionedAssets">
            submit
          </v-btn>

        </card>
        <!-- <form v-if="returnType == 'Staff Return'" @submit.prevent="submit">
          <v-select variant="outlined" v-model="phone.value.value" :counter="7" :error-messages="phone.errorMessage.value"
            label="Product"></v-select>
          <v-text-field variant="outlined" v-model="phone.value.value" :counter="7"
            :error-messages="phone.errorMessage.value"
            label="Quantity Returned (for products with no identifiers)"></v-text-field>

          <v-text-field variant="outlined" v-model="phone.value.value" :counter="7"
            :error-messages="phone.errorMessage.value" label="Identifiers(for products with identifiers)"></v-text-field>

          <v-text-field variant="outlined" v-model="email.value.value" :error-messages="email.errorMessage.value"
            label="Reason for return"></v-text-field>

          <v-btn @click="handleReset">
            clear
          </v-btn>
          <v-btn class="me-4 float-right" type="submit">
            submit
          </v-btn>

        </form>
        <form v-if="returnType == 'Supplier Return'" @submit.prevent="submit">
          <v-autocomplete variant="outlined" v-model="select.value.value" :items="items"
            :error-messages="select.errorMessage.value" label="Select Return"></v-autocomplete>
          <v-text-field variant="outlined" v-model="phone.value.value" :counter="7"
            :error-messages="phone.errorMessage.value" label="Quantity Returned (optional)"></v-text-field>

          <v-text-field variant="outlined" v-model="email.value.value" :error-messages="email.errorMessage.value"
            label="Reason for return"></v-text-field>

          <v-btn @click="handleReset">
            clear
          </v-btn>
          <v-btn class="me-4 float-right" type="submit">
            submit
          </v-btn>

        </form> -->
      </v-col>
      <v-col v-if="scannedIdentifiers.length > 0" cols="12" xs="12" sm="12" md="5" lg="5" xl="5" xxl="5">

        <v-data-table :headers="[{
          align: 'start',
          key: 'identifier',
          sortable: false,
          title: 'Identifier',
        }]" :items="scannedIdentifiers">

        </v-data-table>
        <p class="text-end">Items Scanned:{{ scannedIdentifiers.length }}</p>

      </v-col>
    </v-row>
    <v-row no-gutters class="position-relative">
      <div class="text-center">
        <v-snackbar :color="snackbarType" light="true" multi-line v-model="openSnackBar" timeout="10000"
          transition="dialog-bottom-transition">
          <span>{{ snackbarResponse }}</span>
          <template v-slot:actions>
            <v-btn class="bg-pacificBlue text-white" variant="text" @click="openSnackBar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-row>
    <LoadingOverlay v-if="loading" />
  </v-container>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import axiosWarehouseInstance from '@/stores/warehouse_store';

export default defineComponent({

  data: () => ({
    product: "",
    returnType: "Select Return Type",
    products: [] as any[],
    deviceTypes: [] as string[],
    loading: false,
    nonIdentifiableProducts: [] as string[],
    identifier: "",
    identifier_type: "",
    count: 0,
    additionalNotes: "",
    identifier_list: [] as string[] | null,
    candidateIdentifiers: [] as string[],
    scannedIdentifiers: [] as any[],
    snackbarResponse: "",
    snackbarType: "grey",
    openSnackBar: false,
  }),
  methods: {
    async getProductNames() {
      let vm = this
      try {
        const response = await axiosWarehouseInstance.
          get("product-names")
        this.products = response.data.products
        this.deviceTypes = response.data.device_types
        vm.setNonIdentifiableProducts(this.products)

      }
      catch (error) {

      }
      vm.loading = false
    },
    async returnDecommissionedAssets() {
      const vm = this
      vm.loading = true
      const response = await axiosWarehouseInstance
        .post(`returns/${this.returnType.toLowerCase()}/`, {
          identifiers: vm.candidateIdentifiers,
          count: vm.count,
          identifier_type: vm.identifier_type,
          product: vm.product
        }).then(function (response) {
          vm.snackbarResponse = response.data.message
          vm.snackbarType = 'success'
        }).catch((error: any) => {
          vm.snackbarResponse = error.response.data.message
        })
      vm.showSnackbar()
      vm.loading = false
    },
    async returnScrappedAssets() {
      const vm = this
      vm.loading = true
      const response = await axiosWarehouseInstance
        .post("returns/scrapping/", {
          identifiers: vm.candidateIdentifiers,
          count: vm.count,
          identifier_type: vm.identifier_type,
          product: vm.product
        }).then(function (response) {
          vm.snackbarResponse = response.data.message
          vm.snackbarType = 'success'
        }).catch((error: any) => {
          vm.snackbarResponse = error.response.data.message
        })
      vm.showSnackbar()
      vm.loading = false
    },
    setNonIdentifiableProducts(products: any[]) {
      for (let product of products) {
        if (product.track_by_identifier == false) {
          this.nonIdentifiableProducts.push(product.name)
        }
      }
    },
    checkValue() {
      if (this.candidateIdentifiers.includes(this.identifier)) {
        this.identifier = ""
      }

      else {
        this.scannedIdentifiers.push({
          "identifier": this.identifier
        })
        this.candidateIdentifiers.push(this.identifier)
        this.identifier = ""
      }

    },
    showSnackbar(): void {
      setTimeout(() => {
        this.openSnackBar = true;
      }, 2000);
    }
  },

  mounted() {
    this.getProductNames()
  }
})
</script>
