
<template>
    <v-navigation-drawer expand-on-hover rail app>
        <v-list>

            <v-list-item prepend-icon="mdi-view-dashboard" :to="{ name: 'Schedule' }" class="list-items" value="schedule">
                Dashboard
            </v-list-item>

            <v-list-item prepend-icon="mdi-calendar-account-outline" :to="{ name: 'ScheduleOverview' }" class="list-items" value="scheduleOverview">
                Overview
            </v-list-item>

            <v-list-item prepend-icon="mdi-account-hard-hat" :to="{ name: 'ScheduleTeams' }" class="list-items" value="teams">
                Teams
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

    </v-navigation-drawer>

    <v-main>
        <router-view v-slot="{ Component, route }">
            <component  :is="Component" />
        </router-view>
    </v-main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: "ScheduleIndex",
    data: () => ({}),
    methods: {},
    onMounted() {
        console.log("ScheduleIndex mounted");
    },
});
</script>
