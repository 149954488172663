<template>
    <v-container fluid>
        <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>
        <div v-else>
            <v-row class="mb-3">
                <v-col md="6">
                    <v-row class="mb-3">
                        <v-col md="12">
                            <v-card color="lightgreen" dark>
                                <v-card-title class="text-center">Inbound</v-card-title>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="lightgreen" dark>
                                <v-card-title>Active</v-card-title>
                                <v-card-text class="text-h3 text-center my-8">{{ answeredCalls.length }}</v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="lightyellow" dark>
                                <v-card-title>Ringing</v-card-title>
                                <v-card-text class="text-h3 text-center my-8">{{ tryingCalls.length }}</v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="lightred" dark>
                                <v-card-title>Queued</v-card-title>
                                <v-card-text class="text-h3 text-center my-8">{{ waitingCalls.length }}</v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="lightred" dark>
                                <v-card-title>Available Agents</v-card-title>
                                <v-card-text class="text-h3 text-center my-8">{{
                                    callCenterStatus.available_agents.length }}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col md="6">
                    <v-row class="mb-3">
                        <v-col md="12">
                            <v-card color="lightgreen" dark>
                                <v-card-title class="text-center">Outbound</v-card-title>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card color="lightgreen" dark>
                                <v-card-title>Active</v-card-title>
                                <v-card-text class="text-h3 text-center my-8">{{ outboundCalls.length}}</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <v-row>
                <v-col md="6">
                    <v-row>
                        <v-col md="12">
                            <v-data-table :headers="answeredHeaders" :items="answeredCalls" hide-default-footer
                                class="elevation-1">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Active Calls</v-toolbar-title>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.cid_number`]="{ item }">
                                    {{ zeroPadded(item.cid_number) }}
                                </template>
                                <template v-slot:[`item.joined_epoch`]="{ item }">
                                    {{ item.joined_epoch ? formatDateTime(item.joined_epoch) : 'N/A' }}
                                </template>
                                <template v-slot:[`item.timeWaited`]="{ item }">
                                    {{ timeWaited(item) }}
                                </template>
                                <template v-slot:[`item.timeOnCall`]="{ item }">
                                    {{ timeOnCall(item) }}
                                </template>
                                <template v-slot:bottom></template>
                            </v-data-table>
                        </v-col>
                        <v-col md="12">
                            <v-data-table :headers="tryingHeaders" :items="tryingCalls" hide-default-footer
                                class="elevation-1 mt-4">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Ringing Calls</v-toolbar-title>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.joined_epoch`]="{ item }">
                                    {{ item.joined_epoch ? formatDateTime(item.joined_epoch) : 'N/A' }}
                                </template>
                                <template v-slot:[`item.timeWaited`]="{ item }">
                                    {{ timeWaiting(item) }}
                                </template>
                                <template v-slot:bottom></template>
                            </v-data-table>
                        </v-col>
                        <v-col md="12">
                            <v-data-table :headers="queueHeaders" :items="waitingCalls" hide-default-footer
                                class="elevation-1 mt-4">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Waiting Calls</v-toolbar-title>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.joined_epoch`]="{ item }">
                                    {{ item.joined_epoch ? formatDateTime(item.joined_epoch) : 'N/A' }}
                                </template>
                                <template v-slot:[`item.timeWaited`]="{ item }">
                                    {{ timeWaiting(item) }}
                                </template>
                                <template v-slot:bottom></template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="6">
                    <v-row>
                        <v-col md="12">
                            <v-data-table :headers="outboundHeaders" :items="outboundCalls" hide-default-footer
                                class="elevation-1">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title>Outbound Calls</v-toolbar-title>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.timeOnCallOutbound`]="{ item }">
                                    {{ timeOnCallOutbound(item) }}
                                </template>
                                <template v-slot:bottom></template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col md="6">

                    <v-row>
                        <v-col cols="12">
                            <p class="text-h6">Available Agents</p>
                        </v-col>
                        <v-col cols="3" v-for="agent in callCenterStatus.available_agents">
                            <v-card :class="{'bg-red' : !agent.registered, 'text-center': true}">
                                <v-avatar class="mt-2" v-if="agent.img" :image="agent.img"></v-avatar>
                                <v-avatar class="mt-2" v-else :image="imageUrl"></v-avatar>
                                <v-card-title>
                                    <span class="headline">{{ agent.name }}</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col md="6">

                    <v-row>
                        <v-col cols="12">
                            <p class="text-h6">Agents On Break</p>
                        </v-col>
                        <v-col cols="3" v-for="agent in callCenterStatus.agents_on_break">
                            <v-card :class="{'text-center': true}">
                                <v-avatar class="mt-2" v-if="agent.img" :image="agent.img"></v-avatar>
                                <v-avatar class="mt-2" v-else :image="imageUrl"></v-avatar>
                                <v-card-title>
                                    <span class="headline">{{ agent.name }}</span>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
  
<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';

import image from '@/assets/img/user.jpg' // => or relative path

interface QueueMember {
    status: string;
    cid_number: string;
    serving_agent: string;
    agent_name: string;
    joined_epoch: number;
    answer_epoch: number;
}

interface Call {
    uuid: string;
    direction: string;
    cid_name: string;
    cid_num: string;
    agent_name: string;
    created_epoch: number;
    dest: string;
    callstate: string;
}

interface AvailableAgent {
    name: string;
    status: string;
    img: string;
    registered: boolean;
}


interface AgentOnBreak {
    name: string;
    status: string;
    img: string;
    time_since: number;
}

interface CallCenterStatus {
    queue_members: QueueMember[];
    available_agents: AvailableAgent[];
    agents_on_break: AgentOnBreak[];
    inbound_calls: Call[];
    outbound_calls: Call[];
}

interface ManagerDashboard {
    callCenterStatus: CallCenterStatus;
    isLoading: boolean;
    answeredHeaders: any;
    tryingHeaders: any;
    outboundHeaders: any;
    queueHeaders: any;
    timeout: any;
    currentTime: any;
    imageUrl: any;
}


export default defineComponent({

    data: (): ManagerDashboard => ({
        callCenterStatus: { 
            queue_members: [], 
            available_agents: [],
            inbound_calls: [],
            outbound_calls: [],
            agents_on_break: []
        },
        isLoading: true,
        answeredHeaders: [
            { title: 'Number', key: 'cid_number' },
            { title: 'Agent Name', key: 'agent_name' },
            { title: 'Duration', key: 'timeOnCall' }
        ],
        tryingHeaders: [
            { title: 'Number', key: 'cid_number' },
            { title: 'Agent Name', key: 'agent_name' },
            { title: 'Waiting Time', key: 'timeWaited' },
        ],
        queueHeaders: [
            { title: 'Number', key: 'cid_number' },
            { title: 'Time Joined', key: 'joined_epoch' },
            { title: 'Waiting Time', key: 'timeWaited' },
        ],
        outboundHeaders: [
            { title: 'Number', key: 'dest' },
            { title: 'Agent', key: 'agent_name' },
            { title: 'Duration', key: 'timeOnCallOutbound' },
        ],
        timeout: null,
        currentTime: null,
        imageUrl: image
    }),
    methods: {
        getCallCenterStatus() {
            axios.get('/call/center/status/')
                .then(response => {
                    this.callCenterStatus = response.data;
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        formatDateTime(epoch: number) {
            if (epoch) {
                const date = new Date(epoch * 1000);
                return `${date.toLocaleTimeString('en-US')}`;
            } else {
                return 'N/A';
            }
        },
        timeWaited(item: QueueMember) {
            if (item.answer_epoch && item.joined_epoch) {
                return item.answer_epoch - item.joined_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeOnCall(item: QueueMember) {
            if (item.answer_epoch) {
                return Math.floor(this.currentTime / 1000) - item.answer_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeWaiting(item: QueueMember) {
            if (item.joined_epoch) {
                return Math.floor(this.currentTime / 1000) - item.joined_epoch;
            }
            else {
                return 'N/A';
            }
        },
        timeOnCallOutbound(item: Call) {
            if (item.created_epoch) {
                return Math.floor(this.currentTime / 1000) - item.created_epoch;
            }
            else {
                return 'N/A';
            }
        },
        zeroPadded(num: string) {
            
            if (num.startsWith("0")) {
                return num
            }
            if (num.startsWith("+")) {
                return "0" + num.slice(3);
            }
            return "0" + num.slice(2);
        },
    },
    computed: {
        answeredCalls() {
            return this.callCenterStatus.queue_members.filter(member => member.status === "Answered");
        },
        tryingCalls() {
            return this.callCenterStatus.queue_members.filter(member => member.status === "Trying");
        },
        waitingCalls() {
            return this.callCenterStatus.queue_members.filter(member => member.status === "Waiting");
        },
        outboundCalls() {
            return this.callCenterStatus.outbound_calls.filter(call => call.callstate === "ACTIVE");
        }
    },
    mounted() {
        this.getCallCenterStatus();
        if (!this.timeout) {
            this.timeout = setInterval(this.getCallCenterStatus, 5000);
        }

        setInterval(() => {
            this.currentTime = Date.now();
        }, 1000);

    }
})



</script>
  