<template>
    <v-container>
    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="" @click="goToTransfers()">
        <v-card class="" max-width="344" elevation="10" >
            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon class="display-1" size="150" color="navigationDrawer darken-2" cover>mdi-truck-delivery</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                Manage Transfers
            </v-card-title>
            <v-card-subtitle>
                Transfer assets between depos, check in-transit transfers & more
            </v-card-subtitle>

        </v-card>

    </v-col>
    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">
        <v-card class="" max-width="344" elevation="10" @click="goToAudit()">
            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon class="display-1" size="150" color="navigationDrawer darken-2" cover>mdi-magnify-scan</v-icon>
                </v-col>
            </v-row>

            <v-card-title>
                Audit Invoice
            </v-card-title>
            <v-card-subtitle>
                Audit Deliveries, Load Shipping Manifests & more
            </v-card-subtitle>

        </v-card>

    </v-col>
    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="">


        <v-card class="" max-width="344" elevation="10" @click="goToStockManagement()">
            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon class="display-1" size="150" color="navigationDrawer darken-2"
                        cover>mdi-file-chart-outline</v-icon>
                </v-col>
            </v-row>

            <v-card-title>
                Manage Stock Orders
            </v-card-title>
            <v-card-subtitle>
               Manage low inventory stock, manage orders and more
            </v-card-subtitle>

        </v-card>

    </v-col>
    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="mt-5">


        <v-card class="" max-width="344" elevation="10" @click="goToStockTake()">
            <v-row align="center" justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon class="display-1" size="150" color="navigationDrawer darken-2"
                        cover>mdi-file-chart-outline</v-icon>
                </v-col>
            </v-row>

            <v-card-title>
                Stock Take
            </v-card-title>
            <v-card-subtitle>
                Generate variance reports & more
            </v-card-subtitle>

        </v-card>

    </v-col>
</v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {

        }
    },
    methods: {
        goToAudit() {
            this.$router.push('warehouse-dashboard/audit-invoice')
        },
        goToTransfers() {
            this.$router.push('warehouse-dashboard/transfers')
        },
        goToStockManagement(){
            this.$router.push('warehouse-dashboard/stock-management')
        },
        goToStockTake(){
            this.$router.push('warehouse-dashboard/stock-take')
        }
    },

})
</script>