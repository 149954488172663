<template>
    <v-chip color="success" class="ma-2" v-if="softPhoneStarted === false" @click="startSoftphone()">
        <v-icon class="mr-1">
            mdi-power
        </v-icon>
        Start Phone
    </v-chip>

    <template v-else>

        <v-menu v-model="menu" :close-on-content-click="false" location="end">
            <template v-slot:activator="{ props }">
                <v-row>
                    
                    <v-chip color="primary" class="my-auto mr-2">
                        <span>{{ sipCreds.sip_username }}</span>
                        <span v-if="sipjsStatus == 'Connected'" class="text-green ml-2">{{ registerState }}</span>
                        <span v-else="sipjsStatus == 'Disconnected'" class="text-red ml-2">{{ registerState }}</span>
                    </v-chip>

                    <div v-if="currentCall">
                        <v-toolbar>
                            <v-menu>
                                <template v-slot:activator="{ props }">
                                    <v-btn icon v-bind="props">
                                        <v-icon color="blue">mdi-phone-forward</v-icon>
                                    </v-btn>
                                    <v-btn v-if="!sessionHeld">
                                        <v-icon @click="placeOnHold" size="x-large" color="blue">mdi-phone-paused</v-icon>
                                    </v-btn>
                                    <v-btn v-else>
                                        <v-icon @click="unhold" size="x-large"
                                            color="blue">mdi-phone-in-talk-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(item, index) in transferList" :key="index" :value="index">
                                        <v-list-item-title @click="transferCall(item.pbx_extension)">{{ item.username }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-spacer></v-spacer>
                            <v-divider vertical class="mx-2"></v-divider>

                            <div v-if="connecting">
                                Connecting
                            </div>
                            <v-btn @click="dialog = true" class="px-5 w-100" v-else>
                                <div class="text-center">
                                    <h4>{{ currentCall.user }} <v-icon>mdi-arrow-expand-all</v-icon></h4>
                                    <h5>{{ callDuration.min }}:{{ callDuration.sec }}</h5>
                                </div>
                            </v-btn>

                            <v-divider vertical class="mx-2"></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="hangup">
                                <v-icon color="red">mdi-phone-hangup</v-icon>
                            </v-btn>

                        </v-toolbar>
                    </div>
                    <div v-else>
                        <v-btn icon v-bind="props" class="ma-1 pr-3">
                            <v-icon>mdi-phone</v-icon>
                        </v-btn>
                    </div>
                </v-row>

            </template>

            <v-card min-width="300" max-width="500">
                <div v-if="incomingCall && !connecting">
                    <v-list>
                        <v-list-item
                            prepend-avatar="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                            :title=incomingCall.user>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="success" variant="text" @click="answerCall()">
                            Answer
                        </v-btn>
                    </v-card-actions>
                </div>

                <div v-else-if="!currentCall">
                    <v-layout>
                        <v-navigation-drawer rail permanent>
                            <v-list-item height="54px" nav
                                prepend-avatar="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"></v-list-item>

                            <v-divider></v-divider>

                            <v-tabs v-model="dialerMode" direction="vertical" color="primary">
                                <v-tab value="dialer">
                                    <v-icon start>
                                        mdi-dialpad
                                    </v-icon>
                                </v-tab>
                                <v-tab value="callLog">
                                    <v-icon start>
                                        mdi-phone-log
                                    </v-icon>
                                </v-tab>

                            </v-tabs>
                        </v-navigation-drawer>

                        <v-main>
                            <v-window v-model="dialerMode">
                                <v-window-item value="dialer">
                                    <Dialer @dial="dial" />
                                </v-window-item>
                                <v-window-item value="callLog">
                                    <CallLog @redial="redial" />
                                </v-window-item>
                            </v-window>
                        </v-main>
                    </v-layout>
                </div>
            </v-card>
        </v-menu>

        <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-row no-gutters class="flex-nowrap bg-surface-variant pt-3 px-3" v-if="currentCall">
                    <v-col cols="4" class="flex-grow-0 flex-shrink-0">
                        <v-card>
                            <v-card-item>
                                <v-card-text>
                                    <div class="text-center">
                                        <h2 class="mb-4" v-if="customerInfo">
                                            {{ customerInfo.name }}
                                        </h2>

                                        <h2>
                                            {{ currentCall.user }}
                                        </h2>

                                    </div>
                                </v-card-text>
                            </v-card-item>
                            <v-card-item v-if="customerInfo">
                                <v-card-text>
                                    <v-chip v-if="customerInfo.hotspot">
                                        Hotspot
                                    </v-chip>
                                    <v-chip v-if="customerInfo.uncapped" class="ml-2">
                                        Uncapped
                                    </v-chip>
                                </v-card-text>
                            </v-card-item>
                            <v-card-item v-if="customerInfo">
                                <v-card title="Hotspot" v-if="customerInfo.hotspot">
                                    <v-table>
                                        <tbody>
                                            <tr>
                                                <td>Usage (30 Day)</td>
                                                <td>
                                                    {{ customerInfo.hotspot.usage }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Balance</td>
                                                <td>
                                                    {{ customerInfo.hotspot.balance }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-table>
                                </v-card>
                            </v-card-item>
                            <v-card-item>
                                <v-btn color="green" @click="createTicket">
                                    Create Ticket
                                </v-btn>
                            </v-card-item>
                            <v-toolbar>
                                <v-menu>
                                    <template v-slot:activator="{ props }">
                                        <v-btn icon v-bind="props">
                                            <v-icon color="blue">mdi-phone-forward</v-icon>
                                        </v-btn>
                                        <v-btn v-if="!sessionHeld">
                                            <v-icon @click="placeOnHold" size="x-large"
                                                color="blue">mdi-phone-paused</v-icon>
                                        </v-btn>
                                        <v-btn v-else>
                                            <v-icon @click="unhold" size="x-large"
                                                color="blue">mdi-phone-in-talk-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in transferList" :key="index" :value="index">
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer></v-spacer>
                                <v-divider vertical class="mx-2"></v-divider>

                                <div v-if="connecting">
                                    Connecting
                                </div>
                                <v-button @click="dialog = true" class="px-5 w-100" v-else>
                                    <div class="text-center">
                                        <h4>{{ currentCall.user }} <v-icon>mdi-arrow-expand-all</v-icon></h4>
                                        <h5>{{ callDuration.min }}:{{ callDuration.sec }}</h5>
                                    </div>
                                </v-button>

                                <v-divider vertical class="mx-2"></v-divider>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="hangup">
                                    <v-icon color="red">mdi-phone-hangup</v-icon>
                                </v-btn>

                            </v-toolbar>
                        </v-card>
                    </v-col>
                    <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0 ml-3">
                        <v-card title="Nodes" v-if="customerInfo">
                            <hr class="mx-5 mb-4">
                            <v-row class="mx-2 mb-2">
                                <v-col cols="4" v-for="(node, index) in customerInfo.nodes" :key="index">
                                    <v-card :title="node.name" variant="outlined">
                                        <v-table>
                                            <tbody>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>
                                                        <div v-if="node.online">
                                                            <v-chip class="ma-2" color="green" text-color="white">
                                                                Online
                                                            </v-chip>
                                                        </div>
                                                        <div v-else>
                                                            <v-chip class="ma-2" color="red" text-color="white">
                                                                Offline
                                                            </v-chip>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>Uncapped</td>
                                                    <td>
                                                        <v-chip v-if="node.uncapped" class="ma-2" color="green"
                                                            text-color="white">
                                                            Active
                                                        </v-chip>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>Uncapped Package</td>
                                                    <td>
                                                        <v-chip v-if="node.uncapped" class="ma-2" color="green"
                                                            text-color="white">
                                                            {{ node.uncapped.package }}
                                                        </v-chip>
                                                        <div v-else>
                                                            N/A
                                                        </div>
                                                    </td>


                                                </tr>

                                                <tr>
                                                    <td>Uncapped Expires</td>
                                                    <td>
                                                        <v-chip v-if="node.uncapped" class="ma-2" color="green"
                                                            text-color="white">
                                                            {{ node.uncapped.expiry }}
                                                        </v-chip>
                                                        <div v-else>
                                                            N/A
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </v-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card title="Late Payments (3 Months)" v-if="customerInfo">
                            <hr class="mx-5 mb-4">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th>
                                            Date Paid
                                        </th>
                                        <th>
                                            Amount
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(payment, index) in customerInfo.payment_history" :key="index">
                                        <td>
                                            {{ payment.date_paid }}
                                        </td>
                                        <td>
                                            R {{ payment.amount }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-card>
                        <v-card title="Leads" v-if="customerInfo">
                            <hr class="mx-5 mb-4">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Type
                                        </th>
                                        <th>
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(lio, index) in customerInfo.lios" :key="index">
                                        <td>
                                            <a target="_blank" :href="lio.url">{{ lio.lio_name }}</a>
                                        </td>
                                        <td>
                                            {{ lio.lio_type }}
                                        </td>
                                        <td>
                                            {{ lio.status }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>


                        </v-card>

                        <v-card title="Net Promoted Score" v-if="customerInfo">
                            <hr class="mx-5 mb-4">
                            <v-card-item>
                                <v-card-text>
                                    <div class="text-start">
                                        <h2 class="mb-4" v-if="customerInfo.latest_nps_rating">
                                            {{ customerInfo.latest_nps_rating }}
                                        </h2>
                                        <h2 v-else>
                                            Not Yet Rated
                                        </h2>
                                    </div>
                                </v-card-text>
                            </v-card-item>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog v-model="npsDialog" persistent width="auto">
            <v-card>
                <v-card-title class="text-h5">
                    Send the customer the NPS Survey ?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red-darken-1" variant="text" @click="npsDialog = false">
                        No
                    </v-btn>
                    <v-btn color="green-darken-1" variant="text" @click="sendCustomerNPS();">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </template>
</template>

<style>
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -1s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 85px;
        height: 85px;
        opacity: 0;
    }
}
</style>

<script lang="ts">
import { ref } from 'vue';
import CallLog from './CallLog.vue'
import Dialer from './Dialer.vue'
import { useMainStore } from '../stores/main'

import { defineComponent } from 'vue';
import { SessionInviteOptions, Web, } from "sip.js";
import { SessionState, UserAgent,Referral, Registerer, Session, Inviter, URI } from "sip.js";
import ringUrl from "/ring.mp3"
import dialingUrl from "/dialing.mp3"
import whisperUrl from "/incoming_call_whisper.mp3"
import axios from 'axios'
import { IncomingResponse } from 'sip.js/lib/core';

let remoteAudio = new Audio()
let localAudio = new Audio()
localAudio.muted = true
const remoteStream = new MediaStream();
const localStream = new MediaStream();


function setupRemoteMedia(session: Session) {

    const sessionDescriptionHandler = session.sessionDescriptionHandler as Web.SessionDescriptionHandler | null;
    if (sessionDescriptionHandler) {
        sessionDescriptionHandler.peerConnection?.getReceivers().forEach((receiver: any) => {
            if (receiver.track) {
                remoteStream.addTrack(receiver.track);
            }
        });
        remoteAudio.srcObject = remoteStream;
        remoteAudio.play();

        sessionDescriptionHandler.peerConnection?.getSenders().forEach((sender: any) => {
            localStream.addTrack(sender.track);
        });

        localAudio.srcObject = localStream;
        localAudio.play()
    }
}

export default defineComponent({
    props: {
        sipCreds: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        npsDialog: false,
        dialerMode: ['dailer'],
        widgets: false,
        notifications: false,
        sound: false,
        fav: true,
        menu: false,
        message: false,
        hints: true,
        autoAnswer: true,
        autoAnswerWhisper: new Audio(whisperUrl),
        incomingRing: new Audio(ringUrl),
        dialing: new Audio(dialingUrl),
        remoteAudio: new Audio(),
        localAudio: new Audio(),
        userAgentOptions: null as any,
        userAgent: null as any,
        numberToCall: "",
        online: false,
        customerInfo: null as any,
        interval: null as any,
        callDuration: {
            "min": "00",
            "sec": "00"
        } as any,
        calling: false,
        registered: false,
        registerer: null as any,
        currentCall: null as any,
        incomingCall: null as any,
        connecting: false,
        sipjsStatus: "Disconnected",
        attemptingReconnection: false,
        reconnectionAttempts: 5,
        reconnectionDelay: 5000,
        registerState: "Not Registered",
        softPhoneStarted: false,
        transferList: [] as any,
        managedSessions: [] as any,
        options: {} as any,
        sessionHeld: false,
        delegate: {} as any,
        agentData: null as any,
        lastCall: false,
        extensions: [] as any
    }),

    methods: {
        createTicket() {
            console.log("Create A Ticket")
        },
        startRing() {
            this.incomingRing.loop = true;
            this.incomingRing.play();
        },
        startAutoAnswer() {
            let vm = this
            this.autoAnswerWhisper.loop = false;
            this.autoAnswerWhisper.play();
            this.autoAnswerWhisper.addEventListener("ended", (event) => {
                vm.answerCall();
            });
        },
        stopRing() {
            this.incomingRing.pause();
            this.incomingRing.currentTime = 0;
        },
        startDialSound() {
            this.dialing.loop = true;
            this.dialing.play();
        },
        stopDialSound() {
            this.dialing.pause();
            this.dialing.currentTime = 0;
        },
        answerCall() {

            if (this.incomingCall && this.incomingCall.invitation && this.incomingCall.invitation.state === SessionState.Initial) {
                this.incomingCall.invitation.accept();
                this.currentCall = {
                    "user": this.incomingCall.user,
                    "invitation": this.incomingCall.invitation,
                    "startTime": new Date()
                }
                this.interval = setInterval(this.callTimer, 1000);

            }
        },
        sendCustomerNPS() {
            if (this.lastCall) {
                let response = axios.post('customer/send/nps/', { "cellphone": this.lastCall });

            }
            this.npsDialog = false;
        },
        getCustomerInfo() {
            let vm = this;
            let response = axios
                .post('customer/information/', { "cellphone": vm.currentCall.user })
                .then(function (response) {
                    if (response.status === 200) {
                        vm.customerInfo = response.data
                        console.log(response.data)
                        console.log(response.data.payment_history)
                        console.log(response.data.payment_history["date_paid"])
                    }
                })
                .catch((error: string) => {
                    console.log(error);
                    vm.customerInfo = null
                })
        },
        onInvite(invitation: any) {
            if (this.currentCall != null && !this.agentData.onBreak) {
                console.log("Already in a call. Rejecting incoming call.");
                invitation.reject();
                return;
            }

            if (this.agentData.onBreak) {
                console.log("Agent on break. Rejecting incoming call.");
                invitation.reject();
                return;
            }
            if (this.autoAnswer) {
                this.startAutoAnswer();
            } else {
                this.startRing();
            }


            this.incomingCall = {
                "user": invitation.remoteIdentity._displayName,
                "invitation": invitation
            }
            let vm = this

            invitation.stateChange.addListener((state: SessionState) => {
                console.log(`Session state changed to ${state}`);
                switch (state) {
                    case SessionState.Initial:
                        break;
                    case SessionState.Establishing:
                        this.stopRing();
                        this.connecting = true;
                        break;
                    case SessionState.Established:
                        this.stopRing();
                        setupRemoteMedia(invitation);
                        this.getCustomerInfo()
                        this.dialog = true
                        this.menu = false;
                        this.connecting = false;
                        break;
                    case SessionState.Terminating:
                    // fall through
                    case SessionState.Terminated:
                        if (this.currentCall && this.currentCall.user) {
                            this.lastCall = this.currentCall.user
                            this.showNpsDialog();
                        }
                        this.currentCall = null
                        this.dialog = false;
                        this.stopRing();
                        this.menu = false;
                        clearInterval(this.interval);
                        this.connecting = false;
                        this.incomingCall = null;
                        break;
                    default:
                        throw new Error("Unknown session state.");
                }
            });

            this.menu = true
        },
        callTimer() {
            if (this.currentCall && this.currentCall.startTime) {
                var dif = this.currentCall.startTime.getTime() - new Date().getTime();
                var durationSecs = Math.round(Math.abs(dif / 1000));

                var minutes = Math.floor(durationSecs / 60);
                var seconds = durationSecs - minutes * 60;
                if (minutes > 9) {
                    this.callDuration.min = minutes;
                } else {
                    this.callDuration.min = "0" + minutes;
                }

                if (seconds > 9) {
                    this.callDuration.sec = seconds;
                } else {
                    this.callDuration.sec = "0" + seconds;
                }
            }
        },
        fakeCall() {

            this.currentCall = {
                "user": "+27747202605",
                "invitation": "",
                "startTime": new Date()
            }
            this.getCustomerInfo()
            this.dialog = true;
        },

        hangup() {

            switch (this.currentCall.invitation.state) {
                case SessionState.Initial:
                case SessionState.Establishing:
                    // An unestablished outgoing session
                    this.currentCall.invitation.cancel();
                    break;
                case SessionState.Established:
                    // An established session
                    this.currentCall.invitation.bye();

                case SessionState.Terminating:
                case SessionState.Terminated:
                    // Cannot terminate a session that is already terminated
                    break;
            }

            clearInterval(this.interval);

            this.currentCall = null;
            this.dialog = false;
            this.calling = false;

            this.callDuration = {
                "min": "00",
                "sec": "00"
            }
        },

        placeOnHold() {
            let vm = this
            //console.log("this is the session id", this.currentCall.invitation.id)

            console.log("this is the session id before hold", vm.currentCall.invitation)
            const sessionDescriptionHandler = vm.currentCall.invitation.sessionDescriptionHandler;
            console.log(sessionDescriptionHandler._peerConnection.signalingState)
            if (!(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
                throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
            }
            if (this.sessionHeld) {
                return Promise.resolve();
            }
            console.log("these are the receivers", sessionDescriptionHandler.peerConnection?.getReceivers())

            const options: SessionInviteOptions = {
                requestDelegate: {
                    onAccept: (response: IncomingResponse): void => {
                        console.log("accept", response)
                        if (vm.currentCall.invitation !== undefined) {

                            sessionDescriptionHandler.enableReceiverTracks(false);
                            sessionDescriptionHandler.enableSenderTracks(false);
                            console.log(this.delegate)
                            if (this.delegate && this.delegate.onCallHold) {
                                this.delegate.onCallHold(vm.currentCall.invitation, true);
                            }
                        }
                    },
                    onReject: (response: IncomingResponse): void => {
                        console.log("reject", response)
                        if (vm.currentCall.invitation !== undefined) {
                            sessionDescriptionHandler.enableReceiverTracks(true);
                            sessionDescriptionHandler.enableSenderTracks(true);
                        }
                        console.log(this.delegate)
                        if (this.delegate && this.delegate.onCallHold) {
                            this.delegate.onCallHold(vm.currentCall.invitation, false);
                        }
                    }
                }
            };

            console.log(vm.currentCall.invitation)

            const sessionDescriptionHandlerOptions =
                vm.currentCall.invitation.sessionDescriptionHandlerOptionsReInvite as any;
            sessionDescriptionHandlerOptions.hold = true;
            vm.currentCall.invitation.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

            return vm.currentCall.invitation
                .invite(options)
                .then(() => {
                    this.sessionHeld = true
                    // Preemptively enable/disable tracks
                })
                .catch((error: Error) => {
                    console.log("thids is the error fro invite", error)
                });

        },
        unhold(state: SessionState) {
            let vm = this

            //console.log("this is the session id", this.currentCall.invitation.id)
            console.log("this is the session id after hold", vm.currentCall.invitation)
            const sessionDescriptionHandler = vm.currentCall.invitation.sessionDescriptionHandler;
            console.log("these are the receivers after hanging up", sessionDescriptionHandler.peerConnection?.getReceivers())
            console.log(sessionDescriptionHandler._peerConnection.signalingState)
            console.log("this is the current section description handler", sessionDescriptionHandler)
            if (!(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
                throw new Error("Session's session description handler not instance of SessionDescriptionHandler.");
            }

            const options: SessionInviteOptions = {}


            const sessionDescriptionHandlerOptions =
                vm.currentCall.invitation.sessionDescriptionHandlerOptionsReInvite as any;
            sessionDescriptionHandlerOptions.hold = false;
            vm.currentCall.invitation.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

            vm.currentCall.invitation
                .invite(options)
                .then(() => {
                    this.sessionHeld = false
                    sessionDescriptionHandler.enableReceiverTracks(true);
                    sessionDescriptionHandler.enableSenderTracks(true);
                })
                .catch((error: Error) => {
                    console.log("thids is the error fro invite", error)
                });
            console.log("this is the session id after invite hold", vm.currentCall.invitation)

        },
        

        call(phoneNumber: any) {
            console.log(this.userAgent)
            this.calling = true;

            if (phoneNumber.length < 1 && phoneNumber.length > 10) {
                return;
            }

            const target = UserAgent.makeURI("sip:" + phoneNumber + "@pbx-2.toomuchwifi.co.za");
            const inviter = new Inviter(this.userAgent, target as URI);

            this.currentCall = {
                user: phoneNumber,
                invitation: inviter,
                startTime: null
            }

            inviter.invite();
            console.log("this sis the intial invite/session", inviter)
            this.currentCall.startTime = new Date();
            this.interval = setInterval(this.callTimer, 1000);

            this.startDialSound();

            inviter.stateChange.addListener((state: SessionState) => {
                console.log(`Session state changed to ${state}`);
                switch (state) {
                    case SessionState.Initial:
                        break;
                    case SessionState.Establishing:
                        this.connecting = true;
                        break;
                    case SessionState.Established:
                        this.stopDialSound();
                        setupRemoteMedia(inviter);
                        this.calling = false;
                        this.menu = false;
                        this.connecting = false;
                        break;
                    case SessionState.Terminating:
                    // fall through
                    case SessionState.Terminated:
                        if (this.currentCall && this.currentCall.user && !this.currentCall.user.startsWith("*33")) {
                            this.lastCall = this.currentCall.user
                            this.showNpsDialog();
                        }
                        this.currentCall = null
                        this.dialog = false;
                        this.stopDialSound();
                        this.calling = false;
                        this.menu = false;
                        clearInterval(this.interval);
                        this.connecting = false;
                        break;
                    default:
                        throw new Error("Unknown session state.");
                }
            });


        },
        onConnect() {
            let vm = this
            this.sipjsStatus = "Connected";
            this.registerer = new Registerer(this.userAgent);
            this.registerer.stateChange.addListener((newState: any) => {
                vm.registerState = newState;
            });
            this.registerer.register()
                .catch((e: Error) => {
                    // Register failed
                });
        },

        attemptReconnection(reconnectionAttempt = 1) {
            var vm = this;
            // Reconnection attempt already in progress
            if (this.attemptingReconnection) {
                return;
            }

            // Reconnection maximum attempts reached
            if (reconnectionAttempt > this.reconnectionAttempts) {
                return;
            }

            // We're attempting a reconnection
            this.attemptingReconnection = true;

            setTimeout(() => {
                // Attempt reconnect
                vm.userAgent.reconnect()
                    .then(() => {
                        // Reconnect attempt succeeded
                        vm.attemptingReconnection = false;
                    })
                    .catch((error: Error) => {
                        // Reconnect attempt failed
                        vm.attemptingReconnection = false;
                        vm.attemptReconnection(++reconnectionAttempt);
                    });
            }, reconnectionAttempt === 1 ? 0 : vm.reconnectionDelay * 1000);
        },

        redial(phoneNumber: any) {
            this.call(phoneNumber);
        },

        dial(phoneNumber: any) {
            this.call(phoneNumber)
        },

        onDisconnect(error: any) {
            this.sipjsStatus = "Disconnected";
            this.registerer.unregister()
                .catch((e: Error) => {
                    // Unregister failed
                });

            if (error) {
                this.attemptReconnection();
            }
        },

        showNpsDialog() {
            this.npsDialog = true;

            // start timer to close dialog after 5 secs if it is still open.
            setTimeout(() => {
                if (this.npsDialog) {
                    this.npsDialog = false;
                }
            }, 5000);
        },
        startSoftphone() {
            let vm = this;
            this.userAgent.start()

            window.addEventListener("online", () => {
                vm.attemptReconnection();
            });

            this.softPhoneStarted = true;
        },

        handleBeforeUnload() {
            const baseURL = import.meta.env.VITE_AXIOS_BASE_URL;
            this.userAgent.stop();
            if (this.incomingCall) {
                window.navigator.sendBeacon(`${baseURL}agent/call/avoidance/`)
            }
        },
        transferCall(extension: any) {
           
            console.log("this is the extension", extension)
            let vm = this
            const outgoingSession = vm.currentCall.invitation;
            let sipUri = `sip:${extension}@pbx-2.toomuchwifi.co.za`
            console.log("this is the sip uri", sipUri)
            const transferTarget = UserAgent.makeURI(sipUri);
            console.log('this is the transfer target', transferTarget)
            if (!transferTarget){
                throw new Error("Invalid transfer target");
            }
            console.log('this is the current inviation', vm.currentCall)
            outgoingSession.refer(transferTarget,{
                requestDelegate: {
                    onAccept: (response: IncomingResponse): void => {
                        console.log("accept", response)
                       
                    },
                    onReject: (response: IncomingResponse): void => {
                        console.log("reject", response)
                      
                    }
                }
            });
        },
        getExtensions(){
            const vm = this

            const response = axios.get('extensions').then((response) => {
                console.log(response.data)
                vm.transferList = response.data
            }).catch((error) => {
                console.log(error)
            })

        }
    },
    watch: {
        sipCreds(newVal) {

        }
    },
    mounted() {
        let vm = this;
        // UserAgent.OutgoingRequestMessage.setViaHeader = function(branch, transport){
        //     console.log(branch, transport)
        //     return;
        // }
        console.log('these ate the sip creds', this.sipCreds.sip_username)
        this.userAgent = new UserAgent({
            uri: UserAgent.makeURI(`sip:${this.sipCreds.sip_username}@pbx-2.toomuchwifi.co.za`),
            userAgentString: `${this.sipCreds.sip_username}@pbx-2.toomuchwifi.co.za`,
            transportOptions: {
                wsServers: ['wss://pbx-2.toomuchwifi.co.za:7443'],
                keepAliveInterval: 5
            },
            delegate: {
                onInvite: vm.onInvite,
                onConnect: vm.onConnect,
                onDisconnect: vm.onDisconnect,

            },

            authorizationUsername: this.sipCreds.sip_username,
            authorizationPassword: this.sipCreds.sip_password,
            displayName: this.sipCreds.display_name,
        });

        this.userAgent._transport._protocol = "WS"
        this.userAgentOptions = {
            uri: `${this.sipCreds.sip_username}@pbx-2.toomuchwifi.co.za`,
            transportOptions: {
                wsServers: ['wss://pbx-2.toomuchwifi.co.za:7443']
            },
            authorizationUser: this.sipCreds.sip_username,
            password: this.sipCreds.sip_password,
            traceSip: true,
            displayName: this.sipCreds.display_name
        }
        vm.getExtensions()
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    },
    setup() {
        const main = useMainStore();
        return { agentData: main.agentData }
    }
});

</script>
