<template>
    <v-menu @input="changeApp">
        <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props">
                <v-icon left>mdi-apps</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, index) in apps" :key="index" :value="index" @click="changeApp(item)">
                <!-- Start Slot -->
                <template v-slot:prepend>
                    <v-icon :icon="item.icon"></v-icon>
                </template>
                <!-- End Slot -->

                <v-list-item-title v-text="item.name"></v-list-item-title>

            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts">
import { useMainStore } from '../stores/main';
const apps = [
    { id:"CUSTOMER_SUPPORT", name: 'Customer Support', icon: 'mdi-face-agent', route: "CustomerSupport" },
    { id: 'WAREHOUSE', name: 'Warehouse', icon: 'mdi-warehouse', route: "Warehouse" },
    { id: 'SCHEDULE', name: 'Schedule', icon: 'mdi-calendar-clock', route: "Schedule" },
    { id: 'DASHBOARD', name: 'Dashboard', icon: 'mdi-view-dashboard', route: "Dashboard" },
    
    // Add more apps as needed
];
export default {
    data() {
        return {
            apps: apps,
            mainStore: useMainStore(),
            selectedItem: apps[0],
        };
    },
    methods: {
        changeApp(selectedApp: { name: string; icon: string }) {
            // Implement logic to change the app based on the selectedApp
            console.log(selectedApp);
            this.mainStore.setApp(selectedApp);
        },
    },
};
</script>

<style>
/* Add your custom styles here */
</style>
